import { TranslateResult } from 'vue-i18n'

export class MissingLegacyRouteStepError extends Error {
}

export interface CartError {
  code: number
  message: string | TranslateResult
}

export interface HomelocPaymentError {
  code: string
  message: string | TranslateResult
}

export interface ApiPaymentError {
  code: number
  message: string | TranslateResult
}
