import { mutationTree } from "nuxt-typed-vuex"
import { state as baseState } from "./state"

export const mutations = mutationTree(baseState, {
  SET_USER(state, user) {
    state.user = user
  },
  SET_PROFILE(state, profile) {
    state.profile = profile
  },
})

export default mutations