import { actionTree } from 'typed-vuex'
import { entityFile, state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { createActions } from '@kissmylabs/vuex-entitystore'
import { EntityFile } from '~/types/Models/EntityFile'

export const actions = actionTree({ state, getters, mutations }, {
  ...createActions<EntityFile>(entityFile),
})

export default actions
