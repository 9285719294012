









































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import UserCircleIcon from '@/components/icons/UserCircleIcon.vue'
import useMarketplace from '~/composable/useMarketplace'
import useHostname from '~/composable/useHostname'
import useCustomer from '~/composable/useCustomer'

export default defineComponent({
  components: {
    UserCircleIcon,
  },
  setup() {
    const { app: { $accessor, i18n } } = useContext()
    const { clientAccountRedirect } = useMarketplace()
    const { displayName } = useCustomer()

    const isLoggedIn = computed(() => $accessor.users.user !== null)

    const hostname = useHostname()
    const locale = i18n.locale || 'fr'

    return {
      hostname,
      displayName,
      clientAccountRedirect,
      isLoggedIn,
      locale,
    }
  },
})
