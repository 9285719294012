





















































































































import { computed, ComputedRef, defineComponent, onMounted, ref, useContext, useMeta } from '@nuxtjs/composition-api'
import { TranslateResult } from 'vue-i18n'
import useCustomer from '~/composable/useCustomer'
import useLoadingScreen from '~/composable/useLoadingScreen'
import { PAYMENT_VERIFICATION_MAX_TRIES } from '~/helpers'
import usePayment from '~/composable/usePayment'
import BookingPreview from '~/components/preview/BookingPreview.vue'
import ThankYouBlocks from '~/components/content/ThankYouBlocks.vue'
import LoadingScreen from '~/components/LoadingScreen.vue'
import ReassuranceBlock from '~/components/content/ReassuranceBlock.vue'
import ReassuranceCard from '~/components/content/ReassuranceCard.vue'
import useMarketplace from '~/composable/useMarketplace'

export default defineComponent({
  name: 'FinishPage',
  components: {
    ThankYouBlocks,
    BookingPreview,
    LoadingScreen,
    ReassuranceBlock,
    ReassuranceCard,
  },
  setup() {
    const { app: { $accessor, i18n, $translateEntityField } } = useContext()
    const { getCustomerField } = useCustomer()
    const { isPaid, isChecking, startPaymentVerification, paymentTryTimes } = usePayment()
    const { state: loadingScreenState } = useLoadingScreen()
    const loadingTextStep = ref(1)

    startPaymentVerification()

    const { isFairmoove } = useMarketplace()

    onMounted(() => {
      // If we didn't confirm the payment after 30 seconds, change loader text.
      window.setTimeout(() => {
        if (!isPaid.value) {
          loadingTextStep.value = 2
        }
      }, 30000)
    })

    const daysLeftBeforeTrip: ComputedRef<number> = computed(() => {
      if ($accessor.cart && typeof $accessor.cart.start === 'string') {
        const timeDiff = Math.abs(Date.now() - Date.parse($accessor.cart.start))
        return Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
      }
      return 0
    })

    const getFinishMessageIntro: ComputedRef<TranslateResult | string> = computed(() => {
      const childCount = $accessor.getChildrenCount + $accessor.getInfantCount
      if (childCount) {
        return i18n.tc('finish.content_1_child', childCount, { nights: daysLeftBeforeTrip.value })
      }
      return i18n.t('finish.content_1_adult', { nights: daysLeftBeforeTrip.value })
    })

    const hasHostMessage = computed(() => $accessor.booking?.hostMessage)

    useMeta({
      title: `${$accessor.marketplace
        ? $translateEntityField($accessor.marketplace.name)
        : 'Abracadaroom'} | ${i18n.t('steps.finish')}`,
    })

    return {
      daysLeftBeforeTrip,
      getCustomerField,
      getFinishMessageIntro,
      hasHostMessage,
      isChecking,
      isFairmoove,
      isPaid,
      loadingScreenState,
      loadingTextStep,
      PAYMENT_VERIFICATION_MAX_TRIES,
      paymentTryTimes,
    }
  },
  head() {
    return {}
  },
})
