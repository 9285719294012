























import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import LockOpenIcon from '~/components/icons/LockOpenIcon.vue'
import useMarketplace from '~/composable/useMarketplace'
import { ShortToISOEnum } from '~/types/Locale'

export default defineComponent({
  name: 'ReassuranceBlock',
  components: {
    LockOpenIcon,
  },
  setup() {
    const { isTreego, marketplaceLinks } = useMarketplace()
    const { app: { i18n } } = useContext()

    const redirectUrl = computed(() => {
      const locale = i18n.locale
      if (!isTreego.value) {
        return marketplaceLinks.value.cancellationInsuranceUrl[ShortToISOEnum[locale.toUpperCase() as keyof typeof ShortToISOEnum]]
      }
      // For Treego, always redirect to NL version
      return marketplaceLinks.value.cancellationInsuranceUrl[ShortToISOEnum.NL]
    })

    return {
      redirectUrl,
    }
  },
})
