export default {
  locales: [
    {
      code: 'en',
      file: 'en.json',
      iso: 'en_US',
    },
    {
      code: 'fr',
      file: 'fr.json',
      iso: 'fr_FR',
    },
    {
      code: 'es',
      file: 'es.json',
      iso: 'es_ES',
    },
    {
      code: 'nl',
      file: 'nl.json',
      iso: 'nl_NL',
    },
  ],
  defaultLocale: 'fr',
  lazy: true,
  langDir: 'lang/',
  strategy: 'prefix_and_default',
  rootRedirect: 'fr',
  vueI18n: {
    fallbackLocale: 'en',
  },
}
