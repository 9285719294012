













































































import { computed, defineComponent, ref, useContext, watch } from '@nuxtjs/composition-api'
import useCart from '~/composable/useCart'
import { DiscountTypeEnum } from '~/types/Models/Payment'
import GiftCardInfo from '~/components/payment/GiftCardInfo.vue'
import DiscountsErrors from '~/components/content/DiscountsErrors.vue'

export default defineComponent({
  name: 'GiftCardsForm',
  components: {
    DiscountsErrors,
    GiftCardInfo,
  },
  setup() {
    const { app } = useContext()
    const {
      activeDiscountType,
      isFullGiftCardPayment,
    } = useCart()

    const model = ref('')
    const canCheck = computed(() => model.value && model.value.length >= 3)

    watch(() => app.$accessor.activeDiscountType, (type) => {
      if (type !== DiscountTypeEnum.DISCOUNT_GIFTCARDS) {
        model.value = ''
      }
    })

    function onToggleActive(active: boolean) {
      activeDiscountType.value = active ? DiscountTypeEnum.DISCOUNT_GIFTCARDS : null
      if (!active) {
        app.$accessor.giftCards.clearGiftCards()
      }
    }

    async function onGiftCardAdd() {
      if (model.value) {
        // If the giftCard applies properly, reset input value.
        if (await app.$accessor.giftCards.checkAndApplyGiftCard(model.value)) {
          model.value = ''
        }
      }
    }

    async function onGiftCardDelete(index: number) {
      await app.$accessor.giftCards.removeGiftCard(index)
    }

    return {
      DiscountTypeEnum,
      activeDiscountType,
      isFullGiftCardPayment,
      model,
      canCheck,
      onToggleActive,
      onGiftCardAdd,
      onGiftCardDelete,
    }
  },
})
