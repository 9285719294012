import { Context } from '@nuxt/types'
import { ShortLocale, Translation } from '~/types/Locale'
import { translateEntityField as translateEntityFieldHelper } from '~/helpers/locale'

export interface i18nHook {
  currentLocale: string
  translateEntityField: (field: Translation) => string
}

function useI18n(ctx: Context): i18nHook {
  const { app } = ctx

  const currentLocale = app.i18n.locale

  /**
   * Returns the correct string value for a Translation field based on the app's current locale. If a match is not found, returns some fallback values.
   * @param field - The entity field you want to get the correct translation for.
   * @returns A string that is either :
   *  - The value matching the current locale (if found)
   *  - The value in English (if found and the current local is not French)
   *  - The value in French (if found)
   *  - An empty string if no value was matched.
   */
  const translateEntityField = (field: Translation): string => {
    const currentLocaleTranslation = translateEntityFieldHelper(field, currentLocale.toUpperCase())
    if (currentLocaleTranslation) {
      return translateEntityFieldHelper(field, currentLocale.toUpperCase())
    }

    if (currentLocale.toUpperCase() !== ShortLocale.EN) {
      const firstFallbackTranslation = translateEntityFieldHelper(field, ShortLocale.EN)
      if (currentLocale.toUpperCase() !== ShortLocale.FR && firstFallbackTranslation) {
        return firstFallbackTranslation
      }
    }
    return translateEntityFieldHelper(field, ShortLocale.FR) || ''
  }

  return {
    currentLocale,
    translateEntityField,
  }
}

export default useI18n
