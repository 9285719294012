export type SupportedLocale = 'fr' | 'en' | 'es' | 'nl'

export type SupportedISOLocale = 'fr-FR' | 'en-US' | 'es-ES' | 'nl-NL'

export enum ShortLocale {
  FR = 'FR',
  EN = 'EN',
  ES = 'ES',
  NL = 'NL',
}

export enum ShortToRegionEnum {
  FR = 'fr_FR',
  EN = 'en_US',
  ES = 'es_ES',
  NL = 'nl_NL',
}

export enum ShortToISOEnum {
  FR = 'fr-FR',
  EN = 'en-US',
  ES = 'es-ES',
  NL = 'nl-NL',
}

export interface Translation {
  [ShortToISOEnum.FR]: string | null
  [ShortToISOEnum.EN]: string | null
  [ShortToISOEnum.ES]: string | null
  [ShortToISOEnum.NL]: string | null
}
export interface LocaleStrings {
  [key:string]: string
}

export interface LocalizedDescription {
  short_description: string,
  name: string,
  long_description: string
}

export interface LocaleBooleans {
  [key:string]: boolean
}
