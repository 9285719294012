import { useContext, watch } from "@nuxtjs/composition-api"
import { CartOption } from "~/types/Models"

interface IUseChangeCartNotifications {
  getNotificationContent(): string
}

function useCartChangeNotifications(): IUseChangeCartNotifications {

  const { app: { $accessor, $translateEntityField } } = useContext()

  function hasChangedOptionQuantity(oldValue: CartOption[], newValue: CartOption[], index: number) {
    return oldValue[index].quantity !== newValue[index].quantity
  }

  function getNotificationContent() {
    switch ($accessor.lastCartModification?.type) {
      // Required block for this `case` to satisfy https://eslint.org/docs/rules/no-case-declarations
      case 'OPTION': {
        const option = $accessor.options.find(option => $accessor.lastCartModification?.option?.id === option.id)
        const cartOption = $accessor.cart?.options.find(cartOption => cartOption.option === option?.id)
        if (option && cartOption) {
          const optionName = $translateEntityField(option?.name)
          const optionQuantity = cartOption.quantity

          return `${optionQuantity}x ${optionName}`
        }
        return ''
      }
      case 'PROMOCODE':
        return $accessor.lastCartModification.promocode?.percentDiscount ? `Code promo ${$accessor.lastCartModification.promocode?.percentDiscount} %` : ''
      default:
        return ''
    }
  }

  watch(
    () => $accessor.ui.displayCartNotification,
    (newValue) => {
      if (newValue) {
        setTimeout(() => {
          $accessor.HIDE_CART_NOTIFICATION()
          $accessor.CLEAR_LAST_CART_MODIFICATION()
        }, 5000)
      }
    },
  )

  watch(
    () => $accessor.cart?.options,
    (newValue, oldValue) => {
      if (newValue && oldValue) {

        // Find the updated CartOption (this is a bit tricky)
        const updatedCartOption = newValue.find((option, index) => {
          return (!oldValue.some(o => o.option === option.option) || hasChangedOptionQuantity(oldValue, newValue, index))
            && $accessor.getAvailableOptions.some(o => o.id === option.option)
        })
        // Find the related Option
        const option = $accessor.getAvailableOptions.find(option => option.id === updatedCartOption?.option)
        if (option && $accessor.cartPreview?.totalCharge.amount) {
          $accessor.HIDE_CART_NOTIFICATION()
          $accessor.CLEAR_LAST_CART_MODIFICATION()
          $accessor.SET_LAST_CART_MODIFICATION({ option, previousPrice: $accessor.cartPreview.totalCharge.amount, type: 'OPTION' })
        }
      }
    },
  )

  watch(
    () => $accessor.promocode,
    (newValue, oldValue) => {
      // Dont trigger when we just set the promocode ID
      if (typeof newValue === 'number' || typeof oldValue === 'number') {
        return
      }
      if (newValue && oldValue !== newValue && $accessor.cartPreview) {
        // Dont do anything if the cart has just been updated after a patch
        if (newValue.id === oldValue?.id) {
          return
        }
        $accessor.HIDE_CART_NOTIFICATION()
        $accessor.CLEAR_LAST_CART_MODIFICATION()
        $accessor.SET_LAST_CART_MODIFICATION({
          promocode: $accessor.promocode,
          previousPrice: $accessor.cartPreview.totalCharge.amount,
          type: 'PROMOCODE',
        })
      }
    },
  )

  return {
    getNotificationContent,
  }
}

export default useCartChangeNotifications
