import { computed, ComputedRef, useContext } from '@nuxtjs/composition-api'
import { ServiceExternalId } from '~/types/Models'
import { decode } from 'universal-base64'
import { ShortToISOEnum } from '~/types/Locale'

interface ServiceHook {
  getServiceExternalIdObject: ComputedRef<ServiceExternalId | null>
  serviceUrl: ComputedRef<string>
}

function useService(): ServiceHook {
  const { $config, app: { $accessor, i18n } } = useContext()

  const getServiceExternalIdObject = computed(() => {
    if ($accessor.service?.isExternal && $accessor.service.externalId) {
      return JSON.parse(decode($accessor.service.externalId)) as unknown as ServiceExternalId
    }
    return null
  })

  const serviceUrl = computed(() => {
    const locale = i18n.locale
    let url: string | null = null
    if ($accessor.service?.abcdUrl) {
      url = $accessor.service.abcdUrl[ShortToISOEnum[locale.toUpperCase() as keyof typeof ShortToISOEnum]]
    }
    return url || `${$config.abcdBaseUrl}/${i18n.locale}/`
  })

  return {
    getServiceExternalIdObject,
    serviceUrl,
  }
}

export default useService
