import { DateTime } from 'luxon'
import { GuestType, GuestTypeEnum } from '~/types/Models/Guest'
import dayjs from 'dayjs'

export const ageTypePluralToSingular = {
  'adults': 'adult',
  'children': 'children',
  'infants': 'infant',
}
export const ageTypeSingularToPlural = {
  'adult': 'adults',
  'children': 'children',
  'infant': 'infants',
}

export type AgeTypePlural = keyof typeof ageTypePluralToSingular
export type AgeTypeSingular = keyof typeof ageTypeSingularToPlural

export const getAgeTypeSingular = (val: AgeTypePlural): string => ageTypePluralToSingular[val]

export const getAgeTypePlural = (val: AgeTypeSingular): string => ageTypeSingularToPlural[val]

export function isYoungerThan(date: string, limit: number): boolean | undefined {
  if (date.length === 0) {
    return true
  }
  const years = DateTime.fromISO(date)
    .diffNow('years')
    .toObject().years
  return years ? Math.abs(years) <= limit : undefined
}

export function isAdult(date: string): boolean {
  return !isYoungerThan(date, 18)
}

/**
 * Returns the age of a person at a given date.
 * @param birthDateString The DateString representing the birth date
 * @param comparisonDate The Date you want to compare it with (defaults to `new Date()`)
 * @returns The age of the person
 */
export function calculateAgeAtDate(birthDateString: string, comparisonDate: dayjs.Dayjs): number {
  return comparisonDate.startOf('day').diff(dayjs.utc(birthDateString).startOf('day'), 'year')
}

export function calculateAgeAtDateWithOffSet(birthDateString: string, comparisonDate = dayjs.utc()): number {
  return calculateAgeAtDate(birthDateString, comparisonDate) + 1
}

export const getAgeTypeOfOccupant = (birthDate: string, childrenMaxAge: number, infantMaxAge: number, bookingStart: dayjs.Dayjs): GuestType => {
  const years = calculateAgeAtDateWithOffSet(birthDate, bookingStart)
  let type = GuestTypeEnum.ADULT
  if (Math.floor(Math.abs(years)) <= (childrenMaxAge)) {
    type = GuestTypeEnum.CHILDREN
  }
  if (Math.floor(Math.abs(years)) <= (infantMaxAge)) {
    type = GuestTypeEnum.INFANT
  }
  return type
}
