
































































































































































import { computed, defineComponent, reactive, Ref, ref, UnwrapRef, watch } from '@nuxtjs/composition-api'
import { PaymentForm, PaymentMethod, PaymentMethodEnum } from '~/types/Models/Payment'
import usePayment from '~/composable/usePayment'
import { ValidationObserver } from '~/types/vee-validate'
import PaymentCardInput from '~/components/payment/PaymentCardInput.vue'
import { paymentFormDefault } from '~/store/state'
import { shortYearToFullYear } from '~/helpers'

export default defineComponent({
  name: 'PaymentCardForm',
  components: {
    PaymentCardInput,
  },
  emits: ['update'],
  setup(_, { emit }) {
    const { getPaymentField } = usePayment()

    const observer: Ref<ValidationObserver | null> = ref(null)

    const form: UnwrapRef<Omit<PaymentForm, 'type'>> = reactive({
      cardNumber: '',
      month: '',
      year: '',
      ccv: '',
      holderName: '',
    })

    const isBcmc = computed(() => getPaymentField('type').value === PaymentMethodEnum.METHOD_BCMC)

    watch(() => form, async() => {
      // TODO this breaks when a first update is emitted then the form becomes invalid but as it's not updated,
      // it's still considered valid in the store.
      // For now we emit an empty PaymentForm object so isValidPaymentCard becomes false, but maybe there's a better way.
      if (observer.value) {
        let payload: PaymentForm | null = null
        if ((await observer.value.validate())) {
          payload = {
            ...form,
            type: getPaymentField('type').value,
            cardNumber: form.cardNumber.split(' ').join(''),
            year: shortYearToFullYear(form.year),
          } as PaymentForm
        } else {
          payload = {
            ...paymentFormDefault(),
            type: getPaymentField('type').value as PaymentMethod,
          }
        }
        emit('update', payload)
      }
    }, { deep: true })

    return {
      PaymentMethodEnum,
      getPaymentField,
      observer,
      form,
      isBcmc,
    }
  },
})
