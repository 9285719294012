

























































import { computed, defineComponent, ref, useContext, watch } from '@nuxtjs/composition-api'
import useCart from '~/composable/useCart'
import { DiscountTypeEnum } from '~/types/Models/Payment'
import XCircleIcon from '~/components/icons/XCircleIcon.vue'
import DiscountsErrors from '~/components/content/DiscountsErrors.vue'

export default defineComponent({
  components: {
    DiscountsErrors,
    XCircleIcon,
  },
  setup() {
    const { app } = useContext()
    const { getCartField, activeDiscountType } = useCart()

    const model = ref(app.$accessor.promocode.code || '')
    const canCheck = computed(() => model.value && model.value.length >= 3)

    const isPanelOpen = ref(activeDiscountType.value === DiscountTypeEnum.DISCOUNT_PROMOCODE)

    watch(() => activeDiscountType.value, (type) => {
      if (type !== DiscountTypeEnum.DISCOUNT_PROMOCODE) {
        model.value = ''
      }
    })

    function onToggleActive(active: boolean) {
      isPanelOpen.value = active
      activeDiscountType.value = active ? DiscountTypeEnum.DISCOUNT_PROMOCODE : null
    }

    async function onCodeCheck() {
      await app.$accessor.checkAndApplyPromocode(model.value)
    }

    async function onRemovePromocode() {
      activeDiscountType.value = null
    }

    return {
      DiscountTypeEnum,
      getCartField,
      model,
      activeDiscountType,
      canCheck,
      onToggleActive,
      onRemovePromocode,
      onCodeCheck,
      isPanelOpen,
    }
  },
})
