





















import { defineComponent } from '@nuxtjs/composition-api'
import MoonIcon from '~/components/icons/MoonIcon.vue'
import useSteps from '~/composable/useSteps'
import useMarketplace from '~/composable/useMarketplace'
import useService from '~/composable/useService'

export default defineComponent({
  components: {
    MoonIcon,
  },
  setup() {
    const { isInFinishPage } = useSteps()
    const { isABCD } = useMarketplace()
    const { serviceUrl } = useService()

    return {
      isInFinishPage,
      isABCD,
      serviceUrl,
    }
  },
})
