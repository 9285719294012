import { BaseGuestInfo } from './Customer'

export type GuestType = 'children' | 'adult' | 'infant'

export enum GuestTypeEnum {
  CHILDREN = 'children',
  ADULT = 'adult',
  INFANT = 'infant',
}

export interface Guest extends BaseGuestInfo {
  guestType?: GuestType
  idx: number

  [key: string]: any
}
