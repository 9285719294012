



























import { defineComponent } from '@nuxtjs/composition-api'
import UserAddIcon from '~/components/icons/UserAddIcon.vue'
import useGuests from '~/composable/useGuests'

export default defineComponent({
  components: {
    UserAddIcon,
  },
  setup() {
    const { getAdditionalPersonsWithPrices } = useGuests()

    return {
      getAdditionalPersonsWithPrices,
    }    
  },
})
