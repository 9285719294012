














































































import { computed, defineComponent, PropType, ref, useContext, watch } from '@nuxtjs/composition-api'
import { GiftCardWithChargeForm } from '~/types/Models'
import Decimal from 'decimal.js-light'
import SymbolInput from '~/components/content/SymbolInput.vue'
import GiftIcon from '~/components/icons/GiftIcon.vue'
import XCircleIcon from '~/components/icons/XCircleIcon.vue'
import CheckIcon from '~/components/icons/CheckIcon.vue'

export default defineComponent({
  components: {
    GiftIcon,
    CheckIcon,
    XCircleIcon,
    SymbolInput,
  },
  props: {
    giftCard: {
      type: Object as PropType<GiftCardWithChargeForm>,
      required: true,
    },
  },
  setup(props) {
    const { app: { $accessor } } = useContext()

    const otherGiftCardCharges = computed(() => $accessor.giftCards.getGiftCardsWithChargeForm
      .filter(gc => gc.id !== props.giftCard.id)
      .reduce((total: number, gc) => gc.amountToSpend.amount + total, 0),
    )

    const maxAmount = computed(() => {
      const leftToPay = $accessor.getDiscountedRate.amount - otherGiftCardCharges.value
      return Math.min(leftToPay, props.giftCard.remainingBalance.amount)
    })

    // How much do we want to charge against this giftCard?
    const amountToSpend = computed({
      get: () => props.giftCard.amountToSpend.amount / 100,
      set: value => {
        let parsedValue = 0
        // Check for empty string/null/undefined etc...
        if (value) {
          parsedValue = value
        }

        let inputAmount = new Decimal(parsedValue).toNumber()

        inputAmount *= 100
        const max = inputAmount > maxAmount.value ? maxAmount.value : inputAmount

        $accessor.giftCards.SET_GIFTCARD_AMOUNT_TO_SPEND({
          ...props.giftCard,
          amountToSpend: {
            amount: Math.min(inputAmount, max),
            currency: 'EUR',
          },
        })
      },
    })

    // Is the amount to spend with this GiftCard less than the remaining balance ?
    const isCustomAmount = ref(
      props.giftCard.amountToSpend.amount !== props.giftCard.remainingBalance.amount &&
      maxAmount.value !== props.giftCard.amountToSpend.amount,
    )

    watch(() => isCustomAmount.value, async(value) => {
      if (!value) {
        amountToSpend.value = props.giftCard.originalAmountToSpend.amount
        await $accessor.updateCart()
      }
    })

    async function onConfirmAmountToSpend() {
      await $accessor.updateCart()
    }

    function onRemoveGiftcard() {
      $accessor.giftCards.removeGiftCard(props.giftCard)
    }

    return {
      maxAmount,
      amountToSpend,
      onRemoveGiftcard,
      onConfirmAmountToSpend,
      isCustomAmount,
    }
  },
})
