














































































































import { defineComponent, reactive, Ref, ref, useContext, onMounted, toRefs, computed } from '@nuxtjs/composition-api'
import useCart from '~/composable/useCart'
import CustomerGenderSelector from '~/components/customer/CustomerGenderSelector.vue'
import EmailSuggestionField from '~/components/ui/EmailSuggestionField.vue'
import { Country, PhoneObject } from '~/types/Models'
import { ValidationObserver, ValidationProvider } from '~/types/vee-validate'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/css/sprite.css'
import '~/assets/style/vue-tel-input.scss'

export default defineComponent({
  components: {
    CustomerGenderSelector,
    EmailSuggestionField,
    VueTelInput,
  },
  setup() {
    const { app } = useContext()
    const { getCartThirdPartyDataField } = useCart()

    const phoneValidator: Ref<ValidationProvider | null> = ref(null)
    const observer: Ref<ValidationObserver | null> = ref(null)

    const state = reactive({
      countrySelectorOpen: false,
      countries: {},
      preferredCountries: ['FR', 'BE', 'CH', 'NL', 'ES', 'GB', 'LU', 'DE', 'IT', 'CA', 'MC'],
    })

    const country = computed(() => app.$accessor.customer.billingAddress?.country)

    // TODO refacto this after MEP
    function onPhoneUpdate(number: string, phoneObject: PhoneObject): void {
      phoneValidator.value?.reset()
      getCartThirdPartyDataField('phoneNumber').value = number

      if (phoneValidator.value && !phoneObject.valid && number) {
        phoneValidator.value.setErrors([app.i18n.t('form.validations.third_party_phone_number')])
      } else if (phoneObject.valid && phoneObject.number) {
        getCartThirdPartyDataField('phoneNumber').value = phoneObject.number
      }
    }

    onMounted(async() => {
      const isValid = await observer.value?.validate({ silent: true })
      app.$accessor.SET_BLOCK_NEXT_STEP(!isValid)

      const countries: Country[] = await app.$api.get('countries')
      const firstCountries = {} as Record<string, Country>
      const otherCountries = countries.reduce((acc, country) => {
        if (state.preferredCountries.includes(country.code)) {
          firstCountries[country.code] = {
            code: country.code,
            id: country.id,
            name: country.name,
          }
        } else {
          acc[country.code] = {
            code: country.code,
            id: country.id,
            name: country.name,
          }
        }
        return acc
      }, {} as Record<string, Country>)
      state.countries = {
        first: firstCountries,
        other: otherCountries,
      }
    })

    return {
      ...toRefs(state),
      country,
      getCartThirdPartyDataField,
      onPhoneUpdate,
      phoneValidator,
    }
  },
})
