import { computed, useContext } from "@nuxtjs/composition-api"
import { ShortToISOEnum } from "~/types/Locale"

export default function useMarketplace() {
  const { app: { $accessor } } = useContext()

  const isABCD = computed(() => $accessor.marketplace?.id === 241 || $accessor.marketplace?.id === 242)
  const isLCBL = computed(() => $accessor.marketplace?.id === 239)
  const isUSCOM = computed(() => $accessor.marketplace?.id === 332)
  const isFairmoove = computed(() => $accessor.marketplace?.id === 331)
  const isTreego = computed(() => $accessor.marketplace?.id === 240)

  const clientAccountRedirect = computed(() => {
    if (isABCD.value) {
      return 'bff-abcd'
    }
    if (isLCBL.value) {
      return 'bff-lcbl'
    }
    if (isUSCOM.value) {
      return 'bff-uscom'
    }
  })

  const marketplaceLinks = computed(() => {
    if (isUSCOM.value) {
      return {
        faqUrl: 'https://faq.unicstay.com/fr/',
        cancellationInsuranceUrl: {
          [ShortToISOEnum.FR]: 'https://faq.unicstay.com/fr/article/assurance-annulation-6ky9s3/',
          [ShortToISOEnum.EN]: 'https://faq.unicstay.com/fr/article/assurance-annulation-6ky9s3/',
          [ShortToISOEnum.ES]: 'https://faq.unicstay.com/fr/article/assurance-annulation-6ky9s3/',
          [ShortToISOEnum.NL]: 'https://faq.unicstay.com/fr/article/assurance-annulation-6ky9s3/',
        },
        ancvUrl: 'https://www.abracadaroom.com/fr/formulaire-ancv/',
      }
    }
    return {
      faqUrl: 'https://faq.abracadaroom.com/fr/',
      cancellationInsuranceUrl: {
        [ShortToISOEnum.FR]: 'https://faq.abracadaroom.com/fr/article/assurance-annulation-1yv7ews/',
        [ShortToISOEnum.EN]: 'https://www.souscription.safebooking.com/en/abracadaroom/',
        [ShortToISOEnum.ES]: 'https://www.souscription.safebooking.com/es/abracadaroom/',
        [ShortToISOEnum.NL]: 'https://www.souscription.safebooking.com/nl/abracadaroom/',
      },
      ancvUrl: 'https://www.abracadaroom.com/fr/formulaire-ancv/',
    }
  })

  return {
    clientAccountRedirect,
    isABCD,
    isFairmoove,
    isUSCOM,
    isTreego,
    marketplaceLinks,
  }
}
