import { createGetters } from '@kissmylabs/vuex-entitystore'
import { getterTree } from 'typed-vuex'
import { GiftCard, GiftCardWithChargeForm } from '~/types/Models/GiftCard'
import { giftCard as giftCardEntityDefinition, state as baseState } from '~/store/giftCards/state'
import { GiftCardsState } from '~/store/giftCards/types'

export const getters = getterTree(baseState, {
  ...createGetters<GiftCardsState, GiftCard>(giftCardEntityDefinition),

  getGiftCardsWithChargeForm(state, _getters): GiftCardWithChargeForm[] {
    console.log(_getters.getOrdered)
    return _getters.getOrdered.map((giftcard: GiftCard) => ({
      ...giftcard,
      amountToSpend: state.chargesForm[giftcard.id].amountToSpend,
      originalAmountToSpend: state.chargesForm[giftcard.id].originalAmountToSpend,
    }))
  },
})

export default getters
