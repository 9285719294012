




































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { Option } from '~/types/Models/Option'
import useOptions from '~/composable/useOptions'

export default defineComponent({
  name: 'OptionSelect',
  props: {
    option: {
      type: Object as PropType<Option>,
      required: true,
    },
  },
  setup() {
    const { getOptionAvailableQuantities, getOptionSelectedQuantity } = useOptions()
    const isMobileDescriptionOpen = ref(false)

    return {
      getOptionAvailableQuantities,
      getOptionSelectedQuantity,
      isMobileDescriptionOpen,
    }
  },
})
