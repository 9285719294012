import { RootState } from '~/store/types'
import { CustomerLocal, GenderEnum } from '~/types/Models/Customer'
import { ShortToISOEnum, SupportedISOLocale } from '~/types/Locale'
import { PaymentForm, PaymentMethodEnum } from '~/types/Models/Payment'

export const customerDefault = (localeISOCode: SupportedISOLocale | null): CustomerLocal => ({
  billingAddress: {
    addressLine1: '',
    city: '',
    postalCode: '',
    country: null,
  },
  dateOfBirth: '',
  email: '',
  firstName: '',
  formattedPhone: '',
  gender: GenderEnum.GENDER_FEMALE,
  lang: localeISOCode || ShortToISOEnum.EN,
  lastName: '',
  phone: '',
})

export const paymentFormDefault: () => PaymentForm = () => ({
  cardNumber: '',
  ccv: '',
  holderName: '',
  month: '',
  type: PaymentMethodEnum.METHOD_CARD,
  year: '',
})

export const state = (): RootState => ({
  activeDiscountType: null,
  booking: null,
  bookingDates: null,
  cart: null,
  cartErrors: [],
  cartPreview: null,
  cartPreviewErrors: [],
  customer: customerDefault('fr-FR'), // Local mapping from/to cart.customer
  domain: null,
  domainAddress: null,
  domainHealthSafetyMeasures: [],
  guests: [], // Local mapping from/to cart.guests
  isNewCustomer: null,
  lastCartModification: null,
  marketplace: null,
  options: [],
  paymentErrors: [],
  paymentForm: paymentFormDefault(),
  promocode: { // Local mapping from/to cart.promocode
    code: null,
  },
  refererUrl: null,
  service: null,
  serviceRating: null,
  slugs: {
    marketplaceSlug: '',
    serviceSlug: '',
  },
  threeDS2Data: {
    clientIPAddress: '',
  },
  ui: {
    blockNextStep: false,
    cartLoading: 0,
    displayCartNotification: false,
    isServiceAvailable: true,
    loading: 0,
  },
  unavailableDates: [],
})

export default state
