import { BillingAddress } from './BillingAdress'

export type Gender = 'MALE' | 'FEMALE' | 'OTHER'

export enum GenderEnum {
  GENDER_FEMALE = 'FEMALE',
  GENDER_MALE = 'MALE',
  GENDER_OTHER = 'OTHER',
}

export interface BaseGuestInfo {
  dateOfBirth: string
  firstName?: string
  lastName?: string
}

export interface Customer extends BaseGuestInfo {
  gender: Gender
  phone: string
  formattedPhone?: string
  email: string
  billingAddress: BillingAddress

  [key: string]: any
}

export interface CustomerLocal extends Customer {
  formattedPhone?: string
}

export const CustomerRequiredFields: Array<keyof Customer> = [
  'dateOfBirth', 'gender', 'firstName', 'lastName', 'phone', 'email',
]
