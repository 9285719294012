





















import { defineComponent, computed, useContext } from '@nuxtjs/composition-api'
import useSteps from '~/composable/useSteps'
import useMarketplace from '~/composable/useMarketplace'
import useService from '~/composable/useService'

export default defineComponent({
  name: 'BackLink',
  setup() {
    const { app: { $accessor }} = useContext()
    const { prevStepRoute } = useSteps()
    const { isABCD } = useMarketplace()
    const { serviceUrl } = useService()

    const marketplaceUrl = computed(() => {
      if (isABCD.value) {
        return serviceUrl.value
      }
      if ($accessor.marketplace && $accessor.marketplace.id !== 331) {
        return $accessor.marketplace.website
      }
      return null
    })

    return {
      prevStepRoute,
      marketplaceUrl,
    }
  },
})
