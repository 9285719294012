import { getterTree } from 'typed-vuex'
import { state as baseState } from '~/store/users/state'

export const getters = getterTree(baseState, {
  getDisplayName(state): string {
    if (state.profile) {
      return `${state.profile.firstName} ${state.profile.lastName}`
    }
    if (state.user) {
      return state.user.email
    }
    return ''
  },
})

export default getters
