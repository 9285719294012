import Dinero from 'dinero.js'
import { Amount } from '~/types/Models/Amount'
import { Context } from '@nuxt/types'

export interface MoneyHook {
  isAmount(value: Record<string, unknown>): boolean,
  parseAmount(item: Amount, locale?: string): Dinero.Dinero,
  formatAmount(item: Amount, locale?: string, format?: string): string,
  toJson(item: Dinero.Dinero): { amount: number, currency: Dinero.Currency},
}

function useMoney(_ctx?: Context): MoneyHook {

  function isAmount(value: Record<string, unknown>) {
    return 'amount' in value && 'currency' in value
  }

  function parseAmount(item: Amount, locale = 'fr-FR') {
    return Dinero({
      amount: item.amount ? item.amount : 0,
      currency: item.currency ? item.currency : 'EUR',
    })
      .setLocale(locale)
  }

  function formatAmount(item: Amount, locale = 'fr-FR', format = '$0,0.00') {
    return parseAmount(item, locale).toFormat(format)
  }

  function toJson(item: Dinero.Dinero) {
    return {
      amount: item.getAmount(),
      currency: item.getCurrency(),
    }
  }

  return {
    isAmount,
    parseAmount,
    formatAmount,
    toJson,
  }
}

export default useMoney
