














































































































import { defineComponent, useContext, useMeta, computed } from '@nuxtjs/composition-api'
import { DateTime } from 'luxon'
import GuestInfo from '~/components/guest/GuestInfo.vue'
import ReassuranceCard from '~/components/content/ReassuranceCard.vue'
import UsersIcon from '~/components/icons/UsersIcon.vue'
import StepSectionTitle from '~/components/content/StepSectionTitle.vue'
import useCustomer from '~/composable/useCustomer'
import useGuests from '~/composable/useGuests'
import useSteps from '~/composable/useSteps'
import { calculateAgeAtDate } from '~/helpers'
import dayjs from 'dayjs'
import useMarketplace from '~/composable/useMarketplace'

export default defineComponent({
  name: 'StepBooking',
  components: {
    ReassuranceCard,
    StepSectionTitle,
    GuestInfo,
    UsersIcon,
  },
  beforeRouteLeave(_to, _from, next) {
    this.$accessor.updateCart()
    next()
  },
  setup() {
    const { app, app: { $accessor} } = useContext()

    const { getCustomerField } = useCustomer()

    const { isNextStepDisabled } = useSteps()

    const { isUSCOM } = useMarketplace()

    const {
      getGuestAdditionalPersonChargeText,
      getGuestDateOfBirth,
      getGuestsCount,
      getServiceExternalIdObject,
      guestLimitErrors,
      isGuestMajorityBetweenBookingStartNow,
      isGuestInfansBetweenBookingStartNow,
      watchGuests,
    } = useGuests()

    const getGuestAge = (index: number) => computed(() => {
      if ($accessor.getBookingDates?.start) {
        return calculateAgeAtDate(getGuestDateOfBirth(index).value, $accessor.getBookingDates?.start as dayjs.Dayjs)
      }
    })

    function getIsoFromJsDate(date: Date) {
      return DateTime.fromJSDate(date)
        .toISODate()
    }

    function onGuestInfoBlur(index: number): void {
      if (!getGuestDateOfBirth(index).value) {
        getGuestDateOfBirth(index).value = ''
      } else if (process.env.NODE_ENV !== 'production') {
        console.log(getGuestDateOfBirth(index).value)
      }
    }

    watchGuests()

    useMeta({
      title: `${app.$accessor.marketplace
        ? app.$translateEntityField(app.$accessor.marketplace.name)
        : 'Abracadaroom'} | ${app.i18n.t('steps.booking')}`,
    })

    return {
      getCustomerField,
      getGuestAdditionalPersonChargeText,
      getGuestAge,
      getGuestDateOfBirth,
      getGuestsCount,
      getIsoFromJsDate,
      getServiceExternalIdObject,
      guestLimitErrors,
      isGuestMajorityBetweenBookingStartNow,
      isGuestInfansBetweenBookingStartNow,
      isNextStepDisabled,
      onGuestInfoBlur,
      isUSCOM,
    }
  },
  head() {
    return {}
  },
})
