


































import { defineComponent } from '@nuxtjs/composition-api'
import BookingPreview from '~/components/preview/BookingPreview.vue'
import NextStepCta from '~/components/ui/NextStepCta.vue'
import CheckoutErrors from '~/components/checkout/CheckoutErrors.vue'

export default defineComponent({
  name: 'HomePage',
  components: {
    BookingPreview,
    NextStepCta,
    CheckoutErrors,
  },
  setup() {
    return {}
  },
})
