import { DataLayerEvent, EcommerceEventData, EcommerceObject } from '~/types/gtm'

export const pushToDataLayer = (obj: Record<string, any>) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(obj)
}

export const pushEventToDataLayer = (obj: DataLayerEvent) => {
  pushToDataLayer(obj)
}

const setUserData = (userId: string) => {
  pushEventToDataLayer({
    event: 'customUser',
    userData: {
      userId,
    },
  })
}

const createPageEvent = (path: string) => {
  const obj: DataLayerEvent = {
    event: 'customPage',
    pageData: {
      path,
    },
  }
  pushEventToDataLayer(obj)
}

const createEcommerceEvent = (data: EcommerceEventData) => {
  const obj: EcommerceObject = {
    event: 'customEcommerce',
    transactionId: data.id,
    transactionAffiliation: data.affiliation,
    transactionTotal: data.revenue,
    transactionShipping: data.shipping,
    transactionCurrency: data.currency,
  }
  pushEventToDataLayer(obj)
}

export const createGenericEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number,
  nonInteraction?: boolean,
) => {
  const obj: DataLayerEvent = {
    event: 'customEvent',
    eventData: {
      category,
      action,
      label,
      value,
      nonInteraction,
    },
  }
  pushEventToDataLayer(obj)
}

export default {
  createPageEvent,
  createEcommerceEvent,
  createGenericEvent,
  setUserData,
}
