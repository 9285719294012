
















import { defineComponent } from '@nuxtjs/composition-api'
import ShoppingBagIcon from '~/components/icons/ShoppingBagIcon.vue'
import useOptions from '~/composable/useOptions'

export default defineComponent({
  components: {
    ShoppingBagIcon,
  },
  setup() {
    const { getTotalOptionAmount } = useOptions()
    
    return {
      getTotalOptionAmount,
    }
  },
})
