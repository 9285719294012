<template>
<div class="tw-relative">
  <div class="tw-flex-col md:tw-flex-row tw-flex tw-items-center tw-w-full tw-leading-relaxed">
    <BackLink class="tw-hidden tw-w-full md:tw-block md:tw-w-1/3" />
    <div class="tw-w-full tw-flex tw-justify-center tw-items-center md:tw-w-1/3">
      <NuxtLink
        :to="localePath('checkout:step-booking')"
      >
        <img
          v-if="$accessor.entityFiles.getMarketplaceLogoFile"
          :src="$accessor.entityFiles.getMarketplaceLogoFile.url"
          :alt="$translateEntityField($accessor.entityFiles.getMarketplaceLogoFile.alt)"
          style="max-height: 50px;"
        >
      </NuxtLink>
    </div>
    <div class="tw-flex tw-space-x-10 tw-items-center md:tw-w-1/3 md:tw-justify-end">
      <LanguageSwitcher
        text-color="dark"
        :size="isWide ? 'is-medium' : 'is-small'"
        reload-page-on-change
        class="tw-mr-10"
      />
      <!-- Don't display it if not on affiliate marketplace -->
      <AccountButton v-if="clientAccountRedirect" />
    </div>
  </div>
</div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api'
import BackLink from '@/components/navigation/BackLink.vue'
import AccountButton from '@/components/ui/AccountButton.vue'
import useMarketplace from '~/composable/useMarketplace'

export default defineComponent({
  name: 'CheckoutHeader',
  components: {
    BackLink,
    AccountButton,
  },
  setup() {
    const { clientAccountRedirect } = useMarketplace()

    return {
      clientAccountRedirect,
    }
  },
})
</script>

<style>
.LanguageSwitcher .dropdown-menu {
  left: auto
}
</style>
