import localizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import fr from 'dayjs/locale/fr.js'
import en from 'dayjs/locale/en.js'
import es from 'dayjs/locale/es.js'
import nl from 'dayjs/locale/nl.js'
import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'

const locales: { [key: string]: ILocale } = {
  fr,
  en,
  es,
  nl,
}

export default function({ app, $dayjs }: Context, inject: Inject): void {
  $dayjs.locale(locales[app.i18n.locale])
  $dayjs.extend(utc)
  $dayjs.extend(localizedFormat)
  $dayjs.extend(customParseFormat)
  inject('utcDate', (value?: string) => $dayjs.utc(value).startOf('day'))
}
