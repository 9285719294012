





























import { computed, defineComponent, ref } from '@nuxtjs/composition-api'
import { findBestMatch, Rating } from 'string-similarity'
import XCircleIcon from '~/components/icons/XCircleIcon.vue'

// Source : https://www.badsender.com/2020/01/10/liste-domaine-email-destination/
const EMAIL_DOMAIN_LIST = [
  '9business.fr',
  '9online.fr',
  'alice.it',
  'aliceadsl.fr',
  'alicepro.fr',
  'aol.com',
  'aol.fr',
  'arcor.de',
  'autorambler.ru',
  'bbox.fr',
  'bk.ru',
  'blu.it',
  'bluewin.ch',
  'blueyonder.co.uk',
  'btinternet.com',
  'caramail.com',
  'cario.fr',
  'cegetel.net',
  'club-internet.fr',
  'comcast.net',
  'dbmail.com',
  'email.it',
  'facebook.com',
  'fnac.net',
  'free.fr',
  'freenet.de',
  'freesbee.fr',
  'giallo.it',
  'gmail.com',
  'gmx.at',
  'gmx.de',
  'gmx.fr',
  'gmx.net',
  'googlemail.com',
  'guideo.fr',
  'home.nl',
  'hotmail.be',
  'hotmail.co.uk',
  'hotmail.com',
  'hotmail.de',
  'hotmail.es',
  'hotmail.fr',
  'hotmail.it',
  'hotmail.nl',
  'hotmail.se',
  'icloud.com',
  'infonie.fr',
  'laposte.net',
  'lenta.ru',
  'libero.it',
  'libertysurf.fr',
  'live.be',
  'live.co.uk',
  'live.com.pt',
  'live.com',
  'live.de',
  'live.dk',
  'live.fr',
  'live.it',
  'live.nl',
  'live.se',
  'mac.com',
  'mageos.com',
  'mail.com',
  'mail.ru',
  'me.com',
  'movistar.es',
  'msn.be',
  'msn.com',
  'msn.fr',
  'myrambler.ru',
  'neuf.fr',
  'noos.fr',
  'numericable.fr',
  'o2.pl',
  'online.de',
  'online.fr',
  'ono.com',
  'orange.fr',
  'outlook.com',
  'outlook.fr',
  'r0.ru',
  'rambler.ru',
  'ro.ru',
  'rocketmail.com',
  'rocketmail',
  'sfr.fr',
  'sky.com',
  'skynet.be',
  't-online.de',
  'talktalk.net',
  'telefonica.es',
  'telenet.be',
  'tim.it',
  'tin.it',
  'tiscali.co.uk',
  'tiscali.it',
  'verizon.net',
  'virgilio.it',
  'voila.fr',
  'waika9.com',
  'wanadoo.fr',
  'web.de',
  'windowslive',
  'worldonline.fr',
  'wp.pl',
  'yahoo.co.uk',
  'yahoo.com.tw',
  'yahoo.com',
  'yahoo.de',
  'yahoo.es',
  'yahoo.fr',
  'yahoo.it',
  'yahoo.se',
  'yandex.ru',
  'ymail.com',
  'ymail',
]

export default defineComponent({
  components: { XCircleIcon },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const suggestions = ref<Rating[]>([])
    const mailInput = ref<HTMLInputElement | null>(null)
    
    const innerValue = computed({
      get: () => props.value,
      set: (value) => {
        computeSuggestions(value.toLowerCase())
        emit('input', value)
      },
    })

    function getSplittedEmail(value: string) {
      const splitted = value.split('@')
      return splitted
    }

    function onApplySuggestion(index: number) {
      if (suggestions.value[index]) {
        innerValue.value = suggestions.value[index]!.target
      }
      // Re-focus the input when closing suggestions for better UX  
      if (mailInput.value) {
        mailInput.value.focus()
      }
    }

    function onClearSuggestions() {
      suggestions.value = []
      // Re-focus the input when closing suggestions for better UX  
      if (mailInput.value) {
        mailInput.value.focus()
      }
    }

    function sortByRating(a: Rating, b: Rating) {
      if(a?.rating && b?.rating) {
        if (a?.rating < b?.rating) {
          return 1
        }
        if (a?.rating > b?.rating) {
          return -1
        }
      }
      return 0
    }

    function getMatchingValues(ratings: Rating[], address: string) {
      return ratings
        // Arbitrary threshold values based on real life testing
        .filter(value => value.rating >= 0.5 && value.rating < 1)
        // Sort by best match
        .sort(sortByRating)
        // Keep first 5 elements
        .splice(0, 5)
        // Generate array of values with full mail address
        .map(rating => ({...rating, target: `${address}@${rating.target}`}))
    }

    function computeSuggestions(value: string) {
      const [address, extension] = getSplittedEmail(value)
      // Only show suggestion once a '.' has been typed to avoid suggesting too early
      if (extension?.includes('.')) {
        const { ratings } = findBestMatch(extension, EMAIL_DOMAIN_LIST)

        // Don't do anything if we have a perfect match to avoid displaying suggestions for nothing
        if (ratings.some(rating => rating.rating === 1)) {
          onClearSuggestions()
          return
        }

        suggestions.value = getMatchingValues(ratings, address)
      } else {
        // Clear the suggestions to avoid showing it if the user backspaced and the conditions are no longer met
        onClearSuggestions()
      }
    }

    return {
      innerValue,
      mailInput,
      onApplySuggestion,
      onClearSuggestions,
      suggestions,
    }
  },
})
