



















import { defineComponent } from '@nuxtjs/composition-api'
import GiftIcon from '~/components/icons/GiftIcon.vue'

export default defineComponent({
  components: {
    GiftIcon,
  },
})
