

















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String,
      default: 'number',
    },
    status: {
      type: String as PropType<'success' | 'danger' | 'default'>,
      default: 'default',
    },
    symbol: {
      type: String,
      default: '€',
    },
  },
  setup(props, { emit }) {
    const model = computed({
      get: () => props.value,
      set: (value: string | number) =>  emit('input', value),
    })

    return {
      model,
    }
  },
})
