









import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { OptionCategory } from '~/types/Models'

export default defineComponent({
  props: {
    category: {
      type: String as PropType<OptionCategory>,
      required: true,
    },
  },
  setup() {
    
  },
})
