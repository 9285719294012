





































import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import ChevronDownIcon from '~/components/icons/ChevronDownIcon.vue'
import ChevronUpIcon from '~/components/icons/ChevronUpIcon.vue'

export default defineComponent({
  components: {
    ChevronUpIcon,
    ChevronDownIcon,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String as PropType<'white' | 'primary-dark' | 'primary-darker'>,
      default: 'white',
    },
  },
  setup(props) {
    const isOpenInner = ref(props.isOpen)

    const bgColor = computed(() => `tw-bg-${props.color}`)
    const textColor = computed(() => props.color === 'white' ? 'has-text-dark' : 'tw-text-white')

    function onToggle() {
      isOpenInner.value = !isOpenInner.value
    }
    
    return {
      bgColor,
      isOpenInner,
      onToggle,
      textColor,
    }
  },
})

