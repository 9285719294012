import { computed, ComputedRef, useContext, WritableComputedRef } from '@nuxtjs/composition-api'
import { stepRoutes } from '~/routes'
import { Route } from 'vue-router'
import { RouteStoreState } from '~/types/store-accessor'

export interface StepsHook {
  prevStepRoute: ComputedRef<any>,
  nextStepRoute: ComputedRef<any>,
  getRouteIndex: (_route: Route | RouteStoreState) => number | null,
  isInFinishPage: ComputedRef<boolean>,
  isNextStepDisabled: ComputedRef<boolean>,
  isNextStepLoading: ComputedRef<boolean>,
  goToNextStep: () => void,
  stepModel: WritableComputedRef<number>,
  redirectOptionsStep: () => Promise<void>,
  hasOptionsStep: ComputedRef<boolean>,
}

/**
 * Centralized checkout steps management.
 */
function useSteps(): StepsHook {
  const {
    app,
    route,
    store,
    store: { $accessor, getRouteBaseName },
  } = useContext()

  function getRouteIndex(_route: Route): number | null {
    if (stepRoutes && _route.name) {
      const index = stepRoutes.findIndex(r => r.name === _route.name!.split('___')[0])
      if (typeof index !== 'undefined') {
        return index
      }
    }
    return null
  }

  const isNextStepDisabled = computed(
    () => $accessor.ui.cartLoading > 0
      || $accessor.ui.loading > 0
      || $accessor.ui.blockNextStep
      || !$accessor.ui.isServiceAvailable
      || $accessor.cartErrors.length > 0,
  )

  const isNextStepLoading = computed(() => $accessor.ui.cartLoading > 0 || $accessor.ui.loading > 0)

  const currentRouteIndex = computed(() => {
    if (stepRoutes && route) {
      return stepRoutes.findIndex(r => r.name === route.value.name!.split('___')[0])
    }
  })

  const prevStepRoute = computed(() => {
    if (stepRoutes && currentRouteIndex.value) {

      return stepRoutes[currentRouteIndex.value - 1]
    }
  })

  const nextStepRoute = computed(() => {
    if (stepRoutes && currentRouteIndex.value !== undefined) {
      return stepRoutes[currentRouteIndex.value + 1] || null
    }
  })

  function goToNextStep() {
    if (nextStepRoute.value && !isNextStepDisabled.value && app.router && nextStepRoute.value.name) {
      app.router.push(app.localePath(nextStepRoute.value.name))
    } else {
      $accessor.payCart()
    }
  }

  const stepModel = computed({
    get: () => {
      let index
      if (stepRoutes) {
        index = Object.keys(stepRoutes)
          .find((_, i: number) => {
            return stepRoutes![i].name === route.value.name?.split('___')[0]
          })
      }
      const step = parseInt(index ? index : 'O')
      if (!$accessor.getHasNonMandatoryOptions && step > 0) {
        return step - 1
      }
      return step
    },
    set: async(value) => {
      // TODO here we should check if step is a previous step in order to unlock next step CTA.
      if (app.router && stepRoutes) {
        if (value === currentRouteIndex.value || !stepRoutes[value]) {
          return
        }
        await app.router.push(app.localePath(stepRoutes[value].name!))
      }
    },
  })

  // Check for checkout:step-options route,
  // redirect if no options are available while still triggering analytics.
  async function redirectOptionsStep() {
    if (!$accessor.getHasNonMandatoryOptions) {
      let prevIndex: number | null = null
      if (store.state.route?.from?.name) {
        // NOTE: here we use store.state.route,
        // otherwise we would need to make $accessor aware of vuex-router-sync plugin.
        if (store.state.route.from.name.split('___')[0] !== 'checkout:step-options') {
          prevIndex = getRouteIndex(store.state.route.from)
        } else {
          prevIndex = getRouteIndex(store.state.route as any)
        }
      }
      if (app.router && stepRoutes) {
        if (prevIndex !== null) {
          // Compute a new route to redirect to based on the previous index
          const fixedIndex = prevIndex === 0 || prevIndex === 3 ? 2 : 0
          await app.router.push(app.localePath(stepRoutes[fixedIndex]))
        } else {
          // If no prevIndex, we're probably coming from nowhere.
          await app.router.push(app.localePath(stepRoutes[0]))
        }
      }
    }
  }

  const hasOptionsStep = computed(() => $accessor.getHasAvailableOptions)

  const isInFinishPage = computed(() => getRouteBaseName(route.value) === 'checkout:finish')

  return {
    prevStepRoute,
    nextStepRoute,
    getRouteIndex,
    isInFinishPage,
    isNextStepDisabled,
    isNextStepLoading,
    goToNextStep,
    stepModel,
    redirectOptionsStep,
    hasOptionsStep,
  }
}

export default useSteps
