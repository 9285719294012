import { DateTime } from 'luxon'
import { DatePeriodOf } from '~/types/Models/DatePeriod'
import dayjs from 'dayjs'

export enum WeekDayNamesEnum {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export const weekDayNamesArray = [
  WeekDayNamesEnum.MONDAY,
  WeekDayNamesEnum.THURSDAY,
  WeekDayNamesEnum.WEDNESDAY,
  WeekDayNamesEnum.THURSDAY,
  WeekDayNamesEnum.FRIDAY,
  WeekDayNamesEnum.SATURDAY,
  WeekDayNamesEnum.SUNDAY,
]

export const castISODatesToLuxon = (items: Record<string, any>[], fields: string | string[], locale: string): Record<string, unknown>[] => {

  const itemFields = !Array.isArray(fields) ? new Array(fields) : fields

  return items.map((item) => {
    itemFields.forEach((field) => {
      if (field in item) {
        const date = DateTime.fromISO(item[field])
        if (date.isValid) {
          item[field] = date.setLocale(locale)
        }
      }
    })
    return item
  })
}

export const castISOPeriodToDatePeriod = (period: DatePeriodOf<string>): DatePeriodOf<Date> => {
  return {
    start: dayjs.utc(period.start).toDate(),
    end: dayjs.utc(period.end).toDate(),
  }
}

export const castDatePeriodToISOPeriod = (period: DatePeriodOf<Date>, withTime = false): DatePeriodOf<string> => {
  return withTime ? {
    start: dayjs.utc(period.start).toISOString(),
    end: dayjs.utc(period.end).toISOString(),
  } : {
    start: dayjs.utc(period.start).format('YYYY-MM-DD'),
    end: dayjs.utc(period.end).format('YYYY-MM-DD'),
  }
}

export const isDateInPeriod = (date: string, periodStart: string, periodEnd: string, recurring: boolean): boolean => {
  const _date = DateTime.fromISO(date)
  let _periodStart = DateTime.fromISO(periodStart)
  let _periodEnd = DateTime.fromISO(periodEnd)

  if (recurring) {
    _periodStart = _periodStart.set({ year: _date.year })
    _periodEnd = _periodEnd.set({ year: _date.year })
  }

  return _date >= _periodStart && _date <= _periodEnd
}
