import { ThreeDS2Data } from '~/types/3DS2'

export type PaymentMethod = 'CREDIT_CARD' | 'BCMC' | 'IDEAL' | 'GIFT_CARD'

export enum PaymentMethodEnum {
  METHOD_CARD = 'CREDIT_CARD',
  METHOD_BCMC = 'BCMC',
  METHOD_IDEAL = 'IDEAL',
  METHOD_GIFT_CARD = 'GIFT_CARD',
}

export interface PaymentForm {
  type: PaymentMethod
  cardNumber: string
  month: string
  year: string
  ccv: string
  holderName: string

  [key: string]: string | PaymentMethod
}

export interface PaymentFormWith3DSData extends PaymentForm {
  threeDS2Data: ThreeDS2Data

  [key: string]: any
}

export interface GiftCardPaymentForm {
  type: PaymentMethod
  isFullGiftCardPayment: boolean
}

export enum DiscountTypeEnum {
  DISCOUNT_PROMOCODE = 'PROMOCODE',
  DISCOUNT_GIFTCARDS = 'GIFTCARDS',
}

export type DiscountType = DiscountTypeEnum.DISCOUNT_PROMOCODE | DiscountTypeEnum.DISCOUNT_GIFTCARDS | null
