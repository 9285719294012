import { NuxtCookies } from 'cookie-universal-nuxt'
import { CartSlugs } from '~/types/CartSlugs'

export function getCartSlugsFromCookies(cookies: NuxtCookies): CartSlugs | null {
  if (cookies.get('cartSlugs')) {
    return cookies.get('cartSlugs')
  }
  return null
}

