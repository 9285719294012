
































import { defineComponent } from '@nuxtjs/composition-api'
import RefreshIcon from '~/components/icons/RefreshIcon.vue'
import useCartChangeNotifications from '~/composable/useCartChangeNotifications'
import CheckCircleIcon from '~/components/icons/CheckCircleIcon.vue'

export default defineComponent({
  components: {
    RefreshIcon,
    CheckCircleIcon,
  },
  setup() {
    const { getNotificationContent } = useCartChangeNotifications()

    return {
      getNotificationContent,
    }
  },
})
