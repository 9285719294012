import { createEntity, createState } from '@kissmylabs/vuex-entitystore'
import { EntityFilesState } from './types'

export const entityFile = createEntity('entityFile')

export const state = (): EntityFilesState => ({
  ...createState(entityFile),
})

export default state
