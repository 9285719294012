




































































import { computed, defineComponent, ref, useContext, WritableComputedRef, onMounted, onBeforeUnmount } from '@nuxtjs/composition-api'
import { castDatePeriodToISOPeriod, castISOPeriodToDatePeriod } from '~/helpers'
import { DiscountTypeEnum } from '~/types/Models/Payment'
import { DatePeriodOf } from '~/types/Models/DatePeriod'
import useOptions from '~/composable/useOptions'
import PriceDisplay from '~/components/content/PriceDisplay.vue'
import PreviewDates from '~/components/preview/PreviewDates.vue'
import PreviewDiscount from '~/components/preview/PreviewDiscount.vue'
import PreviewGiftcards from '~/components/preview/PreviewGiftcards.vue'
import PreviewGuests from '~/components/preview/PreviewGuests.vue'
import PreviewAmount from '~/components/preview/PreviewAmount.vue'
import PreviewReassurance from '~/components/preview/PreviewReassurance.vue'
import ServiceInfo from '~/components/preview/ServiceInfo.vue'
import PreviewOptions from '~/components/preview/PreviewOptions.vue'
import PreviewMandatoryOptions from '~/components/preview/PreviewMandatoryOptions.vue'
import Disclosure from '~/components/ui/Disclosure.vue'
import PromocodeForm from '~/components/payment/PromocodeForm.vue'
import GiftCardsForm from '~/components/payment/GiftCardsForm.vue'
import PreviewPromocode from '~/components/preview/PreviewPromocode.vue'
import PreviewAdditionalGuestsPrices from '~/components/preview/PreviewAdditionalGuestsPrices.vue'
import PreviewTotalAmount from '~/components/preview/PreviewTotalAmount.vue'
import PreviewDomainHealthSafetyMeasure from '~/components/preview/PreviewDomainHealthSafetyMeasure.vue'
import useGuests from '~/composable/useGuests'
import useSteps from '~/composable/useSteps'
import useMarketplace from '~/composable/useMarketplace'
import { throttle } from 'lodash'


export default defineComponent({
  name: 'BookingPreview',
  components: {
    Disclosure,
    GiftCardsForm,
    PreviewAmount,
    PreviewDates,
    PreviewDiscount,
    PreviewDomainHealthSafetyMeasure,
    PreviewGiftcards,
    PreviewGuests,
    PreviewMandatoryOptions,
    PreviewOptions,
    PreviewPromocode,
    PreviewAdditionalGuestsPrices,
    PreviewReassurance,
    PreviewTotalAmount,
    PriceDisplay,
    PromocodeForm,
    ServiceInfo,
  },
  props: {
    isPostPayment: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { app: { $accessor } } = useContext()
    const { selectedOptions, getOptionById } = useOptions()
    const { getAdditionalPersonsWithPrices } = useGuests()
    const { isInFinishPage } = useSteps()
    const { isFairmoove } = useMarketplace()

    const isShowDatesChange = ref(false)

    const wrapper = ref<HTMLElement | null>(null)

    const priceDisplayWidth = ref<null | number>(0)

    onMounted(() => {
      const interval = setInterval(() => {
        if (wrapper.value) {
          clearInterval(interval)
          setTimeout(() => {
            priceDisplayWidth.value = wrapper.value!.getBoundingClientRect().width
          }, 250)
          window.addEventListener('resize', throttle(() => {
            return priceDisplayWidth.value = wrapper.value!.getBoundingClientRect().width
          }, 100))
        }
      }, 250)
    })

    onBeforeUnmount(() => window.removeEventListener('resize', () => priceDisplayWidth.value = null))

    const bookingDates: WritableComputedRef<DatePeriodOf<Date> | null> = computed({
      get: () => {
        if ($accessor.bookingDates) {
          return castISOPeriodToDatePeriod($accessor.bookingDates as DatePeriodOf<string>)
        }
        return {
          start: new Date(),
          end: new Date(),
        }
      },
      set: (value) => {
        if (value) {
          $accessor.changeBookingDates(castDatePeriodToISOPeriod(value))
        }
      },
    })

    return {
      bookingDates,
      DiscountTypeEnum,
      getAdditionalPersonsWithPrices,
      getOptionById,
      isInFinishPage,
      isFairmoove,
      isShowDatesChange,
      priceDisplayWidth,
      selectedOptions,
      wrapper,
    }
  },
})
