















































































import {
  computed, defineComponent, onBeforeMount, onMounted, reactive, Ref, ref, useContext, useMeta, watch,
} from '@nuxtjs/composition-api'
import BackLink from '~/components/navigation/BackLink.vue'
import CheckoutHeader from '~/components/header/CheckoutHeader.vue'
import CheckoutSteps from '~/components/checkout/CheckoutSteps.vue'
import QuestionMarkIcon from '~/components/icons/QuestionMarkIcon.vue'
import PriceAndCta from '~/components/ui/PriceAndCta.vue'
import TagIcon from '~/components/icons/TagIcon.vue'
import LockIcon from '~/components/icons/LockIcon.vue'
import TheFooter from '~/components/footer/TheFooter.vue'
import useLoadingScreen from '~/composable/useLoadingScreen'
import useTheme from '~/composable/useTheme'
import useSubscribe, { provideSubscribers } from '~/composable/useSubscribe'
import useMarketplace from '~/composable/useMarketplace'
import Vue from 'vue'
import { collectBrowserInfo } from '~/helpers/3ds2'
import { getRouteName } from '~/helpers/route'

export default defineComponent({
  components: {
    BackLink,
    CheckoutHeader,
    CheckoutSteps,
    LockIcon,
    PriceAndCta,
    QuestionMarkIcon,
    TagIcon,
    TheFooter,
  },
  setup() {
    provideSubscribers()

    const { app, store, app: { router } } = useContext()
    const { colorPrimary, colorDark, colorPrimaryDark, colorAccent, colorContrast, hexToRGBStrings } = useTheme()
    const { state: loadingScreenState } = useLoadingScreen()
    const { subscribeMutations } = useSubscribe()
    const { isFairmoove, marketplaceLinks, isUSCOM } = useMarketplace()

    const checkoutCtaWrapper: Ref<HTMLElement | null> = ref(null)
    // Vue Element is too generic and misses .style attribute from HTMLElement.
    const footerRef: Ref<Vue & { $el?: HTMLElement } | null> = ref(null)

    const isChatbotEnabled = ref(false)
    const isThankYouPage = computed(() => getRouteName(router.currentRoute) === 'checkout:finish')

    const state = reactive({
      checkoutStep: 1,
      loadingScreen: true,
    })

    onBeforeMount(async() => {
      await store.$accessor.fetchAfterLoadData()

      subscribeMutations([{
        mutation: 'UPDATE_CUSTOMER',
        handler: () => store.$accessor.updateCart(),
      }])
    })

    onMounted(() => {
      // @ts-ignore because $crisp is not part of "Window & typeof globalThis" type
      if (window.$crisp) {
        isChatbotEnabled.value = true
      }

      // Get client information for 3DS2 authentication.
      store.$accessor.SET_3DS2_CLIENT_INFO(collectBrowserInfo())
    })

    watch(() => store.state.ui.loading, (val) => {
      if (!val && loadingScreenState.isVisible) {
        loadingScreenState.isVisible = false
      }
    })

    useMeta({
      title: app.$accessor.marketplace
        ? app.$translateEntityField(app.$accessor.marketplace.name)
        : 'Abracadaroom',
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width, height=device-height,  initial-scale=1.0, user-scalable=no;user-scalable=0;',
        },
      ],
      link: [{
        rel: 'icon',
        type: 'image/ico',
        href: isUSCOM.value ? '/favicon-us.ico' : '/favicon.ico',
      }],
    })

    const devState = process.env.NODE_ENV === 'development' ? { storeState: computed(() => store.state) } : {}

    return {
      ...devState,
      checkoutCtaWrapper,
      colorAccent,
      colorContrast,
      colorDark,
      colorPrimary,
      colorPrimaryDark,
      footerRef,
      hexToRGBStrings,
      isChatbotEnabled,
      isThankYouPage,
      loadingScreenState,
      isFairmoove,
      state,
      marketplaceLinks,
    }
  },
  head() {
    return {}
  },
})
