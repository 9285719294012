













































































































































































































































































































































































import { defineComponent } from '@nuxtjs/composition-api'
import useTheme from '~/composable/useTheme'

export default defineComponent({
  name: 'LoadingScreen',
  props: {
    step: {
      type: Number,
      default: 1,
      required: false,
    },
  },
  setup() {
    const { colorPrimary, colorDark } = useTheme()

    return {
      colorPrimary,
      colorDark,
    }
  },
})
