import Vue from 'vue'
import VueMq from 'vue-mq'

export const breakpoints = {
  xs: 575,
  sm: 769,
  md: 1024,
  lg: 1216,
  xl: 1408,
}

Vue.use(VueMq, {
  breakpoints,
  defaultBreakpoint: 'md',
})

Vue.mixin({
  computed: {
    isMobile() {
      return ['xs', 'sm'].includes(this.$mq)
    },
    isTouch() {
      return ['xs', 'sm', 'md'].includes(this.$mq)
    },
    isWide() {
      return ['lg', 'xl'].includes(this.$mq)
    },
  },
})
