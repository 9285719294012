import Vue from 'vue'
import { configure, extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { NuxtAppOptions } from '@nuxt/types'
import { ValidationRule } from 'vee-validate/dist/types/types'
import { isAdult } from '~/helpers'

export default function(ctx: NuxtAppOptions): void {
  // Configure globally the default message to use vue-i18n,
  // see https://logaretm.github.io/vee-validate/guide/localization.html#using-other-i18n-libraries
  configure({
    defaultMessage: (field, values) => {
      values._field_ = ctx.app.i18n.t(`form.fields.${field}`)
      return ctx.app.i18n.t(`form.validations.${values._rule_}`, values)
    },
  })

  extend('card_number', {
    validate: value => {
      return value && value.length === 16
    },
  })
  // Special version of card_number with 19 digits to account for whitespaces between 4 digit blocks.
  extend('card_number_masked', {
    validate: value => {
      return value && value.length === 19
    },
  })
  extend('bcmc_number', {
    validate: value => {
      return value && value.length === 17
    },
  })
  // Special version of bcmc_number with 21 digits: 17 digits + 4 whitespaces.
  extend('bcmc_number_masked', {
    validate: value => {
      return value && value.length === 21
    },
  })
  extend('card_month', {
    validate: value => {
      if(value.length < 2) {
        return false
      }
      if (Number.parseInt(value) >= 1 && Number.parseInt(value) <= 12) {
        return true
      } else {
        return false
      }
    },
  })
  extend('card_year', {
    validate: value => {
      if (Number.parseInt(value) >= parseInt((new Date()).getFullYear()
        .toString()
        .substr(2, 2))) {
        return true
      } else {
        return false
      }
    },
  })
  extend('main_guest_birthdate_required', {
    validate: (value: string) => {
      return !!value
    },
  })
  extend('main_guest_must_be_adult', {
    validate: (value: string) => {
      return isAdult(value)
    },
  })
  extend('additional_guest_birthdate_required', {
    validate: (value: string) => {
      return !!value
    },
  })

  // Import all vee-validate rules,
  // see https://logaretm.github.io/vee-validate/guide/rules.html#installing-all-rules
  for (const [rule, validation] of Object.entries(rules)) {
    extend(rule, validation as ValidationRule)
  }

  // Register components globally,
  // see https://logaretm.github.io/vee-validate/guide/basics.html#registering-the-validation-provider
  Vue.component('ValidationProvider', ValidationProvider)
  Vue.component('ValidationObserver', ValidationObserver)
}
