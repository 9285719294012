import { computed, ComputedRef, useContext, watch, WritableComputedRef } from '@nuxtjs/composition-api'
import '~/helpers/polyfills'
import { Cart, ThirdPartyData } from '~/types/Models/Cart'
import { Path, PathValue } from '~/types/types'
import { DiscountType, DiscountTypeEnum } from '~/types/Models/Payment'
import { GenderEnum } from '~/types/Models'

const DEFAULT_THIRD_PARTY_DATA = {
  contactEmail: '',
  firstName: '',
  lastName: '',
  gender: GenderEnum.GENDER_FEMALE,
  isNotificationReceiver: false,
  phoneNumber: '',
}


export interface CartHook {
  activeDiscountType: WritableComputedRef<DiscountType>
  getCartField<F extends Path<Cart>>(field: F): WritableComputedRef<PathValue<Cart, F>>
  getCartThirdPartyDataField: <F extends keyof ThirdPartyData>(field: F) => WritableComputedRef<ThirdPartyData[F]>
  isFullGiftCardPayment: ComputedRef<boolean>
}

function useCart(): CartHook {
  const { app } = useContext()
  const {
    $accessor,
  } = app

  const activeDiscountType = computed({
    get: () => $accessor.activeDiscountType,
    set: async(value) => {
      $accessor.CLEAR_CART_ERRORS()
      await $accessor.changeDiscountTypeAndUpdate(value)
    },
  })


  watch(() => $accessor.cart?.giftCards, () => {
    if ($accessor.cart?.giftCards.length && activeDiscountType.value !== DiscountTypeEnum.DISCOUNT_GIFTCARDS) {
      activeDiscountType.value = DiscountTypeEnum.DISCOUNT_GIFTCARDS
    }
  }, { deep: true, immediate: true })

  watch(() => $accessor.cart?.promocode, () => {
    if ($accessor.cart?.promocode && activeDiscountType.value !== DiscountTypeEnum.DISCOUNT_PROMOCODE) {
      activeDiscountType.value = DiscountTypeEnum.DISCOUNT_PROMOCODE
    }
  }, { immediate: true })

  const getCartField = <P extends Path<Cart>>(field: P): WritableComputedRef<PathValue<Cart, P>> => computed({
    get: () => $accessor.getField(`cart.${field}`),
    set: val => {
      $accessor.UPDATE_FIELD({
        path: `cart.${field}`,
        value: val,
      })
    },
  })

  watch(
    () => $accessor.cart?.isThirdPartyBooking,
    (val) => {
      if (val) {
        getCartField('thirdPartyData').value = getCartField('thirdPartyData').value || DEFAULT_THIRD_PARTY_DATA
        return
      }
      getCartField('thirdPartyData').value = null
    }, { immediate: true })


  const getCartThirdPartyDataField = (field: keyof ThirdPartyData) => computed({
    get: () => $accessor.getField('cart.thirdPartyData')[field],
    set: (val) => {
      $accessor.UPDATE_FIELD({
        path: `cart.thirdPartyData.${field}`,
        value: val,
      })
    },
  })

  const isFullGiftCardPayment: ComputedRef<boolean> = computed(() => {
    return app.$accessor.getIsFullGiftCardPayment
  })

  return <CartHook>{
    activeDiscountType,
    getCartField,
    getCartThirdPartyDataField,
    isFullGiftCardPayment,
  }
}

export default useCart
