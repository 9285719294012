export interface BillingAddress {
  addressLine1: string
  addressLine2?: string
  addressLine3?: string
  postalCode: string
  city: string
  subdivision?: string
  subdivision2?: string
  country: string | null

  [key: string]: any
}

export const BillingAdressRequiredFields: Array<keyof BillingAddress> = [
  'addressLine1', 'postalCode', 'city', 'country',
]
