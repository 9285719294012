

































import { defineComponent, computed, useContext } from '@nuxtjs/composition-api'
import LockOpenIcon from '~/components/icons/LockOpenIcon.vue'
import DocumentTextIcon from '~/components/icons/DocumentTextIcon.vue'
import { ShortToISOEnum } from '~/types/Locale'
import useMarketplace from '~/composable/useMarketplace'

export default defineComponent({
  components: {
    LockOpenIcon,
    DocumentTextIcon,
  },
  setup() {
    const { app: { $accessor, i18n }, $config, $sentry } = useContext()
    const { isFairmoove, isTreego, marketplaceLinks } = useMarketplace()

    const redirectUrl = computed(() => {
      const locale = i18n.locale
      if (!isTreego.value) {
        return marketplaceLinks.value.cancellationInsuranceUrl[ShortToISOEnum[locale.toUpperCase() as keyof typeof ShortToISOEnum]]
      }
      // For Treego, always redirect to NL version
      return marketplaceLinks.value.cancellationInsuranceUrl[ShortToISOEnum.NL]
    })

    async function openConditionsPdf() {
      try {
        const cartId = $accessor.cart?.sessionId
        const url = `${$config.axios.baseURL}/carts/${cartId}/policies/download`

        const a = document.createElement('a')
        a.target = '_blank'
        a.href = url
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      } catch (e) {
        $sentry.captureException('Failed to open conditions PDF')
      }
    }

    return {
      isFairmoove,
      redirectUrl,
      openConditionsPdf,
    }
  },
})
