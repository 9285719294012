import { Plugin } from '@nuxt/types'
import Vue from 'vue'
import VueGtag from 'vue-gtag'

const vueGtag: Plugin = ({ app }) => {
  Vue.use(
    VueGtag,
    {
      pageTrackerEnabled: false,
      bootstrap: false,
      config: {
        id: '',
      },
    },
    app.router,
  )
}

export default vueGtag
