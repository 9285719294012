import each from 'lodash/each'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { NuxtAppOptions } from '@nuxt/types'
import axiosRetry from 'axios-retry'
import { shortLocaleToRegionLocale } from '~/helpers/locale'
import omit from 'lodash/omit'

export type ApiMethods = {
  getBaseUrl(): string,
  onAxiosRequest(config: AxiosRequestConfig): void
  onAxiosResponse(_: AxiosResponse<any>): void
  onAxiosError(err: AxiosError<any>): void
  onAxiosRequestError(err: AxiosError<any>): void
  onAxiosResponseError(err: AxiosError<any>): void
  get(path: string, retries?: number, config?: AxiosRequestConfig): Promise<any>
  post(path: string, data?: any, config?: Record<string, unknown>): Promise<any>
  patch(path: string, data?: any, config?: Record<string, unknown>): Promise<any>
  delete(path: string): Promise<any>
}

enum HttpMethods {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

class HttpResponseCodeError extends TypeError {
}

interface HttpHeaders {
  [key: string]: string
}

export class Api {
  headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  static authCookieName = 'us_auth'

  context
  axios
  sentry
  baseUrl

  constructor(context: NuxtAppOptions) {
    this.context = context

    this.sentry = context.$sentry

    this.axios = this.context.$axios

    this.baseUrl = context.$config.axios.baseURL

    this._setHeaders(this.headers)

    this._setInterceptors()
  }

  _setHeaders(headers: HttpHeaders): void {
    each(headers, (value, name) => this.axios.setHeader(name, value))
    this.axios.setHeader(
      'Accept-Language',
      `${shortLocaleToRegionLocale(this.context.app.i18n.locale)}, ${this.context.app.i18n.locale};q=0.9, en;q=0.8`,
    )
  }

  getBaseUrl(): string {
    return this.baseUrl
  }

  _setInterceptors(): void {
    this.axios.onRequest((config) => {
      this.onAxiosRequest(config)
    })

    this.axios.onResponse((response) => {
      this.onAxiosResponse(response)
    })

    this.axios.onError((error) => {
      this.onAxiosError(error)
    })

    this.axios.onRequestError((error) => {
      this.onAxiosRequestError(error)
    })

    this.axios.onResponseError((error) => {
      this.onAxiosResponseError(error)
    })
  }

  onAxiosRequest(config: AxiosRequestConfig): void {
    this._forwardXdebugSession(config)
    if (process.env.NODE_ENV === 'development') {
      console.log(`on Axios request: [${config.method}] ${config.baseURL}${config.url}`)
    }
  }

  onAxiosResponse(_: AxiosResponse<any>) { // eslint-disable-line
  }

  onAxiosError(_: AxiosError<any>) { // eslint-disable-line
  }

  onAxiosRequestError(err: AxiosError<any>): void {
    console.error(`onAxiosRequestError: ${err}`)
  }

  onAxiosResponseError(err: AxiosError<any>): void {
    console.error(
      `Axios ${err.request.method} request to ${err.response?.config.url} failed with code ${err.response?.status} ${err.response?.statusText}`,
      err,
      omit(err.response?.data || {}, 'trace'),
      '(from onAxiosResponseError)',
    )
  }

  /**
   * Add XDEBUG_SESSION_START=1 query to every URL if a XDEBUG_SESSION cookie is present.
   */
  _forwardXdebugSession(config: AxiosRequestConfig): void {
    if (config.url && process.env.NODE_ENV === 'development' && this.context.app.$cookies.get('XDEBUG_SESSION')) {
      const xdebugQuery = `${config.url.includes('&') || config.url.includes('?') ? '&' : '?'}XDEBUG_SESSION_START=1`
      config.url += xdebugQuery
    }
  }

  _raiseFromStatus(type: HttpMethods, response: AxiosResponse): void {
    if (
      (type === HttpMethods.GET && ![200, 202].includes(response.status)) ||
      ([HttpMethods.POST, HttpMethods.PATCH, HttpMethods.PUT].includes(type) && ![200, 201, 202, 204, 205, 206, 207].includes(response.status)) ||
      (type === HttpMethods.DELETE && ![202, 204].includes(response.status))
    ) {
      throw new HttpResponseCodeError(`Invalid HTTP status code in ${type} request: ${response}`)
    }
  }

  async get(path: string, retries = 0, config?: AxiosRequestConfig): Promise<any> {
    if (retries) {
      axiosRetry(this.axios, { retries })
    }
    const res = await this.axios.get(path, config)
    this._raiseFromStatus(HttpMethods.GET, res)
    if (retries) {
      axiosRetry(this.axios, { retries: 0 })
    }
    return res.data
  }

  async post<T>(path: string, data?: T, config?: Record<string, unknown>): Promise<any> {
    const res = await this.axios.post(path, data, config)
    this._raiseFromStatus(HttpMethods.POST, res)
    return res.data
  }

  async patch<T>(path: string, data?: T, config?: Record<string, unknown>): Promise<any> {
    const res = await this.axios.patch(path, data, config)
    this._raiseFromStatus(HttpMethods.PATCH, res)
    return res.data
  }

  async delete(path: string): Promise<any> {
    const res = await this.axios.delete(path)
    this._raiseFromStatus(HttpMethods.DELETE, res)
    return res.data
  }
}

export const isAxiosError: (e: AxiosError) => boolean = e => 'response' in (e || {}) && 'data' in (e.response || {})

export const isExpectableError: (e: AxiosError) => boolean = e => 'code' in (e.response?.data || {})

