var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CheckoutStep infos"},[_c('div',{staticClass:"CheckoutStep-section"},[_c('ReassuranceCard',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('PhoneIcon',{staticClass:"tw-h-24 tw-w-24 md:tw-h-32 md:tw-w-32"})]},proxy:true},{key:"title",fn:function(){return [_vm._v("\n        "+_vm._s(_vm.$t('step_cards.info_title'))+"\n      ")]},proxy:true}])}),_vm._v(" "),_c('StepSectionTitle',{attrs:{"title":_vm.$t('step_cards.info_subtitle')}}),_vm._v(" "),(!_vm.isLoggedIn)?_c('a',{staticClass:"tw-text-secondary hover:tw-text-primary",attrs:{"href":(_vm.clientAccountUrl + "/auth/login?origin=" + _vm.clientAccountRedirect)}},[_vm._v("\n      "+_vm._s(_vm.$t('customer.has_client_account'))+" >\n    ")]):_vm._e(),_vm._v(" "),_c('ValidationObserver',{ref:"observer",staticClass:"GeneralInformations is-relative",attrs:{"tag":"div"}},[_c('form',{staticClass:"tw-grid tw-grid-cols-6 tw-gap-12",attrs:{"action":""}},[_c('CustomerGenderSelector',{staticClass:"mb-2 tw-col-span-6",model:{value:(_vm.getCustomerField('gender').value),callback:function ($$v) {_vm.$set(_vm.getCustomerField('gender'), "value", $$v)},expression:"getCustomerField('gender').value"}}),_vm._v(" "),_c('ValidationProvider',{staticClass:"tw-col-span-6 md:tw-col-span-2",attrs:{"name":"lastname","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('form.lastname') + '*',"label-position":"inside","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('BInput',{model:{value:(_vm.getCustomerField('lastName').value),callback:function ($$v) {_vm.$set(_vm.getCustomerField('lastName'), "value", $$v)},expression:"getCustomerField('lastName').value"}})],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"tw-col-span-6 md:tw-col-span-2",attrs:{"name":"firstname","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('form.firstname') + '*',"label-position":"inside","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('BInput',{model:{value:(_vm.getCustomerField('firstName').value),callback:function ($$v) {_vm.$set(_vm.getCustomerField('firstName'), "value", $$v)},expression:"getCustomerField('firstName').value"}})],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"tw-col-span-6 md:tw-col-span-3",attrs:{"name":"email","tag":"div","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.$t('form.email') + '*',"label-position":"inside","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('EmailSuggestionField',{model:{value:(_vm.getCustomerField('email').value),callback:function ($$v) {_vm.$set(_vm.getCustomerField('email'), "value", $$v)},expression:"getCustomerField('email').value"}})],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{ref:"customerPhoneValidator",staticClass:"tw-col-span-6 md:tw-col-span-3",attrs:{"name":"phone","tag":"div","rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{staticClass:"PhoneInputField",attrs:{"label":_vm.$t('form.phone') + '*',"label-position":"inside","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('div',{class:['control', {'has-icons-right': errors[0] || valid }]},[_c('vue-tel-input',{attrs:{"value":_vm.getCustomerField('formattedPhone').value,"preferred-countries":_vm.preferredCountries,"auto-default-country":!!_vm.customerCountry,"default-country":_vm.customerCountry || '',"input-options":{
                  placeholder: '',
                  type: 'tel',
                  autocomplete: 'tel-national',
                  styleClasses: ("input " + (errors[0] ? 'is-danger' : '') + " " + (valid ? 'is-success': ''))
                },"dropdown-options":{ showFlags: true, showDialCodeInSelection: true }},on:{"input":_vm.onPhoneUpdate,"country-changed":_vm.onPhoneCountryChange}}),_vm._v(" "),(errors[0])?_c('span',{staticClass:"icon is-right has-text-danger"},[_c('i',{staticClass:"mdi mdi-alert-circle mdi-24px"})]):(valid)?_c('span',{staticClass:"icon is-right has-text-success"},[_c('i',{staticClass:"mdi mdi-check mdi-24px"})]):_vm._e()],1)])]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"tw-col-span-6 md:tw-col-span-4",attrs:{"name":"address","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.countrySelectorOpen && _vm.isMobile ? ' ' : _vm.$t('form.address') + '*',"label-position":"inside","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('BInput',{model:{value:(_vm.getCustomerField('billingAddress.addressLine1').value),callback:function ($$v) {_vm.$set(_vm.getCustomerField('billingAddress.addressLine1'), "value", $$v)},expression:"getCustomerField('billingAddress.addressLine1').value"}})],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"tw-col-span-6 md:tw-col-span-2",attrs:{"name":"postcode","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.countrySelectorOpen ? ' ' : _vm.$t('form.postcode') + '*',"label-position":"inside","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('BInput',{attrs:{"type":"tel"},model:{value:(_vm.getCustomerField('billingAddress.postalCode').value),callback:function ($$v) {_vm.$set(_vm.getCustomerField('billingAddress.postalCode'), "value", $$v)},expression:"getCustomerField('billingAddress.postalCode').value"}})],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"tw-col-span-6 md:tw-col-span-2",attrs:{"name":"city","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.countrySelectorOpen && _vm.isMobile ? ' ' : _vm.$t('form.city') + '*',"label-position":"inside","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('BInput',{model:{value:(_vm.getCustomerField('billingAddress.city').value),callback:function ($$v) {_vm.$set(_vm.getCustomerField('billingAddress.city'), "value", $$v)},expression:"getCustomerField('billingAddress.city').value"}})],1)]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"tw-col-span-6 md:tw-col-span-2",attrs:{"name":"country","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('BaseField',{attrs:{"label":_vm.countrySelectorOpen && _vm.isMobile ? ' ' : _vm.$t('form.country'),"label-position":"inside","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('BSelect',{attrs:{"icon":_vm.countrySelectorOpen && _vm.isMobile ? '' : 'earth'},model:{value:(_vm.getCustomerField('billingAddress.country').value),callback:function ($$v) {_vm.$set(_vm.getCustomerField('billingAddress.country'), "value", $$v)},expression:"getCustomerField('billingAddress.country').value"}},[_vm._l((_vm.countries.first),function(country){return _c('option',{key:country.code,domProps:{"value":country.code}},[_vm._v("\n                "+_vm._s(_vm.$translateEntityField(country.name))+"\n              ")])}),_vm._v(" "),_c('option',{domProps:{"value":null}},[_vm._v("\n                -\n              ")]),_vm._v(" "),_vm._l((_vm.countries.other),function(country){return _c('option',{key:country.code,domProps:{"value":country.code}},[_vm._v("\n                "+_vm._s(_vm.$translateEntityField(country.name))+"\n              ")])})],2)],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"tw-w-full tw-col-span-6"},[_c('BaseSwitch',{attrs:{"reverse":"","size":"is-small","label":_vm.$t('form.fields.third_party_booking')},model:{value:(_vm.getCartField('isThirdPartyBooking').value),callback:function ($$v) {_vm.$set(_vm.getCartField('isThirdPartyBooking'), "value", $$v)},expression:"getCartField('isThirdPartyBooking').value"}})],1),_vm._v(" "),(_vm.$accessor.cart.isThirdPartyBooking)?_c('ThirdPartyBookingForm',{staticClass:"tw-col-span-6"}):_vm._e()],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }