

















import { defineComponent, computed, useContext } from '@nuxtjs/composition-api'
import { uniqBy } from 'lodash'
import { CartError } from '~/types/Errors'


export default defineComponent({
  name: 'DiscountsErrors',
  setup() {
    const { app: { $accessor }} = useContext()

    /**
   * Removes duplicate errors (can sometimes happen because updateCart and previewCart can return the same errors)
   * An filter out the errors that are not related to giftcards or promocode, those are displayed by another component
   */
    const filteredErrors = computed(() => {
      return uniqBy([...$accessor.cartErrors, ...$accessor.cartPreviewErrors], (error) => error.code).filter(removeNonDiscountErrors)
    })

    /**
     * Removes errors that are not related to giftcards or promocode, those are displayed by another component
     */
    function removeNonDiscountErrors(error: CartError) {
      return [-1, -2].includes(error.code)
    }

    return {
      filteredErrors,
    }
  },
})
