import { computed, ComputedRef, useContext, WritableComputedRef } from '@nuxtjs/composition-api'
import { CartOption } from '~/types/Models/Cart'
import { Option, OptionCategoriesEnum, OptionCategory } from '~/types/Models/Option'
import { range } from '~/helpers'
import { Amount } from '~/types/Models'

export interface OptionsHook {
  getOptionAvailableQuantities(option: Option): number[]
  getOptionById(id: number): ComputedRef<Option | null>
  getOptionSelectedQuantity(option: Option): WritableComputedRef<number>
  getTotalOptionAmount(option: Option, quantity: number): Amount
  optionsByCategory: ComputedRef<Record<OptionCategory, Option[]>>
  selectedOptions: WritableComputedRef<CartOption[]>
}

function useOptions(): OptionsHook {
  const { app } = useContext()
  const { $accessor } = app

  const optionsByCategory: ComputedRef<Record<OptionCategory, Option[]>> = computed(() => {
    return [
      OptionCategoriesEnum.CATEGORY_GENERAL,
      OptionCategoriesEnum.CATEGORY_CATERING,
      OptionCategoriesEnum.CATEGORY_ACTIVITY,
    ].reduce((acc, category) => {
      const categoryOptions = $accessor.getAvailableOptions.filter(option =>
        option.category === category || (
          category === OptionCategoriesEnum.CATEGORY_GENERAL &&
          option.category === OptionCategoriesEnum.CATEGORY_UNKNOWN
        ),
      )
      if (categoryOptions.length) {
        return {
          ...acc,
          [category]: categoryOptions,
        }
      }
      return acc
    }, {} as Record<OptionCategory, Option[]>)
  })

  const selectedOptions: WritableComputedRef<CartOption[]> = computed({
    get: () => $accessor.cart ? $accessor.cart.options : [],
    set: value => {
      if ($accessor.cart) {
        $accessor.SET_CART_OPTIONS(value)
      }
    },
  })

  function getOptionAvailableQuantities(option: Option) {
    if (option.max === 0) {
      return [...range(option.min, 100)]
    }
    return [...range(option.min, option.max + 1)]
  }

  const getOptionSelectedQuantity: (option: Option) => WritableComputedRef<number> = (option: Option) => computed({
    get: () => {
      const selectedOption = $accessor.cart?.options.find(o => o.option === option.id)
      if (selectedOption) {
        return selectedOption.quantity
      }
      return 0
    },
    set: async(quantity) => {
      // If quantity = 0, remove from cart.options
      if (quantity === 0) {
        await $accessor.setCartOptions($accessor.cart!.options.filter(o => o.option !== option.id))
      }
      // If quantity > 0 and option already in cart.options, set new quantity.
      else if ($accessor.cart!.options.find(o => o.option === option.id)) {
        await $accessor.setCartOptions(
          $accessor.cart!.options.map(o => o.option === option.id ? ({ ...o, quantity }) : ({ ...o })),
        )
      }
      // If quantity > 0 and option not in cart.options, add it with quantity.
      else {
        await $accessor.setCartOptions([
          ...$accessor.cart!.options,
          {
            option: option.id,
            quantity,
          },
        ])
      }
    },
  })

  const getOptionById = (id: number) => computed(() => $accessor.options.find(o => o.id === id) || null)

  function getTotalOptionAmount(option: Option, quantity: number): Amount {
    return {
      amount: option.fee.amount * quantity,
      currency: option.fee.currency,
    }
  }

  return {
    getOptionAvailableQuantities,
    getOptionById,
    getOptionSelectedQuantity,
    getTotalOptionAmount,
    optionsByCategory,
    selectedOptions,
  }
}

export default useOptions
