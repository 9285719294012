import { DateType } from '~/types/DateType'
import { CartPreview } from '~/types/Models/CartPreview'
import { Amount } from '~/types/Models/Amount'

export enum BookingStatusEnum {
  BOOKING_PENDING = 'BOOKING_PENDING',
  BOOKING_CANCELLED_CUSTOMER = 'BOOKING_CANCELLED_CUSTOMER',
  BOOKING_CANCELLED_PROVIDER = 'BOOKING_CANCELLED_PROVIDER',
  BOOKING_CONFIRMED = 'BOOKING_CONFIRMED',
  BOOKING_STANDBY = 'BOOKING_STANDBY',
  BOOKING_CLOSED = 'BOOKING_CLOSED'
}

export enum BookingStatusClassesEnum {
  BOOKING_CANCELLED_CUSTOMER = 'is-danger',
  BOOKING_CANCELLED_PROVIDER = 'is-danger',
  BOOKING_CONFIRMED = 'is-success',
  BOOKING_PENDING = 'is-warning',
  BOOKING_STANDBY = 'is-warning',
  BOOKING_CLOSED = 'is-text',
  CREATED = 'is-success'
}

export enum BookingIconClassesEnum {
  CREATED = 'usi-contract',
  BOOKING_PENDING = 'usi-contract',
  BOOKING_CANCELLED_PROVIDER = 'usi-cross',
  BOOKING_CANCELLED_CUSTOMER = 'usi-cross',
  UPDATED = 'usi-pencil',
  BOOKING_CONFIRMED = 'usi-validation',
  BOOKING_STANDBY = 'usi-treatment',
  PAYMENT_RECEIVED = 'usi-euro-full',
  BOOKING_CLOSED = ''
}

export enum BookingEventsEnum {
  BOOKING_PENDING = 'BOOKING_PENDING',
  BOOKING_CANCELLED_CUSTOMER = 'BOOKING_CANCELLED_CUSTOMER',
  BOOKING_CANCELLED_PROVIDER = 'BOOKING_CANCELLED_PROVIDER',
  BOOKING_CONFIRMED = 'BOOKING_CONFIRMED',
  BOOKING_STANDBY = 'BOOKING_STANDBY',
  BOOKING_CLOSED = 'BOOKING_CLOSED',
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  PAYMENT_RECEIVED = 'PAYMENT_RECEIVED'
}

export type BookingEvents = BookingStatus | BookingEventsEnum.CREATED | BookingEventsEnum.UPDATED | BookingEventsEnum.PAYMENT_RECEIVED

export type BookingStatus = BookingStatusEnum.BOOKING_PENDING
| BookingStatusEnum.BOOKING_CANCELLED_CUSTOMER
| BookingStatusEnum.BOOKING_CANCELLED_PROVIDER
| BookingStatusEnum.BOOKING_CONFIRMED
| BookingStatusEnum.BOOKING_STANDBY
| BookingStatusEnum.BOOKING_CLOSED
| BookingEventsEnum.CREATED
| BookingEventsEnum.UPDATED
| BookingEventsEnum.PAYMENT_RECEIVED

export enum BookingActionEnum {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export interface BookingEvent {
  action: BookingActionEnum
  data: Booking
  id: number
  loggedAt: DateType
}

export interface Booking {
  accompanyingGuests: number[]
  adultsNumber: number
  bookingCharge: CartPreview & {
    bookingDiscounts: Amount
    serviceDiscoutAmount: Amount
  }
  childrenNumber: number
  createdAt: DateType
  customer: number
  discounts: number[]
  end: DateType
  giftCardSpendings: number[]
  hostMessage: string | null
  id: number
  infantsNumber: number
  marketplace: number
  options: number[]
  payments: number[]
  promocodes: number[]
  service: number
  serviceUnit: number
  start: DateType
  status: BookingStatus
  totalPersons: number
  updatedAt: DateType
}
