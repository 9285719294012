import { createGetters } from '@kissmylabs/vuex-entitystore'
import { getterTree } from 'typed-vuex'
import orderBy from 'lodash/orderBy'
import { EntityFile, FileTypeEnum } from '~/types/Models/EntityFile'
import { entityFile, state } from './state'
import { EntityFilesState } from './types'

export const getters = getterTree(state, {
  ...createGetters<EntityFilesState, EntityFile>(entityFile),
  getDomainGtcsUrl(_state, { getWhereArray }): string | null {
    const files: EntityFile[] = orderBy(
      getWhereArray((f: EntityFile) => f.type === FileTypeEnum.DOMAIN_LEGAL_TERMS),
      [(i: EntityFile) => new Date(i.createdAt)],
      ['desc'],
    )
    if (files.length) {
      return files[0].url
    }
    return null
  },
  getMarketplaceGtuUrl(_state, { getWhereArray }): string | null {
    const files: EntityFile[] = orderBy(
      getWhereArray((f: EntityFile) => f.type === FileTypeEnum.MARKETPLACE_LEGAL_TERMS),
      [(i: EntityFile) => new Date(i.createdAt)],
      ['desc'],
    )
    if (files.length) {
      return files[0].url
    }
    return null
  },
  getMarketplaceLogoFile(_state, { getFirst }): EntityFile | null {
    return getFirst((f: EntityFile) => f.type === FileTypeEnum.MARKETPLACE_LOGO)
  },
  getServicePictureFile(_state, { getFirst }): EntityFile | null {
    return getFirst((f: EntityFile) => f.type === FileTypeEnum.SERVICE_PICTURE && f.idx === 0)
  },
})

export default getters
