import { computed, ComputedRef, useContext, WritableComputedRef } from '@nuxtjs/composition-api'
import { Customer } from '~/types/Models/Customer'
import { Path, PathValue } from '~/types/types'
import { isAdult } from '~/helpers'

export interface CustomerHook {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: We actually want it to be nested.
  getCustomerField<F extends Path<Customer>>(field: F): WritableComputedRef<PathValue<Customer, F>>

  isCustomerDateOfBirthValid: ComputedRef<boolean>

  isCustomerValid: ComputedRef<boolean>

  displayName: ComputedRef<string>
}

function useCustomer(): CustomerHook {
  const { app: { $accessor } } = useContext()

  const getCustomerField = <P extends Path<Customer>>(field: P): WritableComputedRef<PathValue<Customer, P>> => computed({
    get: () => $accessor.getField(`customer.${field}`),
    set: val => {
      if (String(field)[0] === '$') {
        return
      }
      if (!$accessor.getField('$isDirty')) {
        $accessor.UPDATE_FIELD({
          path: 'customer.$isDirty',
          value: true,
        })
      }
      return $accessor.UPDATE_FIELD({
        path: `customer.${field}`,
        value: val,
      })
    },
  })

  const isCustomerDateOfBirthValid = computed(
    () => $accessor.customer?.dateOfBirth?.length > 0 && isAdult($accessor.customer.dateOfBirth),
  )

  const isCustomerValid = computed(() => {
    return ['firstName', 'lastName', 'email', 'phone', 'gender'].every(field => $accessor.customer[field] !== '') &&
      ['addressLine1', 'city', 'postalCode', 'country'].every(field => $accessor.customer.billingAddress[field] !== '')
  })

  const displayName = computed(() => {
    if ($accessor.customer.firstName && $accessor.customer.lastName) {
      return `${$accessor.customer.firstName} ${$accessor.customer.lastName}`
    }
    if ($accessor.users.user) {
      return $accessor.users.user.email
    }
    return ''
  })

  return <CustomerHook>{
    getCustomerField,
    isCustomerDateOfBirthValid,
    isCustomerValid,
    displayName,
  }
}

export default useCustomer
