


















import { computed, defineComponent, ref, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  setup() {
    const { app: { $accessor }} = useContext()

    const isSendingMessage = ref(false)

    const message = computed({
      get: () => $accessor.cart?.hostMessage,
      set: (newValue) => {
        if(newValue) {
          $accessor.SET_HOST_MESSAGE(newValue)
        }
      },
    })
    
    return {
      message,
      isSendingMessage,
    }
  },
})
