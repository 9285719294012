import { Translation } from '~/types/Locale'
import { DateType } from '../DateType'
import { Amount } from '~/types/Models/Amount'

export type OptionCategory = 'GENERAL' | 'ACTIVITY' | 'CATERING' | 'UNKNOWN'

export enum OptionCategoriesEnum {
  CATEGORY_GENERAL = 'GENERAL',
  CATEGORY_ACTIVITY = 'ACTIVITY',
  CATEGORY_CATERING = 'CATERING',
  CATEGORY_UNKNOWN = 'UNKNOWN',
}

export const optionsCategoriesArray = [
  OptionCategoriesEnum.CATEGORY_GENERAL,
  OptionCategoriesEnum.CATEGORY_ACTIVITY,
  OptionCategoriesEnum.CATEGORY_CATERING,
  OptionCategoriesEnum.CATEGORY_UNKNOWN,
]

export enum OptionFeeTypeEnum {
  OPTION_FEE_TYPE_NIGHT = 'OPTION_FEE_TYPE_NIGHT',
  OPTION_FEE_TYPE_GUEST = 'OPTION_FEE_TYPE_GUEST',
  OPTION_FEE_TYPE_NIGHT_GUEST = 'OPTION_FEE_TYPE_NIGHT_GUEST',
  OPTION_FEE_TYPE_FLAT = 'OPTION_FEE_TYPE_FLAT'
}

export interface Option {
  [key: string]: any
  id: number
  domain: number
  label: string
  name: Translation
  description: Translation
  min: number
  max: number
  periods: Array<DateType>
  isActive: boolean
  isRecurringPeriod: boolean
  isPersonType: boolean
  feeType: OptionFeeTypeEnum
  fee: Amount
  appliesOnServiceIds: number[]
  minPreBookingDays?: number,
  category: OptionCategory
}
