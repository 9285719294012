




















import { defineComponent } from '@nuxtjs/composition-api'
import useSteps from '~/composable/useSteps'

export default defineComponent({
  name: 'CheckoutSteps',
  setup() {
    const { stepModel } = useSteps()

    return {
      stepModel,
    }
  },
})
