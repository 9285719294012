





























import { defineComponent, onMounted, useContext, useMeta, watch } from '@nuxtjs/composition-api'
import StepSectionTitle from '~/components/content/StepSectionTitle.vue'
import OptionSelect from '~/components/option/OptionSelect.vue'
import OptionCategory from '~/components/option/OptionCategory.vue'
import ReassuranceCard from '~/components/content/ReassuranceCard.vue'
import GiftIcon from '~/components/icons/GiftIcon.vue'
import useSteps from '~/composable/useSteps'
import useOptions from '~/composable/useOptions'

export default defineComponent({
  name: 'StepOptions',
  components: {
    OptionSelect,
    OptionCategory,
    StepSectionTitle,
    ReassuranceCard,
    GiftIcon,
  },
  setup() {
    const { store, app: { $accessor, $translateEntityField, i18n } } = useContext()

    const { optionsByCategory } = useOptions()
    const { redirectOptionsStep } = useSteps()

    onMounted(async() => {
      await redirectOptionsStep()
      if ($accessor.ui.blockNextStep) {
        $accessor.SET_BLOCK_NEXT_STEP(false)
      }
    })

    watch(() => store.state.service, async(val, prevVal) => {
      if (!prevVal && val) {
        await redirectOptionsStep()
        if ($accessor.ui.blockNextStep) {
          $accessor.SET_BLOCK_NEXT_STEP(false)
        }
      }
    })

    useMeta({
      title: `${$accessor.marketplace ? $translateEntityField($accessor.marketplace.name) : 'Abracadaroom'} | ${i18n.t('steps.options')}`,
    })

    return {
      optionsByCategory,
    }
  },
  head() {
    return {}
  },
})
