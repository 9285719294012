import { Translation } from '~/types/Locale'

export type FileType =
  'MARKETPLACE_LOGO' | 'MARKETPLACE_LEGAL_TERMS' |
  'SERVICE_PICTURE' | 'SERVICE_LEGAL_TERMS' |
  'DOMAIN_PICTURE' | 'DOMAIN_OWNERS_PICTURE' | 'DOMAIN_LOGO' | 'DOMAIN_LEGAL_TERMS' |
  'PROVIDER_LEGAL_TERMS'

export type FileableEntity = 'FILEABLE_PROVIDER' | 'FILEABLE_SERVICE' | 'FILEABLE_DOMAIN' | 'FILEABLE_MARKETPLACE'

export enum FileTypeEnum {
  MARKETPLACE_LOGO = 'MARKETPLACE_LOGO',
  MARKETPLACE_LEGAL_TERMS = 'MARKETPLACE_LEGAL_TERMS',

  SERVICE_PICTURE = 'SERVICE_PICTURE',
  SERVICE_LEGAL_TERMS = 'SERVICE_LEGAL_TERMS',

  DOMAIN_PICTURE = 'DOMAIN_PICTURE',
  DOMAIN_OWNERS_PICTURE = 'DOMAIN_OWNERS_PICTURE',
  DOMAIN_LOGO = 'DOMAIN_LOGO',
  DOMAIN_LEGAL_TERMS = 'DOMAIN_LEGAL_TERMS',

  PROVIDER_LEGAL_TERMS = 'PROVIDER_LEGAL_TERMS',
}

export interface EntityFile {
  alt: Translation
  description: Translation
  domain: number | null
  filename: string
  id: number
  idx: number | null
  marketplace: number | null
  metadata: Record<string, any>
  name: Translation
  provider: number | null
  service: number | null
  type: FileType
  url: string
  createdAt: string
}
