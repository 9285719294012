import Vue from 'vue'
import Router, { Route } from 'vue-router'
import i18nConfig from './config/i18n.js'
import LegacyRouteHandler from '~/components/pages/legacy-route-handler.vue'
import Steps from '~/components/pages/steps.vue'
import StepBooking from '~/components/pages/steps/step-booking.vue'
import StepOptions from '~/components/pages/steps/step-options.vue'
import StepInfo from '~/components/pages/steps/step-info.vue'
import StepPayment from '~/components/pages/steps/step-payment.vue'
import Finish from '~/components/pages/finish.vue'
import EmptyCartPage from '~/components/pages/empty-cart.vue'
import { RouteConfig } from '@nuxt/types/config/router'

Vue.use(Router)

export const mainMenuRoutes: RouteConfig[] = [
  {
    path: '/step',
    component: Steps,
    name: 'checkout:steps',
    children: [
      {
        path: '/step/booking',
        component: StepBooking,
        name: 'checkout:step-booking',
      },
      {
        path: '/step/options',
        component: StepOptions,
        name: 'checkout:step-options',
      },
      {
        path: '/step/info',
        component: StepInfo,
        name: 'checkout:step-info',
      },
      {
        path: '/step/payment',
        component: StepPayment,
        name: 'checkout:step-payment',
      },
    ],
  },
  {
    path: '/finish/:cartId',
    component: Finish,
    name: 'checkout:finish',
  },
  {
    path: '/mkt/:marketplaceSlug/:serviceSlug/:step',
    component: LegacyRouteHandler,
    name: 'legacy-route',
  },
  {
    path: '/no-cart',
    component: EmptyCartPage,
    name: 'no-cart',
  },
  {
    path: '*',
    redirect: (to: Route): string => {
      let lang = to.name!.split('___')[1]
      const enabledLocales = i18nConfig.locales.map(l => l.code)
      lang = enabledLocales.includes(lang) ? lang : 'fr'
      return `/${lang}/no-cart`
    },
  },
]

export const stepRoutes = mainMenuRoutes.find(r => r.name === 'checkout:steps')?.children

export const baseRoutes = [
  ...mainMenuRoutes,
]

/**
 * Check if the argument localeCode is configured as the App default locale.
 *
 */
const isDefaultLocale = (localeCode: string) => localeCode === i18nConfig.defaultLocale

/**
 * Generate a localized route for a generic, non-localized route.
 * Handles route children recursively.
 */
const createLocalizedRoute = <T extends { name?: string | undefined, path: string, children?: T[] }>(route: T, locale: { code: string }) => {
  let routeName = `${route.name}___${locale.code}`

  if (isDefaultLocale(locale.code)) {
    routeName += '___default'
  }
  const localizedRoute = {
    ...route,
    path: `/${locale.code}${route.path}`,
    name: routeName,
  }
  if (route.children && route.children.length) {
    localizedRoute.children = route.children.map(childRoute => createLocalizedRoute(childRoute, locale))
  }
  return localizedRoute
}

/**
 * Generate all localized routes.
 */
const createLocalizedRoutes = (routes: any[], locales: { code: string }[]) => {
  return routes.reduce((i18nRoutes: RouteConfig[], route) => {
    locales.forEach((locale) => {
      i18nRoutes.push(createLocalizedRoute(route, locale))
    })
    return i18nRoutes
  }, [])
}

export function createRouter(): Router {
  return new Router({
    mode: 'history',
    routes: createLocalizedRoutes(baseRoutes, i18nConfig.locales),
    scrollBehavior() {
      return {
        x: 0,
        y: 0,
      }
    },
  })
}
