





























































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import ResponsiveImage from '~/components/media/ResponsiveImage.vue'
import useMarketplace from '~/composable/useMarketplace'
import { getRouteName } from '~/helpers/route'

export default defineComponent({
  components: {
    ResponsiveImage,
  },
  setup() {
    const { app: { i18n, $accessor, router } } = useContext()
    const { isUSCOM } = useMarketplace()

    const isThankYouPage = computed(() => getRouteName(router.currentRoute) === 'checkout:finish')

    function getPillText() {
      if (isThankYouPage.value) {
        return i18n.t('booking.rental_booked')
      }
      if (!$accessor.cart?.id) {
        return i18n.t('booking.rental_unavailable')
      }
      return i18n.t('booking.rental_available')
    }

    return {
      getPillText,
      isUSCOM,
    }
  },
})
