

































































import { defineComponent } from '@nuxtjs/composition-api'
import useMarketplace from '~/composable/useMarketplace'

export default defineComponent({
  name: 'AncvModal',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { marketplaceLinks } = useMarketplace()

    return {
      marketplaceLinks,
    }
  },
})
