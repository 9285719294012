





























import { defineComponent } from '@vue/composition-api'
import { Gender, GenderEnum } from '~/types/Models/Customer'
import { computed, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'CustomerGenderSelector',
  props: {
    value: {
      type: String as PropType<Gender>,
      required: true,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const model = computed({
      get: () => props.value,
      set: value => emit('input', value),
    })

    return {
      GenderEnum,
      model,
    }
  },
})
