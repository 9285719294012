import { reactive } from '@nuxtjs/composition-api'

export interface LoadingScreenHook {
    state: { isVisible: boolean }
}

function useLoadingScreen(): LoadingScreenHook {
  const state = reactive({
    isVisible: true,
  })

  return {
    state,
  }
}

export default useLoadingScreen
