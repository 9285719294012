import { inject, provide, ref, Ref, useContext } from '@nuxtjs/composition-api'

export interface ActionSubscriber {
  action: string; // TODO type action names so we can check here.
  before?: boolean;
  after?: boolean;
  handler: (state: any) => void;
  prepend?: boolean;
}

export interface MutationSubscriber {
  mutation: string; // TODO type mutation names so we can check here.
  handler: (state: any) => void;
  prepend?: boolean;
}

export interface Subscribers {
  actions: {
    before: Map<number, ActionSubscriber>,
    after: Map<number, ActionSubscriber>,
  };
  mutations: Map<number, MutationSubscriber>;
}

export interface UseSubscribe {
  subscribeActions: (actions: ActionSubscriber[]) => void;
  subscribeMutations: (mutations: MutationSubscriber[]) => void;
  subscribers: Subscribers;
}

const SubscribersSymbol = Symbol('subscribers')

export function provideSubscribers(): void {
  const baseSubscribers: Subscribers = {
    actions: {
      before: new Map(),
      after: new Map(),
    },
    mutations: new Map(),
  }

  provide(SubscribersSymbol, baseSubscribers)
}

function useSubscribe(): UseSubscribe {
  const { store } = useContext()
  const subscribers: Subscribers | null | undefined = inject(SubscribersSymbol)

  if (!subscribers) {
    console.error('Do not use useSubscribe before using provideSubscribers()')
  }

  const alreadySubscribed: Ref<string[]> = ref([])

  function subscribeActions(actions: ActionSubscriber[]) {
    if (subscribers && Array.isArray(subscribers.actions.before) && Array.isArray(subscribers.actions.after)) {

      const beforeActions = Array.from(actions.values())
        .filter(action => action.before || !action.after)
      const afterActions = Array.from(actions.values())
        .filter(action => !action.before && action.after)

      const beforeArray = [
        ...beforeActions.filter(a => a.prepend),
        ...beforeActions,
        ...beforeActions.filter(a => !a.prepend),
      ] /*as ActionSubscriber[]*/

      subscribers.actions.before = new Map(beforeArray.map((v, k) => [k, v]))

      const afterArray = [
        ...afterActions.filter(a => a.prepend),
        ...afterActions,
        ...afterActions.filter(a => !a.prepend),
      ] /*as ActionSubscriber[]*/

      subscribers.actions.after = new Map(afterArray.map((v, k) => [k, v]))

      store.subscribeAction({
        before: (action, state) => {
          Object.keys(subscribers.actions.before)
            .forEach((key) => {
              const _action = subscribers.actions.before.get(Number(key)) as ActionSubscriber
              if (_action.action === action.type && !alreadySubscribed.value.includes(`action-${action.type}-before`)) {
                _action.handler(state)
                alreadySubscribed.value.push(`action-${action.type}-before`)
              }
            })
        },
        after: (action, state) => {
          Object.keys(subscribers.actions.after)
            .forEach(key => {
              const _action = subscribers.actions.after.get(Number(key)) as ActionSubscriber
              if (_action.action === action.type && !alreadySubscribed.value.includes(`action-${action.type}-after`)) {
                _action.handler(state)
              }
              alreadySubscribed.value.push(`action-${key}-after`)
            })
        },
      })
    }
  }

  function subscribeMutations(mutations: MutationSubscriber[]) {
    if (subscribers && subscribers.mutations) {

      const newMutations = [
        ...mutations.filter(m => m.prepend),
        ...Array.from(subscribers.mutations.values()),
        ...mutations.filter(m => !m.prepend),
      ]

      subscribers.mutations = new Map(newMutations.map((v, k) => [k, v]))

      store.subscribe((mutation, state) => {
        Array.from(subscribers.mutations.values())
          .forEach(_mutation => {
            if (_mutation.mutation === mutation.type && !alreadySubscribed.value.includes(`mutation-${mutation.type}`)) { // TODO this will prevent stacking subscribers.
              _mutation.handler(state)
              alreadySubscribed.value.push(`mutation-${mutation.type}`)
            }
          })
      })
    }
  }

  return {
    subscribers: subscribers!,
    subscribeActions,
    subscribeMutations,
  }
}

export default useSubscribe
