

































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import UserIcon from '~/components/icons/UserIcon.vue'
import DocumentTextIcon from '~/components/icons/DocumentTextIcon.vue'
import LockOpenIcon from '~/components/icons/LockOpenIcon.vue'
import useMarketplace from '~/composable/useMarketplace'
import { ShortToISOEnum } from '~/types/Locale'

export default defineComponent({
  components: {
    LockOpenIcon,
    DocumentTextIcon,
    UserIcon,
  },
  setup() {
    const { isFairmoove, marketplaceLinks, isTreego } = useMarketplace()
    const { $config, app: { $accessor, $sentry, i18n } } = useContext()

    async function openConditionsPdf() {
      try {
        const cartId = $accessor.cart?.sessionId
        const url = `${$config.axios.baseURL}/carts/${cartId}/policies/download`

        const a = document.createElement('a')
        a.target = '_blank'
        a.href = url
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      } catch (e) {
        $sentry.captureException('Failed to open conditions PDF')
      }
    }

    const cancellationUrl = computed(() => {
      const locale = i18n.locale
      if (!isTreego.value) {
        return marketplaceLinks.value.cancellationInsuranceUrl[ShortToISOEnum[locale.toUpperCase() as keyof typeof ShortToISOEnum]]
      }
      // For Treego, always redirect to NL version
      return marketplaceLinks.value.cancellationInsuranceUrl[ShortToISOEnum.NL]
    })

    return {
      isFairmoove,
      openConditionsPdf,
      cancellationUrl,
    }
  },
})
