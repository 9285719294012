




















import { TranslateResult } from 'vue-i18n'
import { computed, ComputedRef, defineComponent, useContext } from '@nuxtjs/composition-api'
import useMq from '~/composable/useMq'
import useSteps from '~/composable/useSteps'
import RefreshIcon from '~/components/icons/RefreshIcon.vue'


export default defineComponent({
  components: {
    RefreshIcon,
  },
  setup() {
    const { app } = useContext()
    const { nextStepRoute, isNextStepDisabled, isNextStepLoading, goToNextStep } = useSteps()
    const { isWide } = useMq()

    const buttonText: ComputedRef<string | TranslateResult> = computed(() => {
      if (isNextStepLoading.value && isWide.value) {
        return app.i18n.t('booking.updating_button_text')
      }
      return nextStepRoute.value ? app.i18n.t('actions.continue') : app.i18n.t('actions.pay')
    })

    return {
      isNextStepDisabled,
      isNextStepLoading,
      goToNextStep,
      buttonText,
    }
  },
})
