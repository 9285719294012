



































import { defineComponent, useContext, useMeta } from '@nuxtjs/composition-api'
import CheckoutErrors from '~/components/checkout/CheckoutErrors.vue'

export default defineComponent({
  name: 'EmptyCartPage',
  components: { CheckoutErrors },
  setup() {
    const {
      app,
      app: { $accessor, $translateEntityField },
    } = useContext()

    useMeta({
      title: $accessor.marketplace
        ? $translateEntityField($accessor.marketplace.name) as string
        : app.i18n.t('steps.empty') as string,
    })
    return {}
  },
  head() {
    return {}
  },
})
