import { ShortToISOEnum, Translation, SupportedISOLocale } from '~/types/Locale'

export const translateEntityField = (field: Translation, locale: string): string => {
  const translatedValue = field[ShortToISOEnum[locale.toUpperCase() as keyof typeof ShortToISOEnum]]
  // Workaround tiptap saving an empty paragraph when content is empty.
  if (translatedValue === '<p></p>') {
    return ''
  }
  return translatedValue || ''
}

export enum LanguagesEnum {
  FR = 'fr',
  EN = 'en',
  ES = 'es',
  NL = 'nl',
  DE = 'de',
  IT = 'it',
  CH = 'ch',
  GB = 'gb',
  LU = 'lu',
  CA = 'ca',
  MC = 'mc',
  BE = 'be'
}

export enum PhonePreferredCountriesEnum {
  FR = 'FR',
  BE = 'BE',
  CH = 'CH',
  NL = 'NL',
  ES = 'ES',
  GB = 'GB',
  LU = 'LU',
  DE = 'DE',
  IT = 'IT',
  CA = 'CA',
  MC = 'MC',
}

export type SupportedLocale = 'fr' | 'en' | 'es' | 'it' | 'nl' | 'de'

export const languages = [
  LanguagesEnum.FR,
  LanguagesEnum.EN,
  LanguagesEnum.ES,
  LanguagesEnum.NL,
  LanguagesEnum.DE,
  LanguagesEnum.IT,
]

export const publicationLanguages = [
  LanguagesEnum.FR,
  LanguagesEnum.EN,
  LanguagesEnum.ES,
  LanguagesEnum.NL,
]

export const shortLocaleToCountry = {
  [LanguagesEnum.FR]: 'fr',
  [LanguagesEnum.EN]: 'gb',
  [LanguagesEnum.IT]: 'it',
  [LanguagesEnum.NL]: 'nl',
}

export function shortLocaleToRegionLocale(locale: SupportedLocale | string, fallbackToEnglish = true): SupportedISOLocale | null {
  if (
    Object.keys(ShortToISOEnum)
      .includes(locale.toUpperCase())
  ) {
    // Dirty cast here to be able to index enum by string
    return ShortToISOEnum[locale.toUpperCase() as keyof typeof ShortToISOEnum]
  }
  return fallbackToEnglish ? ShortToISOEnum.EN : null
}

export function mapLocaleToCountry(locale: SupportedLocale): string {
  return locale.toUpperCase()
}
