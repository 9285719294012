


















import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import PreviewSHGuests from '~/components/preview/PreviewSHGuests.vue'
import UsersIcon from '~/components/icons/UsersIcon.vue'

export default defineComponent({
  components: {
    UsersIcon,
    PreviewSHGuests,
  },
  setup() {
    const { app: { $accessor }} = useContext()

    const getOptionCharges = computed(() =>{
      const optionChargesIds = $accessor.cartPreview?.optionCharges.map(opt => opt.optionId)
      return $accessor.options.filter(opt => optionChargesIds?.includes(opt.id))
    })
    
    return {
      getOptionCharges,
    }
  },
})
