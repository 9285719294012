import { Context } from '@nuxt/types'
import { pushToDataLayer } from '~/helpers/gtm'
import { mainMenuRoutes } from '~/routes'
import { translateEntityField } from '~/helpers'

export default function({ app, $config, $gtm, $device, route }: Context): void {
  if (app.$featureToggle.toggles.gtm && app.$accessor.marketplace) {
    const gtmIds = [...app.$accessor.marketplace.frontConfig.googleTagId]
    if ($config.gtmMainId) {
      gtmIds.push($config.gtmMainId)
    } else {
      console.log('WARNING: No GTM_ID environment variable found. Only using marketplace ones') // eslint-disable-line
    }

    try {
      const dataLayerSetup: Record<string, any> = {
        env_device: $device.isDesktop ? 'desktop' : 'mobile',
        env_language: app.i18n.locale.toUpperCase(),
        env_work: $config.environment,
        env_site: window.location.hostname,
        product_ID: app.$accessor.service?.id,
        product_name: translateEntityField(app.$accessor.service?.name || {}, app.i18n.locale),
        product_price: app.$accessor.getDiscountedRateWithPromocodeAndGiftCardDiscounts,
      }

      if (!route.name?.startsWith('checkout:finish')) {
        const currentRouteIndex = mainMenuRoutes.find(r => r.name === 'checkout:steps')!.children!.findIndex(c => c.name === route.name?.split('___')[0])
        const currentRoute = mainMenuRoutes.find(r => r.name === 'checkout:steps')!.children![currentRouteIndex]

        if (typeof currentRouteIndex !== 'undefined' && currentRouteIndex !== null) {
          dataLayerSetup.checkout_step = currentRouteIndex + 1
          dataLayerSetup.checkout_name = currentRoute.name
          if (app.$accessor.cartPreview?.giftCardCharges.length) {
            dataLayerSetup.checkout_option = 'gift_card'
          } else if (app.$accessor.cartPreview?.promocodeDiscount.amount) {
            dataLayerSetup.checkout_option = 'promocode'
          }
        }
      }
      pushToDataLayer(dataLayerSetup)
    } catch (e) {
      app.$sentry.captureException(e)
      if ($config.environment !== 'production') {
        console.error(e)
      }
    }

    gtmIds.forEach(gtmId => {
      $gtm.init(gtmId)
    })
  }
}
