import { computed, ComputedRef, useContext } from '@nuxtjs/composition-api'

interface ThemeHook {
  colorPrimary: ComputedRef<string>
  colorDark: ComputedRef<string>
  colorPrimaryDark: ComputedRef<string>
  colorAccent: ComputedRef<string>
  colorContrast: ComputedRef<string>
  hexToRGBStrings(hex: string): string
}

enum ColorVariantsEnum {
  'PRIMARY_DARK' = 'PRIMARY_DARK',
  'ACCENT' = 'ACCENT',
  'CONTRAST' = 'CONTRAST'
}

const EXTRA_COLORS_MAP: Record<number, Record<ColorVariantsEnum, string>> = {
  332: { // USCOM
    'PRIMARY_DARK': '#2B3A58',
    'ACCENT': '#EA9E90',
    'CONTRAST': '#000000',
  },
  241: { // ABCD
    'PRIMARY_DARK': '#005A62',
    'ACCENT': '#7F204E',
    'CONTRAST': '#FFFFFF',
  },
  239: { // LCBL
    'PRIMARY_DARK': '#4B402F',
    'ACCENT': '#EC9E28',
    'CONTRAST': '#000000',
  },
  240: { // TREEGO
    'PRIMARY_DARK': '#4B402F',
    'ACCENT': '#EC9E28',
    'CONTRAST': '#000000',

  },
  298: { // CABANES DE FRANCE
    'PRIMARY_DARK': '#6C5D52',
    'ACCENT': '#EC9E28',
    'CONTRAST': '#000000',
  },
  250: { // TEST MARKETPLACE (SAME COLORS AS ABCD FOR CONVENIENCE)
    'PRIMARY_DARK': '#005A62',
    'ACCENT': '#7F204E',
    'CONTRAST': '#FFFFFF',
  },
  80: { // GUEST & HOUSE
    'PRIMARY_DARK': '#005A62',
    'ACCENT': '#7F204E',
    'CONTRAST': '#FFFFFF',
  },
  331: { // FAIRMOOVE
    'PRIMARY_DARK': '#295442',
    'ACCENT': '#FFC7C3',
    'CONTRAST': '#274437',
  },
}

function useTheme(): ThemeHook {
  const { app } = useContext()

  const colorPrimary = computed(() => {
    if (app.$accessor.marketplace) {
      return app.$accessor.marketplace.frontConfig?.primaryColor
    }
    return '#23acb6'
  })

  const colorDark = computed(() => {
    if (app.$accessor.marketplace) {
      return app.$accessor.marketplace.frontConfig?.secondaryColor
    }
    return '#005a62'
  })

  const colorPrimaryDark = computed(() => {
    if (extraConfig.value) {
      return extraConfig.value[ColorVariantsEnum.PRIMARY_DARK]
    }
    return '#005A62'
  })

  const colorAccent = computed(() => {
    if (extraConfig.value) {
      return extraConfig.value[ColorVariantsEnum.ACCENT]
    }
    return '#7F204E'
  })

  const colorContrast = computed(() => {
    if (extraConfig.value) {
      return extraConfig.value[ColorVariantsEnum.CONTRAST]
    }
    return '#FFFFFF'
  })

  const extraConfig = computed(() => {
    if (app.$accessor.marketplace?.id) {
      return EXTRA_COLORS_MAP[app.$accessor.marketplace.id]
    }
  })

  /**
   * Converts a Hex color value to the `r,g,b` format, used to provide tailwind with basic colors and merge them with transparency values
   * @param hex The hex value to be converted
   * @returns The equivalent RGB value to the `r,g,b` format
   */
  function hexToRGBStrings(hex: string) {
    let r = '0', g = '0', b = '0'

    // 3 digits
    if (hex.length == 4) {
      r = "0x" + hex[1] + hex[1]
      g = "0x" + hex[2] + hex[2]
      b = "0x" + hex[3] + hex[3]

    // 6 digits
    } else if (hex.length == 7) {
      r = "0x" + hex[1] + hex[2]
      g = "0x" + hex[3] + hex[4]
      b = "0x" + hex[5] + hex[6]
    }
    return `${+r},${+g},${+b}`
  }


  return {
    colorPrimary,
    colorDark,
    colorPrimaryDark,
    colorAccent,
    colorContrast,
    hexToRGBStrings,
  }
}

export default useTheme
