import Vue from 'vue'

import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import reduce from 'lodash/reduce'
import reverse from 'lodash/reverse'
import min from 'lodash/min'
import max from 'lodash/max'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'

const lodashPlugin = {
  install(VueN: typeof Vue) {
    /**
     * Export a subset of useful Lodash functions as Vue.$_
     * Usage in Vue component: this.$_.filter(items, item => item.id > 100)
     * @type {{filter: *, reduce: *, min: *, max: *, kebabCase: *, isEmpty: *, orderBy: *, reverse: *, map: *, get: *}}
     * @private
     */
    VueN.prototype.$_ = {
      isEmpty,
      filter,
      kebabCase,
      map,
      orderBy,
      reduce,
      reverse,
      min,
      max,
      get,
      cloneDeep,
    }
  },
}

Vue.use(lodashPlugin)
