import { CartSlugs, RouteSlugMatch } from '~/types/CartSlugs'
import { Route } from 'vue-router'
import { Steps } from '~/types/Steps'
import { MissingLegacyRouteStepError } from '~/types/Errors'
import { StayParams } from '~/types/StayParams'
import dayjs from 'dayjs'

export function extractParamsFromLegacyUrl(route: Route): CartSlugs | null {
  return {
    marketplaceSlug: route.params.marketplaceSlug,
    serviceSlug: route.params.serviceSlug,
  }
}

/**
 * Try to get slugs from legacy URL.
 * @param route
 * @throws Error when no step matches but the route is legacy.
 *
 * @return route name if found,
 */
export function getRouteSlugMatchFromLegacyPath(route: Route): RouteSlugMatch | null {
  if ('step' in route.params) {
    const steps: Steps = {
      1: 'booking',
      2: 'info',
      3: 'info',
      4: 'payment',
    }
    // Handle "/stepN/" routes
    let expectedStepIndex = parseInt(route.params.step.split('step')[1])
    // Handle "/N/" routes
    if (!expectedStepIndex && !isNaN(parseInt(route.params.step))) {
      expectedStepIndex = parseInt(route.params.step)
    }

    const expectedStep = steps[expectedStepIndex]

    if (!expectedStep) {
      // TODO redirect to empty cart
      //  router.push(app.localePath(''))
      throw new MissingLegacyRouteStepError(`No legacy booking matching step${expectedStepIndex} with route path ${route.path}`)
    }

    return {
      routeName: `checkout:step-${expectedStep}`,
      marketplaceSlug: route.params.marketplaceSlug,
      serviceSlug: route.params.serviceSlug,
    }
  }
  return null
}

export function getSlugsFromRoute(route: Route): CartSlugs | null {
  if ('marketplaceSlug' in route.query && 'serviceSlug' in route.query) {
    const marketplaceSlug = route.query.marketplaceSlug as string
    const serviceSlug = route.query.serviceSlug as string
    return {
      marketplaceSlug,
      serviceSlug,
    }
  }
  return null
}

export function getStayParamsFromLegacyQuery(query: Route['query']): StayParams | null {
  let start = dayjs.utc().startOf('day')
  let end = dayjs.utc().startOf('day')
  let adults = 1
  let children = 0
  let infants = 0
  if (
    query &&
    query.initial_1a_start_date &&
    query.initial_1a_end_date
  ) {
    start = dayjs.utc(`${query.initial_1a_start_date}T00:00:00.00Z`).startOf('day')

    end = dayjs.utc(`${query.initial_1a_end_date}T00:00:00.00Z`).startOf('day')

    if (query.initial_2a_adults_number && typeof query.initial_2a_adults_number === 'string') {
      adults = parseInt(query.initial_2a_adults_number)
    }
    if (query.initial_2a_children_number && typeof query.initial_2a_children_number === 'string') {
      children = parseInt(query.initial_2a_children_number)
    }
    if (query.initial_2a_infants_number && typeof query.initial_2a_infants_number === 'string') {
      infants = parseInt(query.initial_2a_infants_number)
    }
    return {
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD'),
      adults,
      children,
      infants,
    }
  }
  return null
}

export function getStayParamsFromQuery(query: Route['query']): StayParams | null {
  if (['start', 'end', 'adults', 'children', 'infants'].every(field => typeof query[field] !== 'undefined')) {
    return {
      start: String(query.start),
      end: String(query.end),
      adults: parseInt(query.adults as string),
      children: parseInt(query.children as string),
      infants: parseInt(query.infants as string),
    }
  }
  return null
}

export function convertStayParamsToQuery(stayParams: StayParams): Route['query'] {
  return {
    start: String(stayParams.start),
    end: String(stayParams.end),
    adults: String(stayParams.adults),
    children: String(stayParams.children),
    infants: String(stayParams.infants),
  }
}

export function getCartSessionIdFromRoute(route: Route): string | null {
  if (route.params.cartId) {
    return route.params.cartId
  }
  if (route.query.cartId) {
    return String(route.query.cartId)
  }
  return null
}

export function getRouteName(route: Route) {
  return route.name?.split('__')[0]
}
