import { mutationTree } from 'nuxt-typed-vuex'
import { updateField } from 'vuex-map-fields'
import dayjs from 'dayjs'
import stateDef, { customerDefault } from './state'
import { Domain } from '~/types/Models/Domain'
import { Marketplace } from '~/types/Models/Marketplace'
import { Service } from '~/types/Models/Service'
import { Cart, CartGiftCard, CartGiftCardIdReference } from '~/types/Models/Cart'
import { CartPreview } from '~/types/Models/CartPreview'
import { Guest } from '~/types/Models/Guest'
import { Customer } from '~/types/Models/Customer'
import { Option } from '~/types/Models/Option'
import { DiscountType, PaymentForm } from '~/types/Models/Payment'
import { getAgeTypeOfOccupant } from '~/helpers'
import { DatePeriod } from '~/types/Models/DatePeriod'
import { ApiPaymentError, CartError, HomelocPaymentError } from '~/types/Errors'
import { Promocode } from '~/types/Models/Promocode'
import { Booking } from '~/types/Models/Booking'
import { CartSlugs } from '~/types/CartSlugs'
import { ThreeDS2Data } from '~/types/3DS2'
import { BillingAddress } from '~/types/Models'
import { HealthSafetyMeasure } from '~/types/Models/HealthSafetyMeasure'
import { ICartNotification } from './types'

export const mutations = mutationTree(stateDef, {
  UPDATE_FIELD: updateField,
  SET_INITIAL_DATA(state, {
    domain,
    marketplace,
    service,
    domainAddress,
    domainHealthSafetyMeasures,
  }: { domain: Domain, marketplace: Marketplace, service: Service, domainAddress: BillingAddress, domainHealthSafetyMeasures: HealthSafetyMeasure[] }): void {
    state.domain = domain
    state.marketplace = marketplace
    state.service = service
    state.domainAddress = domainAddress
    state.domainHealthSafetyMeasures = domainHealthSafetyMeasures
  },
  SET_BOOKING_DATES(state, bookingDates: DatePeriod): void {
    state.bookingDates = {
      start: bookingDates.start,
      end: bookingDates.end,
    }
  },
  SET_SLUGS(state, slugs: CartSlugs): void {
    state.slugs = slugs
  },
  SET_CART(state, cart: Cart): void {
    state.cart = cart
  },
  SET_CART_GUESTS(state, guests: Guest[]): void {
    if (state.cart) {
      state.cart.guests = guests
    }
  },
  CLEAR_CART(state): void {
    state.cart = null
  },
  CLEAR_CART_ERRORS(state): void {
    state.cartErrors = []
    state.cartPreviewErrors = []
  },
  SET_CART_PREVIEW(state, cartPreview: CartPreview): void {
    state.cartPreview = cartPreview
  },
  CLEAR_CART_PREVIEW(state): void {
    state.cartPreview = null
  },
  SET_CUSTOMER(state, customer: Customer): void {
    state.customer = customer
  },
  CLEAR_CUSTOMER(state): void {
    state.customer = customerDefault('fr-FR')
  },
  SET_APPLICABLE_OPTIONS(state, options: Option[]): void {
    state.options = options
  },
  SET_CART_ERRORS(state, errors: CartError[]) {
    state.cartErrors = errors
  },
  SET_CART_PREVIEW_ERRORS(state, errors: CartError[]) {
    state.cartPreviewErrors = errors
  },
  SET_CART_OPTIONS(state, options) {
    if (state.cart) {
      state.cart.options = options
    }
  },
  SET_CART_PROMOCODE(state, code: number | null) {
    if (state.cart) {
      state.cart.promocode = code
    }
  },
  SET_PAYMENT_CARD(state, cardData: Omit<PaymentForm, 'type'>) {
    state.paymentForm = {
      ...state.paymentForm,
      ...cardData,
    }
  },
  SET_PROMOCODE(state, promocode: Promocode) {
    state.promocode = promocode
  },
  CLEAR_PROMOCODE(state) {
    state.promocode = { code: null }
  },
  SET_GUESTS(state, guests: Guest[]) {
    if (state.domain && state.bookingDates?.start) {
      state.guests = guests.map(guest => ({
        ...guest,
        guestType: guest.guestType || getAgeTypeOfOccupant(
          guest.dateOfBirth,
          state.domain!.maxAgeChild,
          state.domain!.maxAgeInfant,
          dayjs(state.bookingDates?.start),
        ),
      }))
    } else {
      state.guests = guests
    }
  },
  ADD_GUEST(state, guest: Guest) {
    state.guests.push({
      ...guest,
      idx: state.guests.length,
    })
  },
  REMOVE_GUEST(state) {
    state.guests.pop()
  },
  SET_GUEST_DATE_OF_BIRTH(state, {
    guestIndex,
    dateOfBirth,
  }: { guestIndex: number, dateOfBirth: string }) {
    if (state.guests && state.guests[guestIndex]) {
      const patch: Partial<Pick<Guest, 'dateOfBirth' | 'guestType'>> = { dateOfBirth }
      // state.guests[guestIndex].dateOfBirth = dateOfBirth
      if (state.domain && state.bookingDates?.start) {
        patch.guestType = getAgeTypeOfOccupant(
          dateOfBirth,
          state.domain.maxAgeChild,
          state.domain.maxAgeInfant,
          dayjs(state.bookingDates?.start),
        )
      }
      state.guests = [
        ...state.guests.map(guest => guest.idx === guestIndex ? ({
          ...guest,
          ...patch,
        }) : guest),
      ]
    }
  },
  SET_BLOCK_NEXT_STEP(state, isBlock) {
    state.ui.blockNextStep = isBlock
  },
  INC_LOADING(state) {
    state.ui.loading = state.ui.loading + 1
  },
  DEC_LOADING(state) {
    state.ui.loading = state.ui.loading - 1
  },
  INC_CART_LOADING(state) {
    state.ui.cartLoading = state.ui.cartLoading + 1
  },
  DEC_CART_LOADING(state) {
    state.ui.cartLoading = state.ui.cartLoading - 1
  },
  SET_BOOKING(state, booking: Booking) {
    state.booking = booking
  },
  SET_UNAVAILABLE_DATES(state, dates) {
    state.unavailableDates = dates
  },
  SET_ACTIVE_DISCOUNT_TYPE(state, type: DiscountType) {
    state.activeDiscountType = type
  },
  SET_CART_GIFTCARDS(state, giftCards: CartGiftCard[]) {
    if (state.cart) {
      state.cart.giftCards = giftCards
    }
  },
  SET_CART_GIFTCARDS_REFERENCES(state, giftCardRefs: CartGiftCardIdReference[]) {
    if (state.cart) {
      state.cart.giftCards = giftCardRefs
    }
  },
  SET_IS_NEW_CUSTOMER(state, isNew: boolean) {
    state.isNewCustomer = isNew
  },
  CLEAR_CART_GIFTCARDS(state) {
    if (state.cart) {
      state.cart.giftCards = []
    }
  },
  SET_PAYMENT_ERRORS(state, paymentErrors: ApiPaymentError[] | HomelocPaymentError[]) {
    state.paymentErrors = paymentErrors
  },
  SET_SERVICE_RATING(state, rating) {
    if (rating) {
      state.serviceRating = parseFloat(parseFloat(rating)
        .toFixed(2))
    } else {
      state.serviceRating = rating
    }
  },
  SET_3DS2_CLIENT_IP(state, ip: string) {
    state.threeDS2Data.clientIPAddress = ip
  },
  SET_3DS2_CLIENT_INFO(state, clientInfo: Omit<ThreeDS2Data, 'clientIPAddress'>) {
    state.threeDS2Data = {
      ...state.threeDS2Data,
      ...clientInfo,
      isComplete: Object.keys(clientInfo).every(key => clientInfo[key] !== ''),
    }
  },
  SET_HOST_MESSAGE(state, message: string) {
    if (state.cart) {
      state.cart.hostMessage = message
    }
  },
  SET_DOMAIN_ADDRESS(state, address: BillingAddress) {
    state.domainAddress = address
  },
  SET_REFERER_URL(state, url: string) {
    state.refererUrl = url
  },
  SET_LAST_CART_MODIFICATION(state, update: ICartNotification) {
    state.lastCartModification = update
  },
  CLEAR_LAST_CART_MODIFICATION(state) {
    state.lastCartModification = null
  },
  SHOW_CART_NOTIFICATION(state) {
    state.ui.displayCartNotification = true
  },
  HIDE_CART_NOTIFICATION(state) {
    state.ui.displayCartNotification = false
  },
})

export default mutations
