import createPersistedState from 'vuex-persistedstate'
import { Context } from '@nuxt/types'
import cookie from 'cookie'

export default ({
  app,
  store,
  req,
}: Context): void => {
  createPersistedState({
    key: 'usData',
    paths: [
      'bookingDates',
      'cart.sessionId',
      'customer',
      'guests',
      'slugs',
    ],
    storage: {
      getItem: (key) => {
        if (process.server) {
          const parsedCookies = cookie.parse(req.headers.cookie || '' as string)
          return parsedCookies[key]
        } else {
          return app.$cookies.get(key, { fromRes: process.server })
        }
      },
      // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
      setItem: (key, value) => {
        app.$cookies.set(key, value, {
          path: '/',
          maxAge: 60 * 60 * 24 * 7,
          secure: false,
          sameSite: 'lax',
        })
      },
      removeItem: key => app.$cookies.remove(key),
    },
  })(store)
}
