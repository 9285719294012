















































































































































































































































import {
  computed,
  nextTick,
  defineComponent, onBeforeUnmount, onMounted, reactive, Ref, ref, toRefs, useContext, useMeta, watch,
} from '@nuxtjs/composition-api'
import { VueTelInput } from 'vue-tel-input'
import { mapLocaleToCountry, shortLocaleToCountry } from '~/helpers'
import useCustomer from '~/composable/useCustomer'
import useSteps from '~/composable/useSteps'
import { GenderEnum, PhoneObject } from '~/types/Models'
import 'vue-tel-input/dist/css/sprite.css'
import '~/assets/style/vue-tel-input.scss'
import { Country, PhoneInputCountry } from '~/types/Models/Country'
import { ValidationObserver, ValidationProvider } from '~/types/vee-validate'
import CustomerGenderSelector from '~/components/customer/CustomerGenderSelector.vue'
import ThirdPartyBookingForm from '~/components/customer/ThirdPartyBookingForm.vue'
import ReassuranceCard from '~/components/content/ReassuranceCard.vue'
import EmailSuggestionField from '~/components/ui/EmailSuggestionField.vue'
import PhoneIcon from '~/components/icons/PhoneIcon.vue'
import StepSectionTitle from '~/components/content/StepSectionTitle.vue'
import useCart from '~/composable/useCart'
import useMarketplace from '~/composable/useMarketplace'

export default defineComponent({
  name: 'StepInfo',
  components: {
    CustomerGenderSelector,
    PhoneIcon,
    ReassuranceCard,
    ThirdPartyBookingForm,
    VueTelInput,
    EmailSuggestionField,
    StepSectionTitle,
  },
  async beforeRouteLeave(to, from, next) {
    const baseRouteName = to.name!.split('___')[0]
    const currentRouteName = from.name!.split('___')[0]
    if (
      [
        currentRouteName,
        'checkout:step-booking',
        'checkout:step-options',
      ].includes(baseRouteName)
    ) {
      return next()
    }
    const observer = this.$refs.observer as unknown
    if ((await (observer as ValidationObserver).validate())) {
      this.$accessor.INC_LOADING()
      // this.$accessor.setCartCustomerFromCustomer()
      await this.$accessor.updateCart()
      this.$accessor.DEC_LOADING()
      next()
    }
  },
  setup() {
    const {
      $config: { clientAccountUrl },
      app: {
        $accessor,
        $api,
        i18n,
        $translateEntityField,
      },
    } = useContext()

    const { getCustomerField } = useCustomer()
    const { getCartField } = useCart()
    const { hasOptionsStep } = useSteps()
    const { clientAccountRedirect } = useMarketplace()

    const isLoggedIn = computed(() => $accessor.users.user !== null)

    const observer: Ref<ValidationObserver | null> = ref(null)
    const customerPhoneValidator: Ref<ValidationProvider | null> = ref(null)

    const state = reactive({
      countrySelectorOpen: false,
      countries: {},
      preferredCountries: ['FR', 'BE', 'CH', 'NL', 'ES', 'GB', 'LU', 'DE', 'IT', 'CA', 'MC'],
    })

    const customerCountry = computed(() => $accessor.customer.billingAddress?.country)

    async function onPhoneUpdate(number: string, phoneObject: PhoneObject): Promise<void> {
      customerPhoneValidator.value?.reset()

      if (customerPhoneValidator.value && !phoneObject.valid) {
        customerPhoneValidator.value.setErrors([i18n.t('form.validations.phone')])
        getCustomerField('formattedPhone').value = number
      } else if (phoneObject.valid && phoneObject.number) {
        // Set both fields to trigger frontend form validation AND save the formatted value to DB
        getCustomerField('formattedPhone').value = number
        getCustomerField('phone').value = phoneObject.number
      }
    }

    /**
     * Sync Country selector when Phone country selector changes.
     * @param country
     */
    function onPhoneCountryChange(country: PhoneInputCountry) {
      getCustomerField('billingAddress.country').value = country.iso2
    }

    onMounted(async() => {
      const isValid = await observer.value?.validate({ silent: true })
      $accessor.SET_BLOCK_NEXT_STEP(!isValid)

      const countries: Country[] = await $api.get('countries')
      const firstCountries = {} as Record<string, Country>
      const otherCountries = countries.reduce((acc, country) => {
        if (state.preferredCountries.includes(country.code)) {
          firstCountries[country.code] = {
            code: country.code,
            id: country.id,
            name: country.name,
          }
        } else {
          acc[country.code] = {
            code: country.code,
            id: country.id,
            name: country.name,
          }
        }
        return acc
      }, {} as Record<string, Country>)
      state.countries = {
        first: firstCountries,
        other: otherCountries,
      }
    })

    const stopWatch = watch(
      () => [$accessor.customer, $accessor.cart?.thirdPartyData],
      async() => {
        // Let the customer correctly update before validating, we faced some race conditions before
        await nextTick()
        if (observer.value && customerPhoneValidator.value) {
          const isValid = await observer.value.validate({ silent: true })
          // We have to check for this field independently since VeeValidate seems to suck
          const hasErrors = customerPhoneValidator.value.errors.length > 0
          $accessor.SET_BLOCK_NEXT_STEP(!isValid || hasErrors)
        }
      },
      { deep: true },
    )

    onBeforeUnmount(() => stopWatch())

    useMeta({
      title: `${$accessor.marketplace
        ? $translateEntityField($accessor.marketplace.name)
        : 'Abracadaroom'} | ${i18n.t('steps.info')}`,
    })

    return {
      ...toRefs(state),
      clientAccountRedirect,
      clientAccountUrl,
      customerCountry,
      customerPhoneValidator,
      GenderEnum,
      getCartField,
      getCustomerField,
      hasOptionsStep,
      isLoggedIn,
      mapLocaleToCountry,
      observer,
      onPhoneCountryChange,
      onPhoneUpdate,
      shortLocaleToCountry,
    }
  },
  head() {
    return {}
  },
})
