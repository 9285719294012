import { actionTree } from 'typed-vuex'
import { giftCard as giftCardEntityDefinition, state as baseState } from '~/store/giftCards/state'
import { getters as baseGetters } from '~/store/giftCards/getters'
import { mutations as baseMutations } from '~/store/giftCards/mutations'
import { GiftCard } from '~/types/Models/GiftCard'
import { Cart, CartGiftCard, CartGiftCardIdReference } from '~/types/Models/Cart'
import { createActions } from '@kissmylabs/vuex-entitystore'
import { Amount } from '~/types/Models/Amount'
import { GiftCardChargeForm } from '~/store/giftCards/types'

export const actions = actionTree({
  state: baseState,
  getters: baseGetters,
  mutations: baseMutations,
}, {
  ...createActions<GiftCard>(giftCardEntityDefinition),
  setGiftCardsFromCart(_, cart: Cart): void {
    if (cart.giftCards.length) {
      this.$accessor.giftCards.createMany((cart.giftCards as CartGiftCard[]).map(cg => cg.giftCard))
      this.$accessor.giftCards._setChargesFormValuesFromCart(cart)
    }
  },
  setCartGiftCardsFromGiftCards(): void {
    this.$accessor.CLEAR_CART_GIFTCARDS()
    this.$accessor.SET_CART_GIFTCARDS_REFERENCES(
      this.$accessor.giftCards.getGiftCardsWithChargeForm
        .map(giftCard => ({
          giftCard: giftCard.id,
          amount: giftCard.amountToSpend,
        }) as CartGiftCardIdReference),
    )
  },
  _setChargesFormValuesFromCart(_, cart: Cart): void {
    this.$accessor.giftCards.SET_CHARGES_FORM_VALUES(
      (cart.giftCards as CartGiftCard[]).reduce((acc, card) => ({
        ...acc,
        [card.giftCard.id]: {
          amountToSpend: card.amount,
          originalAmountToSpend: card.amount,
        } as GiftCardChargeForm,
      }), {}),
    )
  },
  async fetchGiftCard(_, code: string): Promise<boolean> {
    try {
      const giftCard: GiftCard = await this.app.$api.get(`carts/giftcards/${code}`)

      // TODO hotfix, do something cleaner.
      if (this.$dayjs.utc(giftCard.validUntil).isBefore(this.$dayjs.utc())) {
        this.$accessor.SET_CART_ERRORS([{ code: 16002, message: this.$i18n.t('errors.vacation.16002') }])
        return false
      }
      this.$accessor.giftCards.create(giftCard)

      const amountToSpend: Amount = {
        amount: Math.min(giftCard.remainingBalance.amount, this.$accessor.getGiftCardLeftToPay.amount),
        currency: 'EUR',
      }

      console.log(`in fetchGiftCard: ${giftCard.id} ${amountToSpend}`)
      this.$accessor.giftCards.SET_GIFTCARD_CHARGE_FORM({
        ...giftCard,
        amountToSpend,
        originalAmountToSpend: amountToSpend,
      })


      if (this.$accessor.cart) {
        this.$accessor.SET_CART_GIFTCARDS([
          // Filter to avoid duplicate giftCards, we keep the latest one only.
          ...(this.$accessor.cart.giftCards as CartGiftCard[]).filter(cg => cg.giftCard.id !== giftCard.id),
          {
            amount: amountToSpend,
            giftCard,
          },
        ])
      }
      return true
    } catch (e: any) {
      const message = e.response.status === 404
        ? this.$i18n.t('errors.giftcard_generic_error')
        : this.$i18n.t('errors.cart_generic_error')

      // Set the code to -2 so we can filter it out later and display it appart from other cart errors somewhere else 
      // Negative value to avoid conflicting with API error codes.
      const code = e.response.status === 404 ? -2 : 0

      this.$accessor.SET_CART_ERRORS([
        ...this.$accessor.cartErrors,
        {
          code,
          message,
        },
      ])
      throw e
    }
  },
  async checkAndApplyGiftCard(_, code: string): Promise<boolean> {
    this.$accessor.INC_CART_LOADING()
    this.$accessor.CLEAR_CART_ERRORS()
    let isValid = false
    try {
      isValid = await this.$accessor.giftCards.fetchGiftCard(code)
      if (!isValid) {
        this.$accessor.DEC_CART_LOADING()
        return false
      }
      await this.$accessor.updateCart()
      isValid = true
    } catch (e) {
      const prevErrors = [...this.$accessor.cartErrors]
      this.$accessor.giftCards.REMOVE_LAST_GIFTCARD()
      await this.$accessor.updateCart()
      this.$accessor.SET_CART_ERRORS(prevErrors)

      // TODO handle error codes etc.
      console.log('failed to apply giftcard, TODO handle error')
    }
    this.$accessor.DEC_CART_LOADING()
    return isValid
  },
  async removeGiftCard(_, giftCard): Promise<void> {
    this.$accessor.giftCards.delete(giftCard.id)
    if (this.$accessor.cart) {
      this.$accessor.SET_CART_GIFTCARDS(
        (this.$accessor.cart.giftCards as CartGiftCard[]).filter(cg => cg.giftCard.id !== giftCard.id),
      )
      await this.$accessor.updateCart()
    }
  },
  async clearGiftCards(): Promise<void> {
    this.$accessor.giftCards.deleteAll()
    if (this.$accessor.cart) {
      this.$accessor.CLEAR_CART_GIFTCARDS()
    }
    await this.$accessor.updateCart()
  },
})

export default actions
