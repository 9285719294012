












































































import { defineComponent } from '@nuxtjs/composition-api'
import LockIcon from '~/components/icons/LockIcon.vue'
import QuestionMarkIcon from '~/components/icons/QuestionMarkIcon.vue'
import UserGroupIcon from '~/components/icons/UserGroupIcon.vue'
import CreditCardIcon from '~/components/icons/CreditCardIcon.vue'
import LegalLinks from '~/components/footer/LegalLinks.vue'
import MarketplaceLink from '~/components/footer/MarketplaceLink.vue'
import useMarketplace from '~/composable/useMarketplace'

export default defineComponent({
  components: {
    MarketplaceLink,
    LegalLinks,
    CreditCardIcon,
    LockIcon,
    QuestionMarkIcon,
    UserGroupIcon,
  },
  setup() {
    const { isFairmoove, isUSCOM, marketplaceLinks } = useMarketplace()

    return {
      isFairmoove,
      isUSCOM,
      marketplaceLinks,
    }
  },
})
