import { createEntity, createState } from '@kissmylabs/vuex-entitystore'
import { GiftCardsState } from '~/store/giftCards/types'

export const giftCard = createEntity('giftCard')

export const state = (): GiftCardsState => ({
  ...createState(giftCard),
  chargesForm: {},
})

export default state
