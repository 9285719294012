import locale9ec66352 from '../../lang/en.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en"},
  vueI18nLoader: false,
  locales: [{"code":"en","file":"en.json","iso":"en_US"},{"code":"fr","file":"fr.json","iso":"fr_FR"},{"code":"es","file":"es.json","iso":"es_ES"},{"code":"nl","file":"nl.json","iso":"nl_NL"}],
  defaultLocale: "fr",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_and_default",
  lazy: true,
  langDir: "/var/app/lang",
  rootRedirect: "fr",
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","file":"en.json","iso":"en_US"},{"code":"fr","file":"fr.json","iso":"fr_FR"},{"code":"es","file":"es.json","iso":"es_ES"},{"code":"nl","file":"nl.json","iso":"nl_NL"}],
  localeCodes: ["en","fr","es","nl"],
}

export const localeMessages = {
  'en.json': () => Promise.resolve(locale9ec66352),
  'fr.json': () => import('../../lang/fr.json' /* webpackChunkName: "lang-fr.json" */),
  'es.json': () => import('../../lang/es.json' /* webpackChunkName: "lang-es.json" */),
  'nl.json': () => import('../../lang/nl.json' /* webpackChunkName: "lang-nl.json" */),
}
