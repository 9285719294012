<template>
<div class="mb-4">
  <h2 class="CheckoutStep-section-title">
    {{ title }}
  </h2>
  <div
    v-if="subtitle"
    class="CheckoutStep-section-subtitle"
  >
    {{ subtitle }}
  </div>
</div>
</template>

<script>
export default {
  name: 'StepSectionTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>
  .CheckoutStep-section-title {
    font-size: $size-2;
    font-weight: 500;
    line-height: 1.2;
  }

  .CheckoutStep-section-subtitle {
    font-style: italic;
    font-size: $size-5;
  }
</style>
