import { actionTree } from 'nuxt-typed-vuex'
import { decode } from 'universal-base64'
import { getters } from './getters'
import { mutations } from './mutations'
import state from '~/store/state'
import { convertStayParamsToQuery, getCartSessionIdFromRoute, getRouteSlugMatchFromLegacyPath, getSlugsFromRoute, getStayParamsFromLegacyQuery, getStayParamsFromQuery } from '~/helpers/route'
import { Context } from '@nuxt/types'
import { Route } from 'vue-router'
import { CartSlugs } from '~/types/CartSlugs'
import { CreateCartPayload } from '~/types/CreateCartPayload'
import { getEmptyGuest, shortLocaleToRegionLocale, SupportedLocale } from '~/helpers'
import { ShortToISOEnum } from '~/types/Locale'
import { Cart, CartOption, CartStatusEnum } from '~/types/Models/Cart'
import { CartPreview } from '~/types/Models/CartPreview'
import { getCartSlugsFromCookies } from '~/helpers/cookies'
import { CartError } from '~/types/Errors'
import { Customer } from '~/types/Models/Customer'
import { Option } from '~/types/Models/Option'
import { Logger } from '~/helpers/logger'
import parsePhoneNumber from 'libphonenumber-js'
import { Guest } from '~/types/Models/Guest'
import { DatePeriodOf } from '~/types/Models/DatePeriod'
import { isAxiosError, isExpectableError } from '~/helpers/api'
import { AxiosError } from 'axios'
import { DiscountType, DiscountTypeEnum, GiftCardPaymentForm, PaymentForm, PaymentFormWith3DSData, PaymentMethodEnum } from '~/types/Models/Payment'
import { Promocode } from '~/types/Models/Promocode'
import { Booking } from '~/types/Models/Booking'
import { StayParams } from '~/types/StayParams'
import { Domain } from '~/types/Models/Domain'
import { Marketplace } from '~/types/Models/Marketplace'
import { Service } from '~/types/Models/Service'
import { EntityFile } from '~/types/Models/EntityFile'
import { ThreeDS2Data } from '~/types/3DS2'
import { BillingAddress, DomainExternalId } from '~/types/Models'
import { HealthSafetyMeasure } from '~/types/Models/HealthSafetyMeasure'
import { CartTrackingData } from '~/types/analytics'

const logger = new Logger()

let loopIndex = 0

export const actions = actionTree({
  state,
  getters,
  mutations,
}, {
  /**
   * Handle cart restore, new cart init, or fallback redirection using the following strategies:
   * - 1: if legacy route, it's coming from a marketplace, so we clear the cart and redirect with cleaned params.
   * - 2: if we have a cartId in URL, we restore from this cart id
   * - 3: if booking dates in URL are different than the ones restored from cookies, create new cart
   * - 4: if cart ID in cookies, restore it
   * - 4-a: if cart restored cannot be booked (closed/abandoned/paid), create a new cart TODO make sure it's OK
   * - 4-b: if cart restored can be booked, continue
   * - 5: create a new cart from query params.
   * - 6: redirect to no-cart if none of the previous strategies worked.
   * @param _
   * @param app
   * @param route
   * @param redirect
   */
  async nuxtServerInit(_, { app, route, redirect, req }: Context): Promise<Route | void> {

    if (route.name?.startsWith('no-cart')) {
      return
    }

    if (req.headers.referer) {
      this.$accessor.SET_REFERER_URL(req.headers.referer)
    }

    const clearPersistedData: () => void = () => {
      // Cleanup state from previous sessions.
      this.$accessor.resetCart()
      this.$accessor.resetCartErrors()
      this.$accessor.SET_GUESTS([])
    }

    try {
      const authCookie = this.$cookies.get('us_auth')

      if (authCookie) {
        const requestConfig = {
          headers: {
            authorization: `Bearer ${authCookie.token}`,
          },
        }
        const loggedInUser = await this.$api.get(`/users/${authCookie.user}`, 0, requestConfig)
        if (loggedInUser) {
          const userProfile = await this.$api.get('/public/customers/profile', 0, requestConfig)
          if (userProfile.dateOfBirth) {
            userProfile.dateOfBirth = this.$utcDate(userProfile.dateOfBirth).format('YYYY-MM-DD')
          }
          // Set empty billingAddress if the entity has not yet been created
          if (!userProfile.billingAddress) {
            userProfile.billingAddress = {
              addressLine1: '',
              city: '',
              postalCode: '',
              country: null,
            }
          }
          this.$accessor.users.SET_USER(loggedInUser)
          this.$accessor.SET_CUSTOMER(userProfile)
        }
      }
    } catch (error) {
      logger.debug(error)
    }

    // Legacy route handler.
    // If legacy route, fetch relations then redirect server-side.
    // There's no issue about restoring a cart here: legacy routes are always first-time entry points.
    const routeSlugMatch = getRouteSlugMatchFromLegacyPath(route)
    if (routeSlugMatch) {
      clearPersistedData()
      this.$accessor.CLEAR_CUSTOMER()
      console.log('[NuxtServerInit]: legacy route handler, cleaning cart')

      const stayParams = getStayParamsFromLegacyQuery(route.query)
      if (!stayParams || !stayParams.start || !stayParams.end) {
        console.log('[NuxtServerInit]: stayParams invalid, redirecting to no-cart : ', stayParams)
        return redirect(302, '/no-cart')
      }

      if (route.query.affiliateTrackingId) {
        this.$cookies.set('ae_id', route.query.affiliateTrackingId, {
          path: '/',
          maxAge: 60 * 60 * 24 * 45,
          secure: false,
        })
      }

      if (route.query.originName) {
        this.$cookies.set('origin_name', route.query.originName, {
          path: '/',
          maxAge: 60 * 60 * 24 * 7,
          secure: false,
        })
      }

      return redirect(app.localePath({
        name: routeSlugMatch.routeName,
        query: {
          marketplaceSlug: routeSlugMatch.marketplaceSlug,
          serviceSlug: routeSlugMatch.serviceSlug,
          ...convertStayParamsToQuery(stayParams),
        },
      }))
    }
    // Normal route handler.

    // Store real IP in store.
    let clientIP = ''
    if (req.headers && req.headers['x-real-ip']) {
      clientIP = req.headers['x-real-ip'] as string
      // console.log(`x-real-ip is ${clientIP}`)
    } else {
      clientIP = (req.connection.remoteAddress || req.socket.remoteAddress) as string
      console.log(`Warning: no x-real-ip found, using remoteAddress: ${clientIP}`)
    }
    this.$accessor.SET_3DS2_CLIENT_IP(clientIP)

    // Otherwise get cart id from route.
    const cartIdFromRoute = getCartSessionIdFromRoute(route)

    let cartRestoredOrCreated = false

    if (cartIdFromRoute) {
      logger.debug('[NuxtServerInit]: cart ID in route = ' + cartIdFromRoute)
      cartRestoredOrCreated = await this.$accessor.initSessionFromCartId(cartIdFromRoute)

      if (cartRestoredOrCreated) {
        if (!route.name?.startsWith('checkout:')) {
          logger.debug('[NuxtServerInit]: cart restored/created, redirecting to booking step')
          return this.$router.push(this.app.localePath({ name: 'checkout:booking' }))
        }
      } else {
        logger.debug('[NuxtServerInit]: failed to restore cart from ID in route!!!')
        this.$sentry.captureException(new Error('Failed to restore cart from ID in route'), {
          extra: { cartId: cartIdFromRoute },
        })
      }
    } else {
      logger.debug('[NuxtServerInit]: no cart ID in route, processing...')
      // We load slugs and stayParams from both route query and cookies,
      // and compare them to know if we want to init a new cart (even if one exists, but slugs/stayParams differ),
      // or if we can load the cart from the stored ID.
      const slugs = await this.$accessor._getSlugsFromRouteOrCookies(route)
      const routeStayParams = getStayParamsFromQuery(route.query)

      // Will reset cart state if query parameters have changed.
      const shouldResetCartState = await this.$accessor._queryHasChangedParams()

      if (!shouldResetCartState && this.$accessor.cart?.sessionId) {
        logger.debug('[NuxtServerInit]: has session id in state')
        // Restore cart
        cartRestoredOrCreated = await this.$accessor.initSessionFromCartId(this.$accessor.cart?.sessionId)

        // If we still need to create a new cart (eg closed cart),
        // try to do so from previously fetched data.
        if (
          cartRestoredOrCreated &&
          routeStayParams &&
          this.$accessor.cart &&
          [CartStatusEnum.CLOSED, CartStatusEnum.PAID, CartStatusEnum.ABANDONED].includes(this.$accessor.cart.status)
        ) {
          logger.debug('[NuxtServerInit]: should create new cart, status is' + this.$accessor.cart.status)
          cartRestoredOrCreated = await this.$accessor.initSessionFromSlugs({
            serviceSlug: this.$accessor.service!.slug,
            marketplaceSlug: this.$accessor.marketplace!.slug,
          })
        }
        // Check if need to create a new one base on slugs
      } else if (slugs && routeStayParams) {
        clearPersistedData()
        // Set booking dates in store
        this.$accessor.setStayParams(routeStayParams)
        logger.debug('[NuxtServerInit]: has slugs : ', slugs)
        cartRestoredOrCreated = await this.$accessor.initSessionFromSlugs(slugs)
      }
    }

    if (!cartRestoredOrCreated || !this.$accessor._cartRelationsLoaded()) {
      logger.debug('[NuxtServerInit]: could not load cart relations!!!')
      return redirect(this.app.localePath({ name: 'no-cart' }))
    }
  },
  /**
   * Set booking base entities (domain, marketplace, service, files) in state.
   *
   * @param _
   * @param domain
   * @param marketplace
   * @param service
   * @param files
   */
  _setBaseEntities(_, { domain, marketplace, service, files, domainAddress, domainHealthSafetyMeasures }: { domain: Domain, marketplace: Marketplace, service: Service, files: EntityFile[], domainAddress: BillingAddress, domainHealthSafetyMeasures: HealthSafetyMeasure[] }): void {
    const formattedDomain: Domain = {
      ...domain,
      externalId: domain.externalId ? JSON.parse(decode(domain.externalId as unknown as string)) as DomainExternalId : null,
    }

    this.$accessor.SET_INITIAL_DATA({
      domain: formattedDomain,
      marketplace,
      service,
      domainAddress,
      domainHealthSafetyMeasures,
    })
    // Map files in store.entityFiles.entities
    this.$accessor.entityFiles.createMany(files)
  },
  /**
   * Fetch cart relations (domain/marketplace/service) from Slugs,
   * then try to init a new Cart.
   *
   * @param _
   * @param slugs
   */
  async initSessionFromSlugs(_, slugs: CartSlugs): Promise<boolean> {
    const initData = await this.app.$api.post('/slugs', slugs)
    this.$accessor._setBaseEntities(initData)

    let cartCreated = false
    logger.debug('initSessionFromSlugs: init new cart from slugs', slugs)

    try {
      cartCreated = await this.$accessor.initNewCart()
    } catch (e: any) {
      logger.error('initSessionFromSlugs: catched error: ', e)
      this.$sentry.captureException(e)
      this.$accessor.resetCart()

      if (isAxiosError(e) && isExpectableError(e)) {
        this.$accessor._handleCartPreviewErrors(e)
      } else {
        this.$accessor.resetCartErrors()
      }
    }
    return cartCreated
  },
  /**
   * Fetch cart relations (domain/marketplace/service) from Cart sessionId,
   * then fetch the cart itself.
   *
   * @param _
   * @param sessionId
   */
  async initSessionFromCartId(_, cartId: string): Promise<boolean> {
    // TODO add clickup task to ask API to also return the cart.
    const initData = await this.app.$api.get(`carts/${cartId}/data`)
    this.$accessor._setBaseEntities(initData)

    let cartRestored = false
    logger.debug('initSessionFromCartId: fetch cart from cartId: ' + cartId)

    try {
      cartRestored = await this.$accessor.fetchCartById(cartId)

      if (cartRestored && this.app.$accessor.options.length === 0 && this.app.$accessor.cart?.booking?.options.length) {
        await this.$accessor.fetchApplicableOptions()
      }
    } catch (e: any) {
      logger.error('initSessionFromCartId: catched error: ', e)
      this.$sentry.captureException(e)
      this.$accessor.resetCart()

      if (isAxiosError(e) && isExpectableError(e)) {
        this.$accessor._handleCartPreviewErrors(e)
      } else {
        this.$accessor.resetCartErrors()
      }
    }
    return cartRestored
  },
  _cartRelationsLoaded(): boolean {
    return !!this.$accessor.marketplace && !!this.$accessor.domain && !!this.$accessor.service
  },
  async _queryHasChangedParams(): Promise<boolean> {
    const routeStayParams = getStayParamsFromQuery(this.app.context.route.query)
    const stateStayParams = this.$accessor.getStayParamsFromState
    if (routeStayParams && stateStayParams) {
      // TODO we don't check if Marketplace has changed, as marketplace links pass through legacy url handler.
      // Make sure it's what we want
      return this.$utcDate(routeStayParams.start).toISOString() !== this.$utcDate(stateStayParams.start).toISOString() ||
        this.$utcDate(routeStayParams.end).toISOString() !== this.$utcDate(stateStayParams.end).toISOString()
    }
    return false
  },
  async _getSlugsFromRouteOrCookies(_, route): Promise<CartSlugs | null> {
    // Try to get slugs from new route (using query instead of params)
    const routeCartSlugs = getSlugsFromRoute(route)
    if (routeCartSlugs) {
      return routeCartSlugs
    }
    // And try to get slugs from cookies.
    const cookiesCartSlugs = getCartSlugsFromCookies(this.$cookies)
    if (cookiesCartSlugs) {
      return cookiesCartSlugs
    }
    return null
  },
  /**
   * Fetch the data we don't need before first render.
   */
  async fetchAfterLoadData(): Promise<void> {
    await Promise.all([
      this.$accessor.fetchServiceRating(),
      // this.$accessor.fetchUnavailabilities(),
    ])
  },

  setStayParams(_, stayParams: StayParams): void {
    if (stayParams) {
      this.$accessor.SET_BOOKING_DATES(stayParams)
    }

    // Set initial guests based on stayParams counts.
    for (let i = 0; i < (stayParams.adults + stayParams.children + stayParams.infants) - 1; i++) {
      this.$accessor.ADD_GUEST(getEmptyGuest(i))
    }
  },
  /**
   * Initialize a new Cart.
   * TODO dedupe with fetchCartById
   */
  async initNewCart(): Promise<boolean> {
    if (
      this.$accessor.bookingDates === null ||
      this.$accessor.cart?.id ||
      !this.$accessor.service ||
      !this.$accessor.marketplace
    ) {
      return false
    }
    this.$accessor.INC_LOADING()

    const payload: CreateCartPayload = {
      service: this.$accessor.service.id,
      marketplace: this.$accessor.marketplace.id!,
      lang: shortLocaleToRegionLocale(this.$i18n.locale as SupportedLocale) || ShortToISOEnum.EN,
      start: this.$accessor.bookingDates.start,
      end: this.$accessor.bookingDates.end,
    }

    try {
      logger.debug('init new cart')
      const res: Cart = await this.app.$api.post('/carts/', payload)
      this.$accessor._setCart(res)

      // TODO don't forget to try/catch here to get errors early on
      // Always run fetchApplicableOptions before for Valet.
      await this.$accessor.fetchApplicableOptions()
      await this.$accessor.fetchCartPreview()

      // TODO uncomment when https://app.clickup.com/t/1381122/US-3217 is fixed.
      // this.app.$accessor.fetchUnavails()
      logger.debug('cart initialized and saved in cookies:', this.$accessor.cart!.sessionId)
      this.app.$accessor.DEC_LOADING()
      return true
    } catch (e: any) {
      if (isAxiosError(e) && isExpectableError(e)) {
        logger.debug(`Error in initNewCart: ${e.response.data.message}`)
        this.$accessor._handleCartPreviewErrors(e)
        this.$sentry.captureException(e, { extra: { code: e.response.data.code, message: e.response.data.message } })
      } else {
        logger.debug(`Error in initNewCart: ${e}`)
        this.$sentry.captureException(e)
      }
      this.app.$accessor.DEC_LOADING()
      return false
    }

  },
  /**
   * Sets data received from cart.customer in state.customer if received data isn't null.
   * (used when receiving data from API)
   * @param _
   * @param cart
   */
  setCustomerFromCart(_, cart: Partial<Cart>): void {

    let customer: Customer | null = null

    if (cart.customer) {
      const parsedPhone = parsePhoneNumber(cart.customer.phone)
      const formattedPhone = parsedPhone?.formatNational() || cart.customer.phone

      customer = {
        ...cart.customer,
        formattedPhone,
        dateOfBirth: cart.customer.dateOfBirth
          ? this.$utcDate(cart.customer.dateOfBirth).format('YYYY-MM-DD')
          : cart.customer.dateOfBirth,
      }
    } else {
      customer = {
        ...this.$accessor.customer,
      }
    }
    this.$accessor.SET_CUSTOMER(customer)
  },
  resetCart(): void {
    this.$accessor.CLEAR_CART_PREVIEW()
    this.$accessor.CLEAR_CART()
  },
  resetCartErrors(): void {
    logger.debug('resetCartErrors')
    this.$accessor.SET_CART_ERRORS([])
    this.$accessor.SET_CART_PREVIEW_ERRORS([])
    this.$accessor.SET_PAYMENT_ERRORS([])
  },
  /**
   * Fetch a Cart by its sessionId.
   * @param _
   * @param sessionId
   */
  async fetchCartById(_, sessionId: string): Promise<boolean> {
    this.$accessor.INC_LOADING()
    logger.debug('fetchCartById: ' + sessionId)
    try {
      const cart: Cart = await this.app.$api.get(`/carts/${sessionId}`)
      if (cart && cart.status !== CartStatusEnum.ABANDONED) {
        this.$accessor._setCart(cart)
        // Check if current language is different from cart, update cart before if so.
        if (this.$accessor.cart!.lang !== shortLocaleToRegionLocale(this.$i18n.locale as SupportedLocale) && !this.app.context.route.name.startsWith('checkout:finish')) {
          this.$accessor._setCart({
            ...cart,
            lang: shortLocaleToRegionLocale(this.$i18n.locale as SupportedLocale) as ShortToISOEnum,
          })
          await this.$accessor.updateCart()
        }
        // Only try to get a preview from an open cart.
        else if (this.$accessor.cart!.status === CartStatusEnum.OPEN) {
          await this.$accessor.fetchApplicableOptions()
          await this.$accessor.fetchCartPreview()
        }
        this.app.$accessor.DEC_LOADING()
        return true
      }
    } catch (e: any) {
      if (isAxiosError(e) && isExpectableError(e)) {
        logger.debug(`Error in fetchCartById: ${e.response.data.message}`)
        this.$accessor._handleCartPreviewErrors(e)
      } else {
        logger.debug(`Error in fetchCartById: ${e}`)
      }
      this.$sentry.captureException(e)
    }
    this.app.$accessor.DEC_LOADING()
    return false
  },
  /**
   * Fetch a CartPreview from a Cart sessionId.
   */
  async fetchCartPreview(): Promise<void> {
    if (this.$accessor.cart?.sessionId) {
      logger.debug('fetchCartPreview: start')
      this.$accessor.SET_CART_PREVIEW_ERRORS([])
      try {
        const res: CartPreview = await this.app.$api.get(`/carts/${this.$accessor.cart.sessionId}/preview`)
        this.$accessor.SET_CART_PREVIEW(res)
      } catch (e: any) {
        logger.error('Failed to fetch cart preview')
        this.$accessor._handleCartPreviewErrors(e)
        throw e
      }
    }
  },
  async _handleCartPreviewErrors(_, e: AxiosError): Promise<void> {
    if (isAxiosError(e) && isExpectableError(e)) {
      const { code, message } = e.response?.data as CartError

      switch (code) {
        default:
          break
        case 11006: // OptionNotActiveException11006: the option is not active (bookable) anymore.
          // We update Cart selected options by removing the ones that are not in state.options (thus not available),
          // then update the Cart against the API,
          // set an informative error for the removed options,
          // and return a new call to fetchPreview().
          // TODO remove this when https://app.clickup.com/t/1381122/US-3335 is fixed.
          // TODO inform the user that an option was removed, but maybe not an option...
          const enabledOptions = this.$accessor.options.map(o => o.id) // eslint-disable-line no-case-declarations

          if (this.$accessor.cart) {
            this.$accessor.SET_CART_OPTIONS(
              this.$accessor.cart.options.filter(o => enabledOptions.includes(o.option)),
            )
            this.$accessor.CLEAR_CART_PREVIEW()
            await this.$accessor.updateCart()
          }
          break
        case 16002: // GiftCardExpiredException16002
        // TODO should we actually do something here? As we already prevent adding an expired one?

      }
      this.$accessor.SET_CART_PREVIEW_ERRORS([{
        code,
        message,
      }])
    } else {
      this.$accessor.SET_CART_PREVIEW_ERRORS([{
        code: 0,
        message: this.$i18n.t('errors.cart_generic_error'),
      }])
    }
    this.$accessor.CLEAR_CART_PREVIEW()
  },
  async fetchApplicableOptions(): Promise<void> {
    if (this.$accessor.cart?.sessionId) {
      try {
        const res: { data: Option[] } = await this.app.$api.get(`/carts/${this.$accessor.cart.sessionId}/applicableoptions`)
        this.$accessor.SET_APPLICABLE_OPTIONS(res.data)
      } catch (e: any) {
        logger.debug('fetchApplicableOptions failed: ' + e)
        throw e
      }
    }
  },
  async changeBookingDates(_, newDates: DatePeriodOf<string>): Promise<void> {
    this.$accessor.SET_BOOKING_DATES(newDates)
    // Reset cart when changing dates, until https://app.clickup.com/t/1381122/US-3751 is done
    this.$accessor.CLEAR_CART()
    await this.$accessor.initNewCart()
  },
  async fetchUnavailabilities(): Promise<void> {
    const start = this.$utcDate().toISOString()
    const end = this.$utcDate().add(1, 'y').toISOString()

    try {
      /*const res =*/
      await this.app.$api.get(
        `/services/${this.app.$accessor.service!.id}` +
        `/unavailabilitycalendar/?start=${start}&end=${end}`,
      )
    } catch (e: any) {
      logger.debug(e.response.data)
      // this.app.$sentry.captureException(e)
    }
  },
  _setCart(_, cart: Cart): void {
    // Set base discount type based on what's in the Cart.
    if (this.$accessor.cart?.giftCards?.length) {
      this.$accessor.SET_ACTIVE_DISCOUNT_TYPE(DiscountTypeEnum.DISCOUNT_GIFTCARDS)
    } else if (this.$accessor.cart?.promocode) {
      this.$accessor.SET_ACTIVE_DISCOUNT_TYPE(DiscountTypeEnum.DISCOUNT_PROMOCODE)
    }

    this.$accessor.SET_BOOKING_DATES({
      start: cart.start,
      end: cart.end,
    })

    this.$accessor.SET_CART(cart)
    this.$accessor.setCustomerFromCart(cart)
    this.$accessor.setGuestsFromCart(cart)

    this.$accessor.setPromocodeFromCart(cart)

    if (cart.booking) {
      this.$accessor.SET_BOOKING(cart.booking)
    }

    this.$accessor.giftCards.setGiftCardsFromCart(cart)
  },
  _prepareCart(): void {
    this.$accessor.setCartCustomerFromCustomer()
    this.$accessor.setCartGuestsFromGuests()

    this.$accessor.giftCards.setCartGiftCardsFromGiftCards()
    this.$accessor.setCartPromocodeFromPromocode()
  },
  async updateCart(): Promise<void> {
    if (this.$accessor.cart?.sessionId === null) {
      return
    }
    logger.debug(`==== in updateCart start: loop ${loopIndex++} ====`)
    this.$accessor.INC_CART_LOADING()
    // this.app.$accessor.SET_CART_PREVIEW(null) // TODO check if needed
    this.$accessor.SET_CART_ERRORS([])

    this.$accessor._prepareCart()

    const payload = { ...this.$accessor.cart }

    try {
      const res: Cart = await this.app.$api.patch(`/carts/${this.$accessor.cart!.sessionId}`, payload)

      this.$accessor._setCart(res)

      await this.$accessor.fetchApplicableOptions()
      await this.$accessor.fetchCartPreview()

      if (this.app.$accessor.lastCartModification && this.app.$accessor.cartPreview?.totalChargeWithDiscountsAndPromocode.amount !== this.app.$accessor.lastCartModification.previousPrice) {
        this.app.$accessor.SHOW_CART_NOTIFICATION()
      }

      this.app.$accessor.DEC_CART_LOADING()
      logger.debug(`==== in updateCart end: loop ${loopIndex} ====`)

    } catch (e: any) {
      logger.error(e)
      logger.debug(`==== in updateCart error: loop ${loopIndex} ====`)
      if (isAxiosError(e) && isExpectableError(e)) {
        const {
          code,
          message,
        } = e.response.data as CartError
        this.$accessor.SET_CART_ERRORS([{
          code,
          message,
        }])
        this.$accessor._handleCartPreviewErrors(e)
        this.app.$sentry.captureException(e, { extra: { code, message } })
      } else {
        this.$accessor.SET_CART_ERRORS([{
          code: 0,
          message: this.$i18n.t('errors.cart_generic_error'),
        }])
        this.app.$sentry.captureException(e)
      }
      this.app.$accessor.DEC_CART_LOADING()
      throw e
    }
  },
  async payCart(): Promise<void> {
    // Tracking data.
    const trkData: CartTrackingData = {}
    let res
    this.$accessor.SET_CART_ERRORS([])
    this.$accessor.SET_CART_PREVIEW_ERRORS([])
    this.$accessor.SET_PAYMENT_ERRORS([])
    try {
      this.$accessor.INC_CART_LOADING()
      // If the user added a message to the host, update the cart a last time before paying
      if (this.$accessor.cart?.hostMessage) {
        await this.$accessor.updateCart()
      }
      let payload
      if (this.$accessor.getIsFullGiftCardPayment) {
        // check for https://sentry.unicstay.com/organizations/unicstay/issues/257/?project=5&query=threeDS2Data&statsPeriod=14d
        if (this.$accessor.cart && this.$accessor.cart.giftCards.length === 0) {
          throw new Error('getIsFullGiftCardPayment returns true while no giftCards in cart')
        }
        payload = {
          type: PaymentMethodEnum.METHOD_GIFT_CARD,
          isFullGiftCardPayment: true,
        } as GiftCardPaymentForm
      } else {
        if (this.$accessor.threeDS2Data.isComplete) {
          payload = {
            ...this.$accessor.paymentForm,
            holderName: this.$accessor.paymentForm.holderName.toUpperCase(),
            threeDS2Data: this.$accessor.threeDS2Data as ThreeDS2Data,
          } as PaymentFormWith3DSData
        } else {
          this.$sentry.captureException(new Error('3DS2 Data incomplete!!! Something went wrong!!!'))
        }
      }
      // If threeDS2 enabled but it's not complete, fallback to vanilla payment.
      if (typeof payload === 'undefined') {
        payload = {
          ...this.$accessor.paymentForm,
          holderName: this.$accessor.paymentForm.holderName.toUpperCase(),
        } as PaymentForm
      }
      payload.isFullGiftCardPayment = this.$accessor.getIsFullGiftCardPayment

      if (!this.$accessor.cart?.sessionId) {
        throw new Error('No cart session id found, cannot pay')
      }

      if (this.$cookies.get<string>('ae_id')) {
        trkData.affilaeTrackingId = this.$cookies.get<string>('ae_id')
      }
      if (this.$cookies.get<string>('origin_name')) {
        trkData.originName = this.$cookies.get<string>('origin_name')
      }

      res = await this.app.$api.post(`carts/${this.$accessor.cart!.sessionId}/pay`, {
        ...payload,
        trkData,
      })

      logger.debug('Paycart response =>', res)

      if (res.isSuccess) {
        // Handle 3DS redirection if 3DS requested.
        if (res.paymentRedirect) {
          window.location = res.paymentRedirect
        } else {
          // Redirect to finish page if no 3DS requested.
          this.app.router.push(this.app.localePath({
            name: 'checkout:finish',
            params: { cartId: this.$accessor.cart!.sessionId },
          }))
        }
        if (res.isNewCustomer !== null) {
          this.$accessor.SET_IS_NEW_CUSTOMER(res.isNewCustomer)
        }
      } else {
        this.$accessor.SET_PAYMENT_ERRORS(
          res.errors.map(
            (err: string) => ({
              code: err,
              message: this.$i18n.t(`errors.homelocpay.${err}`),
            }),
          ),
        )
      }
    } catch (e: any) {
      logger.debug('Error during cart payment', e)
      if (isAxiosError(e) && isExpectableError(e)) {
        const {
          code,
          message,
        } = e.response.data as CartError

        this.$accessor._handlePaymentErrors(code)
        this.app.$accessor.SET_PAYMENT_ERRORS([{
          code,
          message,
        }])
        this.$sentry.captureException(e, { extra: { code, message } })
      } else {
        this.app.$accessor.SET_PAYMENT_ERRORS([{
          code: 0,
          message: this.$i18n.t('errors.cart_generic_error'),
        }])
        this.$sentry.captureException(e)
      }
    }
    this.$accessor.DEC_CART_LOADING()
  },
  _handlePaymentErrors(_, errorCode: number): void {
    switch (errorCode) {
      case 14003:
        this.$accessor.resetCart()
        break
      default:
        break
    }
  },
  /**
   * Update selected cart options and quantities in state.cart.options.
   * @param _
   * @param options
   */
  async setCartOptions(_, options: CartOption[]): Promise<void> {
    this.$accessor.SET_CART_OPTIONS(options)
    await this.$accessor.updateCart()
  },
  /**
   * Fills state.cart.customer with local state.customer if local data is complete.
   */
  setCartCustomerFromCustomer(): void {
    let customer: Customer

    if (this.$accessor.getCustomerHasAllData) {
      if (this.$accessor.cart?.customer) {
        customer = {
          ...this.$accessor.cart.customer,
          ...this.$accessor.customer,
        }
      } else {
        customer = { ...this.$accessor.customer }
      }

      if (this.$accessor.cart) {
        this.$accessor.SET_CART({
          ...this.$accessor.cart,
          customer,
        })
      }
    }
  },
  setCartGuestsFromGuests(): void {
    const fullGuests: Guest[] = []
    this.$accessor.guests.forEach((guest) => {
      if (guest.dateOfBirth && guest.guestType) {
        fullGuests.push(guest)
      }
    })
    this.$accessor.SET_CART_GUESTS(fullGuests)
  },
  setGuestsFromCart(_, cart: Cart): void {
    if (cart.guests.length) {
      const guestIdxes = this.$accessor.guests.map(guest => guest.idx)
      // Pick only cart guests that are still in the store.
      const cartGuestsStillValid = cart.guests.filter(guest => guestIdxes.includes(guest.idx))
      const cartGuestIdxes = cartGuestsStillValid.map(guest => guest.idx)
      const mergedGuests = [
        // Merge guests from cart with formatted dateOfBirth
        ...cartGuestsStillValid.map(guest => ({
          ...guest,
          dateOfBirth: this.$utcDate(guest.dateOfBirth).format('YYYY-MM-DD'),
        })),
        // Merge any additional guest from state that wasn't added to the cart yet.
        ...this.$accessor.guests.filter(guest => !cartGuestIdxes.includes(guest.idx)),
      ]
      this.$accessor.SET_GUESTS(mergedGuests)
    }
  },
  setPromocodeFromCart(_, cart: Cart): void {
    if (cart.promocode) {
      // The API returns the serialized promocode,
      // but expects an ID for updates. As we keep it typed as an ID, we need
      // to cast the serialized promocode as a Promocode, not a number.
      this.$accessor.SET_PROMOCODE(cart.promocode as unknown as Promocode)
    }
  },
  setCartPromocodeFromPromocode(): void {
    if (this.$accessor.promocode.code) {
      this.$accessor.SET_CART_PROMOCODE(this.$accessor.promocode.id)
    }
  },
  async changeDiscountTypeAndUpdate(_, type: DiscountType): Promise<void> {
    this.$accessor.SET_ACTIVE_DISCOUNT_TYPE(type)
    let shouldUpdateCart = false
    if (type === DiscountTypeEnum.DISCOUNT_PROMOCODE || type === null) {
      this.$accessor.giftCards.deleteAll()
      // Discount type changed, if not giftCard and giftCArds were previously added,
      // update the cart.
      if (this.$accessor.getGiftCardChargesTotal.amount > 0) {
        this.$accessor.SET_CART_GIFTCARDS([])
        shouldUpdateCart = true
      }
    }
    if (type === DiscountTypeEnum.DISCOUNT_GIFTCARDS || type === null) {
      // If there's a promocode ID then the cart must be updated
      shouldUpdateCart = !!this.$accessor.promocode.id
      this.$accessor.CLEAR_PROMOCODE()
      this.$accessor.SET_CART_PROMOCODE(null)
    }

    if (shouldUpdateCart) {
      await this.$accessor.updateCart()
    }
  },
  async _fetchPromocode(_, code: string): Promise<boolean> {
    try {
      const res: Promocode = await this.app.$api.get(`carts/promocodes/${code}`)

      const now = this.$dayjs()

      // TODO hotfix, do something cleaner.
      if (
        res.periods &&
        res.periods.length &&
        !res.periods.some(period => this.$dayjs.utc(period.start).isBefore(now) && this.$dayjs.utc(period.end).isAfter(now))
      ) {
        this.$accessor.SET_CART_ERRORS([{ code: 13002, message: this.$i18n.t('errors.vacation.13002') }])
        return false
      }
      this.$accessor.SET_PROMOCODE(res)
      return true
    } catch (e: any) {
      const message = e.response.status === 404
        ? this.$i18n.t('errors.promocode_generic_error')
        : this.$i18n.t('errors.cart_generic_error')

      // Set the code to -1 so we can filter it out later and display it appart from other cart errors somewhere else
      // Negative value to avoid conflicting with API error codes.
      const code = e.response.status === 404 ? -1 : 0

      this.$accessor.SET_CART_ERRORS([
        ...this.$accessor.cartErrors,
        {
          code,
          message,
        },
      ])
      this.$accessor.CLEAR_PROMOCODE()
      throw e
    }
  },
  async checkAndApplyPromocode(_, code: string): Promise<boolean> {
    this.$accessor.INC_CART_LOADING()
    this.$accessor.CLEAR_CART_ERRORS()
    let isValid = false
    try {
      // Fetch the full promocode.
      isValid = await this.$accessor._fetchPromocode(code)
      if (!isValid) {
        this.$accessor.DEC_CART_LOADING()
        return false
      }
      // Set in in the cart and try to update.
      // If we don't get any error,
      // it means the cart was successfully updated with the code.
      this.$accessor.SET_CART_PROMOCODE(this.$accessor.promocode.id)
      await this.$accessor.updateCart()
      isValid = true
    } catch (e: any) {
      isValid = false
    }
    this.$accessor.DEC_CART_LOADING()
    return isValid
  },
  async updatePromocode(_ignored, promocode): Promise<void> {
    this.$accessor.SET_CART_PROMOCODE(promocode)
    this.$accessor.giftCards.deleteAll()
    try {
      await this.app.$accessor.updateCart()
    } catch (e: any) {
      const errors = [...this.$accessor.cartErrors]
      this.$accessor.SET_CART_PROMOCODE(null)
      await this.$accessor.updateCart()
      this.$accessor.SET_CART_ERRORS(errors)
    }
  },

  async checkCartPayment(): Promise<boolean> {
    const res: {
      isSuccess: boolean,
      isNewCustomer: boolean,
      booking: Booking
    } = await this.app.$api.post(`/carts/${this.$accessor.cart!.sessionId}/check`, {})
    if (res.isSuccess) {
      this.$accessor.SET_BOOKING(res.booking)
      if (this.$accessor.isNewCustomer === null && res.isNewCustomer !== null) {
        this.$accessor.SET_IS_NEW_CUSTOMER(res.isNewCustomer)
      }
    }
    return res.isSuccess
  },

  async fetchServiceRating(): Promise<void> {
    if (this.$accessor.service?.id && this.$accessor.domain?.id) {
      try {
        const serviceId = this.$accessor.service.id

        const res = await this.app.$axios.get(
          `${this.app.$config.abcdBaseUrl}/api/vacation/services/${serviceId}/rating`,
        )
        if (res.data && res.data.rating !== null) {
          this.$accessor.SET_SERVICE_RATING(res.data.rating)
        }
      } catch (e: any) {
        logger.debug(e)
        // this.app.$sentry.captureException(e)
      }
    }
  },
})

export default actions
