import { computed, ComputedRef, onBeforeUnmount, useContext, watch, WritableComputedRef } from '@nuxtjs/composition-api'
import { calculateAgeAtDate, getAgeTypeOfOccupant, getAgeTypePlural, getEmptyGuest } from '~/helpers'
import { GuestTypeEnum, Guest } from '~/types/Models/Guest'
import useCustomer from '~/composable/useCustomer'
import { Amount, ExternalPlatformTypeEnum, ServiceExternalId } from '~/types/Models'
import useService from '~/composable/useService'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

type GuestTypesWithPrice = Record<GuestTypeEnum, { count: number, amount: Amount }>

interface GuestsHook {
  getAdditionalPersonsWithPrices: ComputedRef<GuestTypesWithPrice | null>
  getGuestAdditionalPersonChargeText(guestIndex: number): ComputedRef<string | null>
  getGuestDateOfBirth(guestIndex: number): WritableComputedRef<string>
  getGuestsCount: WritableComputedRef<number>
  getServiceExternalIdObject: ComputedRef<ServiceExternalId | null>
  guestLimitErrors: ComputedRef<Array<string | null>>
  isGuestMajorityBetweenBookingStartNow(guestIndex: number): boolean
  isGuestInfansBetweenBookingStartNow(guestIndex: number): boolean
  watchGuests(): void
}

function useGuests(): GuestsHook {
  const { app: { $accessor, i18n, $parseAmount } } = useContext()
  const { isCustomerDateOfBirthValid } = useCustomer()
  const { getServiceExternalIdObject } = useService()

  const getGuestsCount: WritableComputedRef<number> = computed({
    get: () => $accessor.guests.length + 1,
    set: value => {
      if (value >= 1) {
        if (value > getGuestsCount.value - 1 && getGuestsCount.value < $accessor.service!.maxPeopleAllowed) {
          return $accessor.ADD_GUEST(getEmptyGuest(getGuestsCount.value - 1))
        }
        $accessor.REMOVE_GUEST()
        return $accessor.updateCart()
      }
    },
  })

  const getGuestDateOfBirth = (guestIndex: number): WritableComputedRef<string> => computed({
    get: () => $accessor.guests[guestIndex].dateOfBirth,
    set: dateOfBirth => $accessor.SET_GUEST_DATE_OF_BIRTH({
      guestIndex,
      dateOfBirth,
    }),
  })

  const getGuestAdditionalPersonChargeText = (guestIndex: number): ComputedRef<string | null> => computed(() => {
    if ($accessor.cartPreview && $accessor.guests[guestIndex]) {
      const guest = $accessor.guests[guestIndex]
      const amount = $accessor.cartPreview.additionalPersonCharges.find(
        charge => charge.idx === $accessor.guests[guestIndex].idx,
      )?.amount || null

      const externalId = getServiceExternalIdObject.value
      if (
        (!externalId || externalId.platformId !== ExternalPlatformTypeEnum.SH) &&
        amount &&
        guest.guestType
      ) {
        return i18n.t('booking.occupant_pricing', {
          name: i18n.t(`booking.${guest.guestType}`),
          count: $parseAmount(amount)
            .divide($accessor.getNumberOfNights)
            .toFormat('$0,0.00'),
        }) as string
      }
      if (!externalId) {
        return i18n.t('booking.occupant_include_default_pricing') as string
      }
    }
    return null
  })

  const getAdditionalPersonsWithPrices = computed((): null | GuestTypesWithPrice => {
    const externalId = getServiceExternalIdObject.value
    if ($accessor.cartPreview?.additionalPersonCharges?.length && $accessor.guests && (!externalId || externalId.platformId !== ExternalPlatformTypeEnum.SH)) {

      // Basically a reduce that returns a GuestTypesWithPrice object based on the additionalPersonCharges
      const output = $accessor.cartPreview.additionalPersonCharges.reduce<GuestTypesWithPrice>((prev, curr, idx, arr) => {

        // Find the gues associated with the charge
        const guest: Guest | undefined = $accessor.guests.find(guest => guest.idx === arr[idx].idx)

        // If it exists, compute the new values for this guest type
        if (guest?.guestType) {
          const previous = prev[guest.guestType]
          const newAmount: Amount = {
            amount: previous.amount.amount += curr.amount.amount,
            currency: previous.amount.currency,
          }
          const newCount = previous.count + 1

          // Add them to the accumulator
          return { ...prev, [guest.guestType]: { amount: newAmount, count: newCount } }
        }
        return prev
      }, {
        [GuestTypeEnum.ADULT]: { count: 0, amount: { amount: 0, currency: 'EUR' } },
        [GuestTypeEnum.CHILDREN]: { count: 0, amount: { amount: 0, currency: 'EUR' } },
        [GuestTypeEnum.INFANT]: { count: 0, amount: { amount: 0, currency: 'EUR' } },
      })
      return output
    }
    return null
  })

  /**
   * Calculates guestType of each guest using dateOfBirth,
   * and makes sure these comply to Service limits (maxAdults/maxChildren/maxInfants).
   * Returns an array containing for each guest either an error string or `null` if no error.
   */
  const guestLimitErrors: ComputedRef<Array<string | null>> = computed(() => {
    if ($accessor.service && $accessor.domain) {
      const counts = {
        [GuestTypeEnum.ADULT]: 1,
        [GuestTypeEnum.CHILDREN]: 0,
        [GuestTypeEnum.INFANT]: 0,
      }
      const limits = {
        [GuestTypeEnum.ADULT]: $accessor.service.maxAdultsAllowed,
        [GuestTypeEnum.CHILDREN]: $accessor.service.maxChildrenAllowed,
        [GuestTypeEnum.INFANT]: $accessor.service.maxInfantAllowed,
      }

      const { start } = $accessor.getBookingDates!

      return $accessor.guests.map(guest => {
        const type = getAgeTypeOfOccupant(
          guest.dateOfBirth,
          $accessor.domain!.maxAgeChild,
          $accessor.domain!.maxAgeInfant,
          start as dayjs.Dayjs,
        )
        counts[type]++

        if (counts[type] > limits[type]) {
          return i18n.t(`form.validations.additional_guests_max_${getAgeTypePlural(type)}_reached`) as string
        }
        return null
      })
    }
    return $accessor.guests.map(() => null)
  })

  /**
   * Check that every guest's dateOfBirth is set and that there is no limit error.
   */
  const isGuestListValid = computed(() => {
    return $accessor.guests.every((guest) => guest.dateOfBirth) &&
      guestLimitErrors.value.every(err => err === null)
  })

  /**
   * Watch guests for changes.
   * - clean up errors
   * - check if the changes could update the price and update card accordingly
   * - block/unblock next step button based on isGuestListValid.
   */
  function watchGuests(): void {
    const stopWatchGuests = watch(() => $accessor.guests, async(newVal, prevVal) => {
      // Update cart if a guest gets a dateOfBirth value.
      const hasNewDateOfBirth = newVal.some((guest, k) => guest.dateOfBirth && (!prevVal || !prevVal[k].dateOfBirth || prevVal[k].dateOfBirth !== guest.dateOfBirth))

      // Update cart if a guest gets a guestType value.
      const hasNewGuestType = newVal.some((guest, k) => guest.guestType?.length && (!prevVal || !prevVal[k].guestType || guest.guestType !== prevVal[k].guestType))

      // Don't check before mounted to avoid conflict with SSR checks.
      if (isGuestListValid.value && (hasNewDateOfBirth || hasNewGuestType) && process.client) {
        await $accessor.updateCart()
      }
    }, {
      deep: true,
      immediate: true,
    })

    // Watch changes in guest/customer validity, sync next step block if needed.
    const stopWatchValidity = watch(() => [isGuestListValid.value, isCustomerDateOfBirthValid.value], ([_isGuestValid, _isCustomerValid]) => {
      const isValidAll = _isGuestValid && _isCustomerValid
      // Block/Unblock next step button depending on validity.
      if (isValidAll === $accessor.ui.blockNextStep) {
        // console.log(`SET_BLOCK_NEXT_STEP ${!isValidAll} in useGuests watch validity`)
        $accessor.SET_BLOCK_NEXT_STEP(!isValidAll)
      }
    }, {
      immediate: true,
    })

    onBeforeUnmount(() => {
      stopWatchGuests()
      stopWatchValidity()
    })
  }

  function isGuestMajorityBetweenBookingStartNow(guestIndex: number) {
    const bookingStart = $accessor.bookingDates?.start
    if ($accessor.domain?.maxAgeChild) {
      const domainLimitInfansAge = $accessor.domain?.maxAgeChild + 1

      const guestDateOfBirth = getGuestDateOfBirth(guestIndex).value

      const isMinor = calculateAgeAtDate(guestDateOfBirth, dayjs.utc()) < domainLimitInfansAge
      if (bookingStart && isMinor) {
        const guestDateOfBirthWithLimitDomainAge = dayjs(guestDateOfBirth).add(domainLimitInfansAge, 'year')
        return dayjs(guestDateOfBirthWithLimitDomainAge).isBetween(dayjs(), bookingStart)
      }
    }
    return false
  }

  function isGuestInfansBetweenBookingStartNow(guestIndex: number) {
    const bookingStart = $accessor.bookingDates?.start
    if ($accessor.domain?.maxAgeInfant) {
      const domainLimitBabyAge = $accessor.domain?.maxAgeInfant + 1
      const guestDateOfBirth = getGuestDateOfBirth(guestIndex).value

      const isBaby = calculateAgeAtDate(guestDateOfBirth, dayjs.utc()) < domainLimitBabyAge
      if (bookingStart && isBaby) {
        const guestDateOfBirthWithLimitDomainAge = dayjs(guestDateOfBirth).add(domainLimitBabyAge, 'year')
        return dayjs(guestDateOfBirthWithLimitDomainAge).isBetween(dayjs(), bookingStart)
      }
    }
    return false
  }

  return {
    getAdditionalPersonsWithPrices,
    getGuestAdditionalPersonChargeText,
    getGuestDateOfBirth,
    getGuestsCount,
    getServiceExternalIdObject,
    guestLimitErrors,
    isGuestMajorityBetweenBookingStartNow,
    isGuestInfansBetweenBookingStartNow,
    watchGuests,
  }
}

export default useGuests
