export class Logger {
  private level

  constructor(level = 0) {
    this.level = level
  }

  public debug(...args: any): void { // eslint-disable-line
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line
      console.log(...args)
    }
  }

  public forceDebug(...args: any): void { // eslint-disable-line
    // eslint-disable-next-line
    console.log(...args)
  }

  public info(...args: any): void { // eslint-disable-line
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line
      console.log(...args)
    }
  }

  public error(...args: any): void { // eslint-disable-line
    // eslint-disable-next-line
    console.error(...args)
  }
}
