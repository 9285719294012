import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { EntityFile } from '~/types/Models/EntityFile'
import { entityFile, state } from './state'
import { EntityFilesState } from './types'

export const mutations = mutationTree(state,{
  ...createMutations<EntityFilesState, EntityFile>(entityFile),
})

export default mutations
