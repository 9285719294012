import { getField } from 'vuex-map-fields'
import { getterTree } from 'nuxt-typed-vuex'
import stateDef from './state'
import dayjs from 'dayjs'
import { Amount, BillingAdressRequiredFields, CustomerRequiredFields, DatePeriod, Guest, GuestType, GuestTypeEnum, isShExternalId, Option, Service, ServiceGuestLimits } from '~/types/Models'
import { StayParams } from '~/types/StayParams'

function guestTypeCount(guests: Guest[], type: GuestType): number {
  return guests.reduce((count, guest) => {
    return guest.guestType === type ? count + 1 : count
  }, 0)
}

export const getters = getterTree(stateDef, {
  getField,
  getStayParamsFromState(state, { getAdultCount, getChildrenCount, getInfantCount }): StayParams | null {
    if (state.bookingDates && state.slugs) {
      return {
        ...state.bookingDates,
        ...state.slugs,
        adults: getAdultCount,
        children: getChildrenCount,
        infants: getInfantCount,
      }
    }
    return null
  },
  getBookingDates(state): DatePeriod | null {
    if (state.cart?.start && state.cart?.end) {
      return {
        start: dayjs.utc(state.cart.start).startOf('day'),
        end: dayjs.utc(state.cart.end).startOf('day'),
      }
    } else if (state.booking && state.booking.start) {
      return {
        start: dayjs.utc(state.booking.start).startOf('day'),
        end: dayjs.utc(state.booking.end).startOf('day'),
      }
    }
    return null
  },
  getAdultCount(state): number {
    return guestTypeCount(state.guests, GuestTypeEnum.ADULT) + 1
  },
  getChildrenCount(state): number {
    return guestTypeCount(state.guests, GuestTypeEnum.CHILDREN)
  },
  getInfantCount(state): number {
    return guestTypeCount(state.guests, GuestTypeEnum.INFANT)
  },
  getGuestCount(state, { getAdultCount, getChildrenCount, getInfantCount }): number {
    return getAdultCount + getChildrenCount + getInfantCount
  },
  getTotalRate(state): Amount {
    if (state.booking) {
      return state.booking.bookingCharge.totalCharge
    } else if (state.cartPreview) {
      return state.cartPreview.totalCharge
    }
    return {
      amount: 0,
      currency: 'EUR',
    }
  },
  getDiscountedRate(state): Amount {
    let amount = 0
    if (state.booking) {
      amount = state.booking.bookingCharge.totalChargeWithDiscountsAndPromocode.amount
    } else if (state.cartPreview) {
      amount = state.cartPreview.totalChargeWithDiscountsAndPromocode.amount
    }
    return {
      amount,
      currency: 'EUR',
    }
  },
  getDiscountedRateWithoutPromocode(state): Amount {
    let amount = 0
    if (state.booking) {
      amount = state.booking.bookingCharge.totalChargeWithDiscounts.amount
    } else if (state.cartPreview) {
      amount = state.cartPreview.totalChargeWithDiscounts.amount
    }
    return {
      amount,
      currency: 'EUR',
    }
  },
  getDiscountedRateLeftToPay(state, { getGiftCardChargesTotal }): Amount {
    let amount = 0
    if (state.cartPreview) {
      amount = state.cartPreview.totalChargeWithDiscountsAndPromocode.amount - getGiftCardChargesTotal.amount
    }
    return {
      amount,
      currency: 'EUR',
    }
  },
  getPromocodeDiscount(state): Amount {
    let amount = 0
    if (state.booking) {
      amount = state.booking.bookingCharge.promocodeDiscount.amount
    } else if (state.cartPreview) {
      amount = state.cartPreview.promocodeDiscount.amount
    }
    return {
      amount,
      currency: 'EUR',
    }
  },
  getGiftCardChargesTotal(state): Amount {
    let amount = 0
    if (state.cartPreview) {
      amount = state.cartPreview.giftCardCharges.reduce(
        (total, charge) => total + charge.amountUsed.amount, 0,
      )
    }
    return {
      amount,
      currency: 'EUR',
    }
  },
  getGiftCardLeftToPay(state, { getDiscountedRate, getGiftCardChargesTotal }): Amount {
    return {
      amount: Math.max(getDiscountedRate.amount - getGiftCardChargesTotal.amount, 0),
      currency: 'EUR',
    }
  },
  getIsFullGiftCardPayment(state, { getDiscountedRate, getGiftCardChargesTotal }): boolean {
    return getGiftCardChargesTotal.amount >= getDiscountedRate.amount
  },
  getPromocodeAndGiftCardsTotalDiscount(_, { getPromocodeDiscount, getGiftCardChargesTotal }): Amount {
    return {
      amount: getPromocodeDiscount.amount + getGiftCardChargesTotal.amount,
      currency: 'EUR',
    }
  },
  getDiscountedRateWithPromocodeAndGiftCardDiscounts(_, { getDiscountedRateWithoutPromocode, getPromocodeAndGiftCardsTotalDiscount }): Amount {
    return {
      amount: getDiscountedRateWithoutPromocode.amount - getPromocodeAndGiftCardsTotalDiscount.amount,
      currency: 'EUR',
    }
  },
  getNumberOfNights(_, { getBookingDates }): number {
    const dates: DatePeriod | null = getBookingDates
    if (
      dates &&
      dayjs.utc(dates.end).isValid()
    ) {
      return dayjs.utc(dates.end).startOf('day').diff(
        dayjs.utc(dates.start).startOf('day'),
        'day',
      )
    }
    return 1
  },
  getGuestLimit(state): ServiceGuestLimits {
    const { maxPeopleAllowed, maxAdultsAllowed, maxChildrenAllowed, maxInfantAllowed } = state.service as Service
    return {
      maxPeopleAllowed,
      maxAdultsAllowed,
      maxChildrenAllowed,
      maxInfantAllowed,
    }
  },
  /**
   * Check if state.customer has all required fields to update Cart and create a Customer.
   * @param state
   * @return boolean
   */
  getCustomerHasAllData(state): boolean {
    if (state.customer.billingAddress) {
      return Object.keys(state.customer.billingAddress).length > 0 &&
        CustomerRequiredFields.every(field => !!state.customer[field]) &&
        BillingAdressRequiredFields.every(field => !!state.customer.billingAddress[field])
    }
    return false
  },
  getIsLoading(state): boolean {
    return state.ui.cartLoading > 0 || state.ui.loading > 0
  },
  getOptions(state): Option[] {
    return state.options.filter(option => option.isActive && !option.isPersonType)
  },
  getHasAvailableOptions(state, { getOptions }): boolean {
    return getOptions.length !== 0
  },
  getHasNonMandatoryOptions(state, { getOptions }): boolean {
    return (getOptions as Option[]).filter(option => option.min === 0).length > 0
  },
  getAvailableOptions(state, { getOptions }): Option[] {
    return (getOptions as Option[]).filter(option => option.min === 0)
  },
  getCartOptionQuantities(state): Record<number, number> {
    if (state.cart) {
      return state.cart.options.reduce((acc, cartOption) => ({
        ...acc,
        [cartOption.option]: cartOption.quantity,
      }), {})
    }
    return {}
  },
  getPersonTypeOptionCharges(state): Record<number, Amount> {
    if (state.cartPreview) {
      return state.cartPreview.optionCharges.reduce((acc, charge) => ({
        ...acc,
        [charge.optionId]: charge.chargeWithDiscounts,
      }), {})
    }
    return {}
  },
  getSelectedOptions(state, { getOptions, getCartOptionQuantities }): Array<Option & { quantity: number }> {
    if (state.cart) {
      return (getOptions as Option[]).filter(item => {
        return item.min === 0 && state.cart!.options.some(cartOption => cartOption.option === item.id)
      }).map(item => ({
        ...item,
        quantity: getCartOptionQuantities[item.id],
      }))
    }
    return []
  },
  getMandatoryOptions(state, { getOptions, getCartOptionQuantities }): Option[] {
    return (getOptions as Option[]).filter((item: Option) => {
      // Make sure items are also added to cart, which is done by the backend.
      return item.min > 0 && state.cart!.options.some(cartOption => cartOption.option === item.id)
    }).map(item => ({
      ...item,
      quantity: getCartOptionQuantities[item.id],
    }))
  },
  getPersonTypeOptions(state): Option[] {
    return state.options.filter(option => option.isActive && option.isPersonType)
  },
  getAppliedPersonTypeOptions(
    state,
    { getPersonTypeOptions, getPersonTypeOptionCharges },
  ): Array<Option & { totalChargeWithDiscounts: Amount }> {
    if (state.cartPreview) {
      return (getPersonTypeOptions as Option[]).filter(
        option => state.cartPreview!.optionCharges.map(charge => charge.optionId).includes(option.id),
      ).map(option => ({
        ...option,
        totalChargeWithDiscounts: getPersonTypeOptionCharges[option.id],
      }))
    }
    return []
  },
  getHasCartErrors(state): boolean {
    return state.cartErrors.length > 0 || state.cartPreviewErrors.length > 0
  },
  getBookingId(state) {
    return state.booking?.id
  },
  getServiceDiscountRate(state) {
    if (state.cartPreview) {
      return ((state.cartPreview?.serviceCharge.amount - state.cartPreview?.serviceChargeWithDiscount.amount) / state.cartPreview?.serviceCharge.amount) * 100
    }
    return 0
  },
  getRefererUrl(state) {
    if (state.refererUrl && state.marketplace) {
      // Extract hostname from referer and marketplace
      const refererDomain = new URL(state.refererUrl)
      const marketplaceDomain = new URL(state.marketplace.website)

      // Compare both value to avoid redirecting to a wrong domain if the user came in from somewhere else (ie: Gmail)
      if (refererDomain.hostname === marketplaceDomain.hostname) {
        return state.refererUrl
      }
      // Default to marketplace base URL (ie: abracadaroom.com)
      return state.marketplace?.website
    }
    return null
  },
  isShDomain(state) {
    if (state.domain?.externalId) {
      return isShExternalId(state.domain?.externalId)
    }
    return false
  },
})

export default getters
