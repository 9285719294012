import Vue from 'vue'
import { createMutations } from '@kissmylabs/vuex-entitystore'
import { mutationTree } from 'typed-vuex'
import { GiftCard, GiftCardWithChargeForm } from '~/types/Models/GiftCard'
import { giftCard as giftCardEntityDefinition, state as baseState } from '~/store/giftCards/state'
import { GiftCardChargeForm, GiftCardsState } from '~/store/giftCards/types'

export const mutations = mutationTree(baseState, {
  ...createMutations<GiftCardsState, GiftCard>(giftCardEntityDefinition),
  REMOVE_LAST_GIFTCARD(state) {
    const lastId = state.entities.allIds.pop()
    if (lastId && state.entities.byId[lastId]) {
      Vue.delete(state.entities.byId, lastId)
    }
  },
  SET_CHARGES_FORM_VALUES(state, chargesForm: Record<number, GiftCardChargeForm>) {
    state.chargesForm = chargesForm
  },
  SET_GIFTCARD_CHARGE_FORM(state, giftCardWithChargeForm: GiftCardWithChargeForm): void {
    Vue.set(state.chargesForm, giftCardWithChargeForm.id, {
      amountToSpend: giftCardWithChargeForm.amountToSpend,
      originalAmountToSpend: giftCardWithChargeForm.amountToSpend,
    })
  },
  SET_GIFTCARD_AMOUNT_TO_SPEND(state, giftCardWithAmountToSpend: Omit<GiftCardWithChargeForm, 'originalAmountToSpend'>) {
    Vue.set(
      state.chargesForm,
      giftCardWithAmountToSpend.id,
      {
        ...giftCardWithAmountToSpend,
        amountToSpend: giftCardWithAmountToSpend.amountToSpend,
      },
    )
  },
})

export default mutations
