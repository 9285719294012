


























import { computed, defineComponent, WritableComputedRef } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'PaymentCardInput',
  props: {
    value: {
      type: String,
      required: true,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: '#### #### #### ####',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const model: WritableComputedRef<string> = computed({
      get: () => props.value,
      set: (value) => {
        emit('input', value)
      },
    })

    function onCardNumberPaste(): void {
      // When pasting the card number, we need to force an update to apply the mask.
      // Applying the mask will get rid of this extra whitespace we add and add spaces each 4 numbers as expected.
      model.value = model.value + ' '
    }

    return {
      model,
      onCardNumberPaste,
    }
  },
})
