import { DatePeriod } from '~/types/Models/DatePeriod'
import { ShortToISOEnum } from '~/types/Locale'
import { Guest } from '~/types/Models/Guest'
import { Customer } from '~/types/Models/Customer'
import { Amount } from '~/types/Models/Amount'
import { GiftCard } from '~/types/Models/GiftCard'
import { Booking } from '~/types/Models/Booking'
import { Gender } from '.'
import { Promocode } from './Promocode'

export enum CartStatusEnum {
  OPEN = 'CART_OPEN',
  AWAITING_PAYMENT = 'CART_AWAITING_PAYMENT',
  PAID = 'CART_PAID',
  CLOSED = 'CART_CLOSED',
  ABANDONED = 'CART_ABANDONED',
}

export interface CartOption {
  option: number
  quantity: number
}

export interface CartGiftCard {
  giftCard: GiftCard
  amount?: Amount
}

export interface CartGiftCardIdReference {
  giftCard: number
  amount: Amount
}

export interface ThirdPartyData {
  gender: Gender
  firstName: string
  lastName: string
  isNotificationReceiver: boolean
  contactEmail: string | null
  phoneNumber: string | null
}

export interface Cart extends DatePeriod {
  booking: Booking | null
  customer: Customer | null
  giftCards: CartGiftCard[] | CartGiftCardIdReference[]
  guests: Guest[]
  hostMessage: string | null
  id: number
  lang: ShortToISOEnum
  marketplace: number
  options: CartOption[]
  promocode: Promocode | number | null
  service: number | null
  sessionId: string
  status: CartStatusEnum
  isThirdPartyBooking: boolean
  thirdPartyData: null | ThirdPartyData
  isDiscountOfferAccepted: boolean
}
