import entityFilesMutations from './mutations'
import entityFilesActions from './actions'
import entityFilesState from './state'
import entityFilesGetters from './getters'

export const entityFiles = {
  namespaced: true,
  state: entityFilesState,
  actions: entityFilesActions,
  mutations: entityFilesMutations,
  getters: entityFilesGetters,
}
