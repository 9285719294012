// import { ModerationStatus } from './Moderation'
import { Translation } from '~/types/Locale'
import { DateType } from '~/types/DateType'

export type DomainStatus = 'PUBLISHED' | 'MAINTENANCE'
export type OnlineBookingStatus = 'DISABLED' | 'FORM' | 'ENABLED'

export interface Domain {
  id: number
  address: number
  provider: number
  files: number[]
  services: number[]

  name: string

  checkinTimeStart: DateType
  checkinTimeEnd: DateType
  checkoutTimeStart: DateType
  checkoutTimeEnd: DateType

  externalId: DomainExternalId | null
  flexibleCheckinTimeStart: DateType
  flexibleCheckinTimeEnd: DateType
  flexibleCheckoutTimeStart: DateType
  flexibleCheckoutTimeEnd: DateType

  isActive: boolean
  isNoIndex: boolean
  isPublicTransportAccessible: boolean
  isSmsNotificationEnabled: boolean
  isFlexibleCheckinout: boolean
  isExternal: boolean

  maxAgeChild: number
  maxAgeInfant: number

  commercialName: Translation
  title: Translation
  description: Translation
  access: Translation
  around: Translation
  practicalInfo: Translation
  openingDetails: Translation
  checkinTimeDetails: Translation
  visitsDiscoveriesTitle: Translation
  visitsDiscoveriesContent: Translation
  seoTitle: Translation
  seoMetaDesc: Translation
  seoH1: Translation
  ownersPresentation: Translation
  ownersWord: Translation
  managerWord: Translation

  abcdUrl?: string
  // moderationStatus: ModerationStatus
  moderatedAt: DateType
  status: DomainStatus
  onlineBookingStatus: OnlineBookingStatus
}

export enum SH_ENGINES {
  ENGINE_SANDBOX = '967',
  ENGINE_PRODUCTION = '1297',
}

export enum ExternalPlatformTypeEnum {
  SH = 'SECUREHOLIDAY',
  KE = 'KEBOOKING',
  EVIIVO = 'EVIIVO',
}

export type ExternalPlatformType = 'SECUREHOLIDAY'

export interface DomainSecureHolidayData {
  engineId: string
  establishmentId: string
}

export interface DomainEviivoData {
  ev_shortName: string // eslint-disable-line camelcase
}

export interface DomainKeBookingData {
  propertyId: string
}

export type DomainExternalId = {
  platformId: ExternalPlatformTypeEnum.SH
  data: DomainSecureHolidayData
} | {
  platformId: ExternalPlatformTypeEnum.EVIIVO
  data: DomainEviivoData
} | {
  platformId: ExternalPlatformTypeEnum.KE
  data: DomainKeBookingData
}

export function isShExternalId(domainExternalId: DomainExternalId): domainExternalId is DomainExternalId {
  return domainExternalId.platformId === ExternalPlatformTypeEnum.SH
}
