import { bootstrap, setOptions, purchase, pageview } from 'vue-gtag'
import { useContext } from '@nuxtjs/composition-api'
import { shortLocaleToRegionLocale } from '~/helpers'
import { ShortLocale, ShortToISOEnum, SupportedISOLocale } from '~/types/Locale'
import { AffilaeConversion } from '~/types/analytics'
import useMoney from '~/composable/useMoney'
import { Logger } from '~/helpers/logger'
import Decimal from 'decimal.js-light'

export interface AnalyticsHook {
  dispatchPurchaseEvent(): void
  pushAffilaeConversion(): void
}

function useAnalytics(): AnalyticsHook {
  const { app, store, route } = useContext()
  const { formatAmount } = useMoney()
  const logger = new Logger()

  function dispatchPurchaseEvent() {
    if (
      app.$accessor.service &&
      app.$accessor.domain &&
      app.$accessor.marketplace?.frontConfig.googleAnalyticsId) {
      setOptions({
        config: { id: app.$accessor.marketplace.frontConfig.googleAnalyticsId },
      })
      bootstrap()
        .then(() => {
          logger.debug('Gtag initialized')

          const items = [{
            id: String(app.$accessor.service!.id),
            name: app.$accessor.service!.label,
            brand: app.$accessor.domain!.name,
            quantity: 1, // count nights for quantity? (https://app.clickup.com/t/7rwtvc?comment=78279668) ATTENTION: if we add night count, we should impact the price accordingly.
            price: (new Decimal(app.$accessor.getDiscountedRate.amount)).dividedBy(100).toNumber(),
          }]
          const taxlessAmount = app.$parseAmount(app.$accessor.getDiscountedRate).multiply(0.8)
          const taxAmount = app.$parseAmount(app.$accessor.getDiscountedRate).subtract(taxlessAmount).toJSON()

          const event = {
            transaction_id: String(store.getters.getBookingId),
            affiliation: app.$accessor.marketplace!.website,
            value: (new Decimal(app.$accessor.getDiscountedRate.amount)).dividedBy(100).toNumber(),
            currency: 'EUR',
            tax: (new Decimal(taxAmount.amount)).dividedBy(100).toNumber(),
            shipping: 0,
            items,
          }

          if (app.$featureToggle.toggles.trackingGtagPurchase) {
            // add options to items? (https://app.clickup.com/t/7rwtvc?comment=78279668)
            logger.debug('Will dispatch Gtag purchase event: ', event)
            purchase(event)
            pageview({
              page_path: route.value.fullPath,
            })
          } else {
            logger.debug('Would dispatch gtag event but we debug: ', event)
          }
        })
    }
  }

  function pushAffilaeConversion() {
    if (app.$accessor.marketplace?.frontConfig) {
      const regionLocale = shortLocaleToRegionLocale(app.i18n.locale, true) as SupportedISOLocale

      if (
        !app.$featureToggle.toggles.affilae ||
        !app.$accessor.marketplace || !app.$accessor.domain || !app.$accessor.cart ||
        app.$accessor.isNewCustomer === null || app.$accessor.marketplace.frontConfig.affilaeTags === null
      ) {
        return
      }

      try {
        const tags = app.$accessor.marketplace.frontConfig.affilaeTags
        const currentTag = app.$accessor.isNewCustomer
          ? tags.newCustomerTags[regionLocale]
          : tags.existingCustomerTags[regionLocale]

        if (currentTag) {
          const aeEvent: AffilaeConversion = {
            key: currentTag,
            Conversion: {
              id: `${app.$accessor.domain.name}-${store.getters.getBookingId}`,
              amount: formatAmount(app.$accessor.getDiscountedRate, ShortToISOEnum[ShortLocale.EN], '0,0.00'),
              cv: app.$accessor.cart.id,
              currency: app.$accessor.getDiscountedRate.currency,
              payment: 'online',
              // @todo on pourrait mettre le voucher, en parler avec Nico.
            },
          }

          if (window.AeTracker) {
            window.AeTracker.sendConversion(aeEvent)
          } else {
            (window.AE = window.AE || []).push(aeEvent)
          }
        }
      } catch (e) {
        if (process.env.NODE_ENV === 'development') {
          console.error(e)
        } else {
          app.$sentry.captureException(e)
        }
      }
    }
  }

  return {
    dispatchPurchaseEvent,
    pushAffilaeConversion,
  }
}

export default useAnalytics
