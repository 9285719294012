





















import { defineComponent } from '@nuxtjs/composition-api'
import TicketIcon from '~/components/icons/TicketIcon.vue'
import useOptions from '~/composable/useOptions'

export default defineComponent({
  components: {
    TicketIcon,
  },
  setup() {
    const { getTotalOptionAmount } = useOptions()
    
    return {
      getTotalOptionAmount,
    }
  },
})
