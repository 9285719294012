import { getAccessorType } from 'typed-vuex'
import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import { modules } from './accessor-types'
import { createLogger } from 'vuex'

const ENABLE_LOGGER = false

export const plugins = process.env.NODE_ENV === 'development' && ENABLE_LOGGER
  ? [createLogger({
    collapsed: true,
    transformer(/*state*/) {
      return ''
    },
    mutationTransformer(mutation) {
      return mutation.type
    },
    actionTransformer(action) {
      return action.type
    },
  })]
  : []

// This function only exist so we can enjoy typings on app.$accessor
// See https://typed-vuex.roe.dev/getting-started-nuxt#defining-the-accessor-type
export const accessorType = getAccessorType({
  state,
  mutations,
  actions,
  getters,
  modules,
})
