import Dinero from 'dinero.js'
import useMoney from '~/composable/useMoney'
import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { Amount } from '~/types/Models/Amount'

export default function(ctx: Context, inject: Inject): void {
  const {
    parseAmount,
    formatAmount,
  } = useMoney(ctx)

  inject('dinero', Dinero)
  inject('parseAmount', (value: Amount) => parseAmount(value, ctx.app.i18n.locale))
  inject('formatAmount', (value: Amount, format?: string) => formatAmount(value, ctx.app.i18n.locale, format))
  inject('toAmount', (value: Dinero.Dinero) => value.toJSON())
}
