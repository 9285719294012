







import { defineComponent } from "@nuxtjs/composition-api"
import PriceDisplay from '~/components/content/PriceDisplay.vue'
import NextStepCta from '~/components/ui/NextStepCta.vue'

export default defineComponent({
  components: {
    PriceDisplay,
    NextStepCta,
  },
  setup() {
  },
})
