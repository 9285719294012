


































import { computed, defineComponent, PropType, useContext, WritableComputedRef } from '@nuxtjs/composition-api'
import { ShortToISOEnum } from '~/types/Locale'

export default defineComponent({
  name: 'GuestInfo',
  props: {
    value: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { app: { $dayjs, i18n } } = useContext()

    const birthDate: WritableComputedRef<Date | null> = computed({
      get: () => props.value ? $dayjs(props.value).utc(false).toDate() : null,
      set: (value: Date | null) => {
        emit('input', value ? $dayjs(value.toDateString()).format('YYYY-MM-DD') : null)
      },
    })

    const datePickerLocale = computed(() => ShortToISOEnum[i18n.locale.toUpperCase() as keyof typeof ShortToISOEnum])

    function dateParser(value: string) {
      return $dayjs(value, 'DD/MM/YYYY').toDate()
    }

    return {
      dateParser,
      birthDate,
      datePickerLocale,
    }
  },
})
