var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{staticClass:"CheckoutStep CheckoutStep-booking",attrs:{"tag":"div"}},[_c('ReassuranceCard',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('UsersIcon',{staticClass:"tw-h-24 tw-w-24 md:tw-h-32 md:tw-w-32"})]},proxy:true},{key:"title",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.isUSCOM ? _vm.$t('step_cards.booking_title_uscom') : _vm.$t('step_cards.booking_title'))+"\n    ")]},proxy:true}])},[_vm._v(" "),_vm._v("\n    "+_vm._s(_vm.$t('step_cards.booking_message'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"CheckoutStep-section"},[_c('StepSectionTitle',{attrs:{"title":_vm.$t('booking.register_occupants'),"subtitle":_vm.$tc('booking.max_occupants_count', _vm.$accessor.getGuestLimit.maxPeopleAllowed)}}),_vm._v(" "),_c('BaseField',{staticClass:"mt-4 has-width-200",attrs:{"label":_vm.$t('booking.add_occupants')}},[_c('BNumberinput',{attrs:{"value":_vm.getGuestsCount,"controls-rounded":"","min":"1","max":_vm.$accessor.getGuestLimit.maxPeopleAllowed,"editable":false,"size":"is-medium","icon-pack":"usi","type":"is-primary"},on:{"input":function($event){_vm.getGuestsCount = $event}}})],1),_vm._v(" "),_c('div',{staticClass:"columns is-mobile is-multiline has-mt-large"},[_c('ValidationProvider',{staticClass:"column is-half is-one-third-desktop",attrs:{"name":"main_guest","tag":"div","rules":"main_guest_birthdate_required|main_guest_must_be_adult","skip-if-empty":false,"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('GuestInfo',{attrs:{"subtitle":_vm.$t('booking.main_guest')},model:{value:(_vm.getCustomerField('dateOfBirth').value),callback:function ($$v) {_vm.$set(_vm.getCustomerField('dateOfBirth'), "value", $$v)},expression:"getCustomerField('dateOfBirth').value"}},[(!_vm.getServiceExternalIdObject)?_c('div',{staticClass:"is-italic is-size-6"},[_vm._v("\n              "+_vm._s(_vm.$t('booking.occupant_include_default_pricing'))+"\n            ")]):_vm._e()])],1)]}}])}),_vm._v(" "),_vm._l((_vm.$accessor.guests),function(guest,i){return _c('ValidationProvider',{key:guest.idx,staticClass:"column is-half",attrs:{"vid":("" + i),"name":"additional_guest","tag":"div","rules":"additional_guest_birthdate_required","skip-if-empty":false,"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('BaseField',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('GuestInfo',{attrs:{"subtitle":((_vm.$t('booking.occupant')) + " " + (i + 2))},model:{value:(_vm.getGuestDateOfBirth(i).value),callback:function ($$v) {_vm.$set(_vm.getGuestDateOfBirth(i), "value", $$v)},expression:"getGuestDateOfBirth(i).value"}},[(_vm.guestLimitErrors[i] && dirty)?_c('div',{staticClass:"has-text-danger has-text-weight-bold is-size-6"},[_vm._v("\n              "+_vm._s(_vm.guestLimitErrors[i])+"\n            ")]):(_vm.getGuestAdditionalPersonChargeText(i).value && _vm.getGuestDateOfBirth(i).value && !_vm.isNextStepDisabled)?_c('div',{staticClass:"is-italic is-size-6"},[_vm._v("\n              "+_vm._s(_vm.getGuestAdditionalPersonChargeText(i).value)+"\n            ")]):_vm._e(),_vm._v(" "),((_vm.isGuestMajorityBetweenBookingStartNow(i) || _vm.isGuestInfansBetweenBookingStartNow(i)) && _vm.getGuestAge(i).value)?_c('div',{staticClass:"is-italic is-size-6"},[_vm._v("\n              "+_vm._s(_vm.$tc('guest.majority_during_booking', _vm.getGuestAge(i).value))+"\n            ")]):_vm._e()])],1)]}}],null,true)})})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }