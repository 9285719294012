<template>
<div :class="['ResponsiveImage', { 'ResponsiveImage--fit': fit !== 'none' }]">
  <v-lazy-image
    :src="signedUrl"
    :alt="alt"
    :src-placeholder="placeholderUrl"
    :style="imageStyles"
  />
</div>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api'
import useMq from '~/composable/useMq'

// TODO maybe we could use a <picture> element but for now this is wayyyyy faster.
export default defineComponent({
  name: 'ResponsiveImage',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    fit: {
      type: String,
      default: 'none',
    },
  },
  setup(props) {
    const {
      isWide,
      isMobile,
      breakpoints,
    } = useMq()
    // const { generateSignedUrl } = useImage()
    //
    // const imgSize = computed(() => isWide.value || isMobile.value ? [300, 200] : [600, 400])

    // const signedUrl = computed(() => generateSignedUrl(props.src, ...imgSize.value))
    const signedUrl = computed(() => props.src)

    const placeholderUrl = computed(() => {
      if (isWide.value) {
        return require('~/assets/img/placeholder-1080x600.jpg')
      }
      return isMobile.value
        ? require('~/assets/img/placeholder-300x300.jpg')
        : require('~/assets/img/placeholder-600x600.jpg')
    })

    const imageStyles = computed(() => {
      switch (props.fit) {
        case 'cover':
          return {
            objectFit: 'cover',
            width: '100%',
          }
        default:
          return {}
      }
    })

    return {
      breakpoints,
      signedUrl,
      placeholderUrl,
      imageStyles,
    }
  },
})
</script>

<style
  scoped
  lang="scss"
>
.ResponsiveImage {
  &--fit {
    height: 100%;
    width: 100%;
    display: flex;
  }

  .v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
  }

  .v-lazy-image-loaded {
    filter: blur(0);
  }
}
</style>
