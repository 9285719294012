import { computed, getCurrentInstance } from '@nuxtjs/composition-api'
import { breakpoints } from '~/plugins/vue-mq'

function useMq() {

  const vm = getCurrentInstance()
  const isWide = computed(() => vm.isWide)
  const isTouch = computed(() => vm.isTouch)
  const isMobile = computed(() => vm.isMobile)

  return {
    isWide,
    isTouch,
    isMobile,
    breakpoints,
  }
}

export default useMq
