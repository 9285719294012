import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import useI18n from '~/composable/useI18n'
import { Translation } from '~/types/Locale'

export default function(ctx: Context, inject: Inject): void {
  const { translateEntityField } = useI18n(ctx)

  inject('translateEntityField', (field: Translation) => translateEntityField(field))
}
