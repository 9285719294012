

























import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import UserPlusicon from '~/components/icons/UserPlusIcon.vue'

export default defineComponent({
  components: {
    UserPlusicon,
  },
  setup() {
    const { app: { $accessor, $formatAmount }} = useContext()

    const getOptionCharges = computed(() =>{
      const optionChargesIds = $accessor.cartPreview?.optionCharges.map(opt => opt.optionId)
      return $accessor.options.filter(opt => optionChargesIds?.includes(opt.id) && opt.isPersonType)
    })

    function getChargeWihDiscountForOption(optionId: number ) {
      const amount = $accessor.cartPreview?.optionCharges.find(opt => opt.optionId === optionId)?.chargeWithDiscounts
      return amount ? $formatAmount(amount) : 0
    }

    function getOptionQuantity(id: number) {
      const quantity = $accessor.cartPreview?.optionCharges.find(opt => opt.optionId === id)?.quantity
      return quantity || 0
    }

    return {
      getChargeWihDiscountForOption,
      getOptionCharges,
      getOptionQuantity,
    }
  },
})
